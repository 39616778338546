import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styles from './_styles.module.scss';
import { useSearchParams } from 'react-router-dom';
import JobHeading from '../../../Candidate/JobDetails/JobHeading';
import JobCardDetails from '../../../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import WorkDate from '../../../Works/WorkCardMob/WorkDate';
import UIRouterLink from '../../../UI/UIRouterLink/UIRouterLink';
import JobDetailsQualification from '../../../Candidate/JobDetails/JobDetailsQualification';
import JobDetailsDescription from '../../../Candidate/JobDetails/JobDetailsDescription';
import ShortlistsAndBumps from '../ShortlistsAndBumps/ShortlistsAndBumps';
import ReviewCandidatesAccordion from '../ReviewCandidatesAccordion/ReviewCandidatesAccordion';
import ReviewCandidatesContent from '../ReviewCandidatesContent/ReviewCandidatesContent';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../core/hooks/reduxHooks';
import UIButton from '../../../UI/UIButton/UIButton';
import { CircularProgress } from '@mui/material';
import JobCardType from '../../../Candidate/JobDetails/JobCardDetails/JobCardType';
import {
  getAssignmentJobDetails,
  repostEmployerAssignment,
} from '../../../../redux/thunk/employerAssignments';
import { openInfoCreditsModal } from '../../../../redux/reducers/ui/ModalDialogSlice';
import { setSelectedAssignmentId } from '../../../../redux/reducers/ui/SubscriptionPackagesSlice';
import JobDetailsWrap from '../../../../containers/JobDetailsWrap/JobDetailsWrap';
import { setEmptyJobReviewCandidates } from '../../../../redux/reducers/api/UserManageJobsSlice';
import { setEmptyAdminJobReviewCandidates } from '../../../../redux/reducers/api/AdminAssignmentSlice';

const ManageJobDetails: FC<{ assignmentId: string }> = ({ assignmentId }) => {
  const [isLoadingDetails, setIsLoadingDetails] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const assignmentIdParams = searchParams.get('assignment_id');

  const jobInfoRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { jobDetails } = useAppSelector(state => state.UserManageJobsReducer);

  const getJobDetails = useCallback(async () => {
    if (assignmentId) {
      setIsLoadingDetails(true);

      return await dispatch(getAssignmentJobDetails(assignmentId)).finally(
        () => {
          setIsLoadingDetails(false);
        }
      );

      // setJobDetails(jobDetails);
    }
  }, [assignmentId]);

  const handleRepost = useCallback(() => {
    if (jobDetails && jobDetails.bumps > 0) {
      dispatch(repostEmployerAssignment(jobDetails.assignment_id));
    } else if (jobDetails && jobDetails.bumps === 0) {
      dispatch(setSelectedAssignmentId(jobDetails.assignment_id));
      dispatch(openInfoCreditsModal('bump'));
    }
  }, [jobDetails]);

  useEffect(() => {
    getJobDetails();
  }, [assignmentId]);

  useEffect(() => {
    const node = jobInfoRef.current;

    node?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [assignmentId]);

  if (isLoadingDetails) {
    return (
      <div className={styles.details_loader}>
        <CircularProgress />
      </div>
    );
  }

  if (jobDetails) {
    return (
      <div className={styles.details} ref={jobInfoRef}>
        <div className={styles.header}>
          <div className={styles.header_main}>
            <JobHeading heading={jobDetails.title} size={20} />
            <JobDetailsWrap
              companyName={jobDetails.company_name}
              location={jobDetails.location}
              lowestRate={jobDetails.lowest_rate}
              highestRate={jobDetails.highest_rate}
              expectedSalaryDivider={jobDetails.expected_salary_divider}
              time={jobDetails.time}
              locationLinks={jobDetails.location_links}
              fullDetails={true}
            />
            <WorkDate date={jobDetails.created_at} />
          </div>
          <div className={styles.header_btns}>
            <JobCardType type={jobDetails.job_type} />
            <div>
              <p className={styles.id}>{jobDetails.assignment_id}</p>
              <div className={styles.btns}>
                <UIRouterLink
                  title={'Edit job'}
                  goTo={`/employer/post-jobs?edit_id=${jobDetails.assignment_id}`}
                  className={styles.btn_edit}
                />
                <UIButton
                  title={'Bump'}
                  callback={() => handleRepost()}
                  classModificator={styles.btn_bump}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.payment_info_wrapper}>
          <JobDetailsQualification
            industry={jobDetails.category}
            qualifications={jobDetails.preference}
          />
          <div className={styles.payment_info}>
            <ShortlistsAndBumps
              assignmentId={jobDetails.assignment_id}
              bumps={jobDetails.bumps}
              shortlisted={jobDetails.shortlisted}
              shortlists_left={jobDetails.shortlists_left}
            />
          </div>
        </div>

        <div className={styles.description}>
          <JobDetailsDescription description={jobDetails.description} />
        </div>

        <ReviewCandidatesAccordion>
          <ReviewCandidatesContent jobId={assignmentId} role={'employer'} />
        </ReviewCandidatesAccordion>
      </div>
    );
  } else {
    return <div className={styles.details}> </div>;
  }
};

export default ManageJobDetails;
