/* eslint-disable react/no-unknown-property */
import React, { FC } from 'react';
import styles from './_styles.module.scss';
import { useRouter } from '../../core/hooks/useRouter';
import { useAppDispatch } from '../../core/hooks/reduxHooks';
import { switchActiveFiltersItem } from '../../redux/reducers/api/CandidateAssignmentsSlice';
import { LocationTypes } from '../../core/models/models';
// import styles from './UIMultipleSelect.scss';

interface SortLocationProps {
  location: LocationTypes;
}

const SortLocation: FC<SortLocationProps> = ({ location }) => {
  const { navigate } = useRouter();
  const dispatch = useAppDispatch();

  const searchByLocation = () => {
    if (location !== 'All regions') {
      dispatch(
        switchActiveFiltersItem({
          array: 'regions',
          value: location,
          add: true,
        })
      );
    }

    navigate('search');
  };

  return (
    <div
      className={styles.locations_list_item}
      about={location.slice(0, 1)}
      onClick={searchByLocation}
    >
      <h2 className={styles.location_heading}>{location}</h2>
    </div>
  );
};

export default SortLocation;
