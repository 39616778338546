import React, { useState, useEffect } from 'react';
import SearchInput from '../../../components/UI/SearchInput';
import Table from '../../../components/UI/Table';
import UIButton from '../../../components/UI/UIButton/UIButton';
import UIPagination from '../../../components/UI/UIPagination/UIPagination';
import { AdminInvoicesTableHeaders } from '../../../core/constants/config';
import { getInvoicePdfDetails } from '../../../core/services/axios';
import { adminAPI } from '../../../core/api/api';
import style from './_index.module.scss';
import { InvoicesOrder, Pagination } from '../../../core/models/models';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { setLoading } from '../../../redux/reducers/ui/HandlerSlice';
import { setInvoices } from '../../../redux/reducers/api/AdminInvoicesSlice';

const initialSearchValue = '';
const initialInvoicesOrder: InvoicesOrder = '-created_at';
const initialPagination: Pagination = {
  pageNumber: 1,
  pageSize: 10,
  total: 0,
  totalPages: 0,
};

const Invoices: React.FC = () => {
  const [invoicesOrder, setInvoicesOrder] =
    useState<InvoicesOrder>(initialInvoicesOrder);
  const [searchValue, setSearchValue] = useState('');
  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  const { invoices } = useAppSelector(state => state.adminInvoices);

  const dispatch = useAppDispatch();

  const onDownload = (purchase_id: string): void => {
    dispatch(setLoading(true));
    getInvoicePdfDetails(purchase_id).finally(() => {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 200);
    });
  };

  const orderBy = (value: InvoicesOrder) => {
    dispatch(setLoading(true));
    adminAPI
      .getAdminInvoices({
        order_by: value,
        page_number: pagination.pageNumber,
        page_size: pagination.pageSize,
        key_word: searchValue,
      })
      .then(({ pageNumber, pageSize, total, totalPages, results }) => {
        dispatch(setInvoices(results));
        setInvoicesOrder(value);
        setPagination({
          pageNumber,
          pageSize,
          total,
          totalPages,
        });
        dispatch(setLoading(false));
      });
  };

  const onSearch = () => {
    dispatch(setLoading(true));
    adminAPI
      .getAdminInvoices({
        order_by: invoicesOrder,
        key_word: searchValue,
        page_number: 1,
        page_size: pagination.pageSize,
      })
      .then(({ pageNumber, pageSize, total, totalPages, results }) => {
        dispatch(setInvoices(results));
        setPagination({
          pageNumber,
          pageSize,
          total,
          totalPages,
        });
        dispatch(setLoading(false));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 200);
      });
  };

  const changePage = (page: number) => {
    dispatch(setLoading(true));
    adminAPI
      .getAdminInvoices({
        order_by: invoicesOrder,
        page_number: page,
        page_size: pagination.pageSize,
        key_word: searchValue,
      })
      .then(({ pageNumber, pageSize, total, totalPages, results }) => {
        dispatch(setInvoices(results));
        setPagination({
          pageNumber,
          pageSize,
          total,
          totalPages,
        });
        dispatch(setLoading(false));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 200);
      });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    adminAPI
      .getAdminInvoices({
        order_by: initialInvoicesOrder,
        page_number: initialPagination.pageNumber,
        page_size: initialPagination.pageSize,
        key_word: initialSearchValue,
      })
      .then(({ pageNumber, pageSize, total, totalPages, results }) => {
        dispatch(setInvoices(results));
        setPagination({
          pageNumber,
          pageSize,
          total,
          totalPages,
        });
        dispatch(setLoading(false));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 200);
      });
  }, []);

  return (
    <div className={style.container}>
      <SearchInput
        onSearch={onSearch}
        value={searchValue}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement>) => setSearchValue(value)}
      />
      <Table>
        <Table.Head
          tableHeaders={AdminInvoicesTableHeaders}
          order={invoicesOrder}
          orderBy={orderBy}
        />
        {invoices.length ? (
          <Table.Body>
            {invoices.map(invoice => (
              <tr key={invoice.purchase_id}>
                <td>{invoice.user_name}</td>
                <td className={style.centered}>{invoice.created_at}</td>
                <td>{invoice.package ?? ''}</td>
                <td className={style.centered}>{invoice.shortlists_amount}</td>
                <td className={style.centered}>{invoice.reposts_amount}</td>
                <td className={style.centered}>
                  {typeof invoice.validity_days === 'number'
                    ? `${invoice.validity_days} day${
                        invoice.validity_days !== 1 ? 's' : ''
                      }`
                    : 'Expired'}
                </td>
                <td className={style.centered}>${invoice.total_price}</td>
                <td>
                  <UIButton
                    title='Download'
                    callback={() => onDownload(invoice.purchase_id)}
                  />
                </td>
              </tr>
            ))}
          </Table.Body>
        ) : null}
      </Table>
      <UIPagination
        currentPage={pagination.pageNumber}
        totalPages={pagination.totalPages}
        onChangePage={(_, page) => changePage(page)}
        size='small'
        siblingCount={2}
        boundaryCount={1}
        className={style.pagination}
      />
    </div>
  );
};

export default Invoices;
