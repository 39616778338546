import React, { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import {
  AdminAssignmentsType,
  AdminAssignment,
} from '../../core/models/models';
import JobCardDetails from '../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import JobDetailsQualification from '../Candidate/JobDetails/JobDetailsQualification';
import JobHeading from '../Candidate/JobDetails/JobHeading';
import ReviewCandidatesAccordion from '../Employer/ManageJobs/ReviewCandidatesAccordion/ReviewCandidatesAccordion';
import ReviewCandidatesContent from '../Employer/ManageJobs/ReviewCandidatesContent/ReviewCandidatesContent';
import UIButton from '../UI/UIButton/UIButton';
import UIButtonNavLink from '../UI/UINavLink/UIButtonNavLink';
import style from './_index.module.scss';
import JobCardType from '../Candidate/JobDetails/JobCardDetails/JobCardType';
import { repostAdminAssignmentJob } from '../../redux/thunk/adminAssignments';
import JobDetailsDescription from '../Candidate/JobDetails/JobDetailsDescription';

const AssignmentInfo: React.FC<{
  assignmentType: AdminAssignmentsType;
  assignment: AdminAssignment | null;
}> = ({ assignmentType, assignment }) => {
  const dispatch = useAppDispatch();
  // const proposals = useAppSelector(
  //   state => state.adminAssignments[assignmentType].proposals
  // );

  const infoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = infoRef.current;

    node?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [assignment, assignmentType]);

  if (!assignment) {
    return <div className={style.loader}>{/* <CircularProgress /> */}</div>;
  }

  return (
    <div className={style.work_full_info} ref={infoRef}>
      {assignment && (
        <>
          <div className={style.work_info_wrap}>
            <div className={style.status}>
              Status: <p>{assignment.status}</p>
            </div>
            <div className={style.work_info_main}>
              <div className={style.work_info_main_text}>
                <JobHeading heading={assignment.title} size={20} />
                <JobCardDetails
                  companyName={assignment.company_name}
                  location={assignment.location}
                  lowestRate={assignment.lowest_rate}
                  highestRate={assignment.highest_rate}
                  expectedSalaryDivider={assignment.expected_salary_divider}
                  time={assignment.time}
                  locationLinks={assignment.location_links}
                  fullDetails={true}
                />
              </div>
              <div className={style.typeAndDate}>
                <div>
                  <JobCardType type={assignment.job_type} />
                  <p className={style.id}>{assignment.assignment_id}</p>
                </div>
                <div className={style.work_info_buttons}>
                  <UIButtonNavLink
                    goTo={`/admin/jobs/post?assignmentId=${assignment.assignment_id}&assignmentType=${assignmentType}`}
                    title='Edit job'
                    className={style.edit}
                  />
                  <UIButton
                    title={'Bump'}
                    callback={() =>
                      dispatch(
                        repostAdminAssignmentJob(assignment.assignment_id)
                      )
                    }
                    classModificator={style.btn_bump}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${style.work_info_wrap} ${style.details}`}>
            <JobDetailsQualification
              industry={assignment.category}
              qualifications={[assignment.preference]}
            />
            <div className={style.postedBy}>
              <p className={style.label}>Posted by:</p>

              <p>
                <span className={style.label}>Name: </span>
                {assignment.posted_by_name || '-'}
              </p>

              <p>
                <span className={style.label}>Phone: </span>
                {assignment.posted_by_hp_number || '-'}
              </p>

              <p>
                <span className={style.label}>Telegram: </span>
                {assignment.posted_by_telegram || '-'}
              </p>
            </div>
          </div>
          <hr
            style={{ borderWidth: '0', height: '1px', marginBottom: '24px' }}
          />
          <div>
            <JobDetailsDescription description={assignment.description} />
          </div>
          {(assignmentType === 'all' ||
            assignmentType === 'posted_by_staff') && (
            <ReviewCandidatesAccordion>
              <ReviewCandidatesContent
                jobId={assignment.assignment_id}
                role={'admin'}
              />
            </ReviewCandidatesAccordion>
          )}
        </>
      )}
    </div>
  );
};

AssignmentInfo.displayName = 'AssignmentInfo';

export default AssignmentInfo;
