import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UIHandlerState {
  isLoading: boolean;
  error: string;
}

const initialState: UIHandlerState = {
  isLoading: false,
  error: '',
};

export const UIHandlerSlice = createSlice({
  name: 'UIHandler',
  initialState,
  reducers: {
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
    },
  },
});

export const { setLoading, setError } = UIHandlerSlice.actions;

export default UIHandlerSlice.reducer;
