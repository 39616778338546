import { TableHeader } from '../../components/UI/Table';
import { IconType } from '../../components/UI/UIIcon/UIIcon';
import { InvoiceDescription } from '../../redux/reducers/api/AdminInvoicesSlice';

// Navigation
export const navigationList = {
  viewJobs: [
    {
      title: 'All jobs',
      goTo: '',
      end: true,
    },
    {
      title: 'Jobs posted by staff',
      goTo: 'staff',
      end: true,
    },
  ],
  approveJobs: [
    {
      title: 'Approve jobs',
      goTo: 'approve',
      end: true,
    },
    {
      title: 'Pending jobs',
      goTo: 'pending',
      end: true,
    },
    {
      title: 'Banned content jobs',
      goTo: 'banned',
      end: true,
    },
  ],
  invoices: [
    {
      title: 'Invoices',
      goTo: '',
      end: true,
    },
    {
      title: 'Create invoice',
      goTo: 'new',
    },
  ],
};

const navigationListInitialPaths: {
  [K in keyof typeof navigationList]: string;
} = {
  viewJobs: '/admin/jobs/',
  approveJobs: '/admin/jobs/',
  invoices: '/admin/invoices/',
};

export const adminLayoutLinksActivePaths: {
  [K in keyof typeof navigationList]: string[];
} = Object.entries(navigationList).reduce(
  (acc, [navigationKey, list]) => ({
    ...acc,
    [navigationKey]: list.map(
      link =>
        `${
          navigationListInitialPaths[
            navigationKey as keyof typeof navigationList
          ]
        }${link.goTo}`
    ),
  }),
  {}
) as {
  [K in keyof typeof navigationList]: string[];
};

export const adminLayoutLinks: {
  to: string;
  title: string;
  icon: IconType;
  activePaths?: string[];
}[] = [
  {
    to: '/admin/jobs/post',
    title: 'Post a job',
    icon: 'postJob',
  },
  {
    to: '/admin/jobs/approve',
    title: 'Approve jobs',
    icon: 'articleApprove',
    activePaths: adminLayoutLinksActivePaths.approveJobs,
  },
  {
    to: '/admin/jobs',
    title: 'View all jobs',
    icon: 'manage',
    activePaths: adminLayoutLinksActivePaths.viewJobs,
  },
  {
    to: '/admin/invoices',
    title: 'Invoices',
    icon: 'purchaseHistory',
    activePaths: adminLayoutLinksActivePaths.invoices,
  },
];

// TableHeaders
export const AdminInvoicesTableHeaders: TableHeader[] = [
  { label: 'Bill to', orderId: 'user_name', icon: 'pointDown' },
  {
    label: 'Date of purchase',
    orderId: 'created_at',
    icon: 'pointDown',
  },
  { label: 'Package', orderId: 'package', icon: 'pointDown' },
  {
    label: 'Number of shortlist',
    orderId: 'shortlists_amount',
    icon: 'pointDown',
  },
  { label: 'Bump', orderId: 'reposts_amount', icon: 'pointDown' },
  { label: 'Validity', orderId: 'validity_days', icon: 'pointDown' },
  { label: 'Amount', orderId: 'total_price', icon: 'pointDown' },
  { label: 'Invoice' },
];

export const AdminInvoiceDescriptionTableHeaders: {
  label: string;
  id: keyof Omit<InvoiceDescription, 'id'>;
}[] = [
  { label: 'Package', id: 'package_name' },
  { label: 'Number of posts', id: 'posts_amount' },
  { label: 'Number of shortlist', id: 'shortlists_amount' },
  { label: 'Bump', id: 'reposts_amount' },
  { label: 'Validity', id: 'validity_days' },
  { label: 'Amount', id: 'total_price' },
];
