import React, { ChangeEventHandler } from 'react';
import styles from './_styles.module.scss';
import { Input, FormControl, makeStyles, styled } from '@mui/material';
import UIInputLabel from '../UIInputLabel/UIInputLabel';
import Icon, { IconType } from '../UIIcon/UIIcon';

interface IUInputProps {
  id: string;
  title?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  phone?: boolean;
  button?: string;
  buttonHandler?: () => void;
  borderColor?: 'primary' | 'secondary' | 'info';
  fontSizeInput?: number;
  type?: 'text' | 'password' | 'email' | 'number' | 'tel';
  value?: string;
  maxLength?: number;
  disabled?: boolean;
  error?: boolean | string;
  direction?: 'row' | 'column';
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  icon?: IconType;
}

const ThemeInput = styled(Input)(({ theme }) => ({
  color: theme.palette.text.primary,
  // '&:after': {
  //   borderColor: theme.palette.text.secondary,
  // },
  // '&.Mui-focused': {
  //   borderColor: '#C52328',
  //   borderWidth: '2px',
  //   color: 'red',
  // },
}));

const UiInput: React.FC<IUInputProps> = ({
  id,
  title,
  type = 'text',
  phone,
  button,
  buttonHandler,
  value,
  maxLength,
  required,
  error,
  borderColor,
  fontSizeInput = 16,
  direction = 'column',
  className,
  icon,
  ...rest
}) => {
  return (
    <FormControl
      variant='standard'
      className={className}
      sx={{
        width: '100%',
        paddingTop: '15px',
        display: 'flex',
        flexDirection: direction,
      }}
    >
      {title && (
        <UIInputLabel
          style={{ color: error ? '#f32a2a' : '#35435e' }}
          htmlFor={id}
          color={borderColor}
        >
          {title}
          {required && (
            <span
              style={{
                color: error ? '#f32a2a' : '#35435e',
              }}
            >
              *
            </span>
          )}
        </UIInputLabel>
      )}
      <div className={styles.input_wrapper}>
        {phone && <div className={styles.phone_code}>+65</div>}
        <ThemeInput
          id={id}
          aria-describedby={id}
          color={borderColor ? borderColor : 'primary'}
          required={!!required}
          type={type}
          value={value || ''}
          sx={{
            width: '100%',
            fontSize: `${fontSizeInput}px`,
            paddingTop: '5px',
            fontWeight: 400,
            paddingRight: button ? '128px' : '0',
            backgroundColor: 'transparent',
            '&.Mui-focused': {
              borderColor: '#f32a2a',
              borderWidth: '2px',
            },
          }}
          inputProps={{
            'aria-label': id,
            maxLength: maxLength,
          }}
          error={!!error}
          {...rest}
        />
        {button && (
          <button
            type='button'
            className={styles.input_button}
            onClick={buttonHandler}
          >
            {button}
          </button>
        )}
        {icon && <Icon name={icon} className={styles.icon} />}
      </div>
    </FormControl>
  );
};

export default UiInput;
