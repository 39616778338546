import React from 'react';
import { AdminAssignment, IJobStatus } from '../../core/models/models';
import JobCardDetails from '../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import JobHeading from '../Candidate/JobDetails/JobHeading';
import JobTypeAndDate from '../JobTypeAndDate/JobTypeAndDate';
import JobStatus from '../UI/JobStatus/JobStatus';
import style from './_index.module.scss';

interface AssignmentCardProps extends React.LiHTMLAttributes<HTMLLIElement> {
  active?: boolean;
  assignment: AdminAssignment;
  onClick: () => void;
}

const AssignmentCard: React.FC<AssignmentCardProps> = ({
  active,
  className,
  assignment: {
    assignment_id,
    bumps,
    category,
    company_name,
    created_at,
    description,
    expected_salary_divider,
    expiration_date,
    highest_rate,
    job_type,
    location,
    lowest_rate,
    posted_by_name,
    posted_by_hp_number,
    posted_by_telegram,
    preference,
    preferred_qualifications,
    shortlisted,
    shortlists_left,
    status,
    time,
    title,
  },
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`${style.container}${active ? ` ${style.active}` : ''}${
        className ? `${className}` : ''
      }`}
    >
      <JobHeading heading={title} size={16} />
      <div className={style.info}>
        <JobCardDetails
          companyName={company_name}
          location={location}
          lowestRate={lowest_rate}
          time={time}
          highestRate={highest_rate}
          expectedSalaryDivider={expected_salary_divider}
        />
        <div className={style.additional}>
          {status && (
            <JobStatus className={style.status} status={status as IJobStatus} />
          )}
          <p className={style.id}>{assignment_id}</p>
        </div>
      </div>
      <JobTypeAndDate type={job_type} date={expiration_date || ''} />
    </div>
  );
};

export default AssignmentCard;
