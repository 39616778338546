import React, { FC } from 'react';
import styles from './_styles.module.scss';
import { IPendingApprovalJobResult } from '../../../../../core/models/models';
import JobHeading from '../../../../Candidate/JobDetails/JobHeading';
import JobCardDetails from '../../../../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import JobTypeAndDate from '../../../../JobTypeAndDate/JobTypeAndDate';
import { Link } from 'react-router-dom';

const PendingApprovalCard: FC<{
  job: IPendingApprovalJobResult;
}> = ({ job }) => {
  return (
    <li className={styles.card}>
      <JobTypeAndDate date={job.date} type={job.job_type} />
      <div className={styles.card_info}>
        <JobHeading heading={job.title} size={18} />
        <JobCardDetails
          companyName={job.company_name}
          location={job.location}
          lowestRate={job.lowest_rate}
          highestRate={job.highest_rate}
          expectedSalaryDivider={job.expected_salary_divider}
          time={job.time}
        />
      </div>
      <Link
        to={{ pathname: 'view-job/' + `${job.assignment_id}` }}
        className={styles.card_btn}
      >
        View
      </Link>
    </li>
  );
};

export default PendingApprovalCard;
