import React, { FC, useEffect, useState } from 'react';
import styles from './_styles.module.scss';
import { IAssignmentDetails } from '../../../../../core/models/models';
import { CircularProgress } from '@mui/material';
import JobHeading from '../../../../Candidate/JobDetails/JobHeading';
import JobCardDetails from '../../../../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import JobCardType from '../../../../Candidate/JobDetails/JobCardDetails/JobCardType';
import Expiration from '../../../../UI/Expiration/Expiration';
import JobStatus from '../../../../UI/JobStatus/JobStatus';
import JobDetailsQualification from '../../../../Candidate/JobDetails/JobDetailsQualification';
import JobDetailsDescription from '../../../../Candidate/JobDetails/JobDetailsDescription';
import { getAssignmentJobDetailsAxios } from '../../../../../core/services/axios';
import { useSearchParams } from 'react-router-dom';

const PendingApprovalJobInfo: FC = () => {
  const [isSelectedJobInfo, setIsSelectedJobInfo] = useState<
    IAssignmentDetails | undefined
  >(undefined);
  const [isLoadingDetails, setLoadingDetails] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const assignmentId = searchParams.get('assignment_id');

  useEffect(() => {
    if (assignmentId) {
      setLoadingDetails(true);
      try {
        getAssignmentJobDetailsAxios(assignmentId).then(res => {
          setIsSelectedJobInfo(res);
          setLoadingDetails(false);
        });
      } catch (error) {
        setLoadingDetails(false);
      }
    }
  }, [assignmentId]);

  if (isLoadingDetails || !isSelectedJobInfo) {
    return (
      <div className={styles.info_wrapper}>
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.info_wrapper}>
      <div className={styles.main_info}>
        <div className={styles.main_head}>
          <div className={styles.main_head_info}>
            <JobHeading heading={isSelectedJobInfo.title} size={20} />
            <JobCardDetails
              companyName={isSelectedJobInfo.company_name}
              location={isSelectedJobInfo.location}
              lowestRate={isSelectedJobInfo.lowest_rate}
              highestRate={isSelectedJobInfo.highest_rate}
              expectedSalaryDivider={isSelectedJobInfo.expected_salary_divider}
              time={isSelectedJobInfo.time}
              fullDetails={true}
              locationLinks={isSelectedJobInfo.location_links}
            />
          </div>
          <JobCardType type={isSelectedJobInfo.job_type} />
        </div>
        <div className={styles.main_footer}>
          <Expiration date={isSelectedJobInfo.created_at} />
          <JobStatus status={isSelectedJobInfo.status} />
        </div>
      </div>
      <div className={styles.payment_info_wrapper}>
        <JobDetailsQualification
          industry={isSelectedJobInfo.category}
          qualifications={isSelectedJobInfo.preference}
        />
        <div className={styles.payment_info}>
          <div className={styles.shortlisted}>
            <span>{isSelectedJobInfo.shortlisted}</span>
            <p>shortlisted</p>
          </div>
          <div className={styles.shortlists_left}>
            <span>{isSelectedJobInfo.shortlists_left}</span>
            <p>shortlisted left</p>
          </div>
          <div className={styles.bumps_left}>
            <span>{isSelectedJobInfo.bumps}</span>
            <p>bumps left</p>
          </div>
        </div>
      </div>
      <JobDetailsDescription description={isSelectedJobInfo.description} />
    </div>
  );
};

export default PendingApprovalJobInfo;
