import React, { FC } from 'react';
import styles from './_styles.module.scss';
import LocationWithLinks from '../../components/LocationWithLinks/LocationWithLinks';

interface JobCardDetailsProps {
  companyName?: string;
  location: string;
  locationLinks?: { [Key in string]: string };
  lowestRate: number | null;
  highestRate: number | null;
  expectedSalaryDivider: string;
  null_rate_message?: string;
  time?: string;
  fullDetails?: boolean;
}

const JobDetailsWrap: FC<JobCardDetailsProps> = ({
  companyName,
  location,
  lowestRate,
  highestRate,
  expectedSalaryDivider,
  null_rate_message = 'Please propose rate',
  time,
  fullDetails,
  locationLinks,
}) => {
  const rateMessage =
    lowestRate === 0 && highestRate === 0
      ? `${null_rate_message}`
      : lowestRate === highestRate
      ? `$${lowestRate || highestRate} / ${expectedSalaryDivider || 'month'}`
      : lowestRate && highestRate
      ? `$${lowestRate}-${highestRate} / ${expectedSalaryDivider || 'month'}`
      : lowestRate || highestRate
      ? `$${lowestRate} / ${expectedSalaryDivider || 'month'}`
      : null;

  return (
    <div className={styles.details}>
      {companyName && (
        <div className={styles.company}>
          <div className={`${styles.company_icon} ${styles.icon}`} />
          <p>{companyName}</p>
        </div>
      )}
      {location && (
        <div className={styles.location}>
          <div className={`${styles.location_icon} ${styles.icon}`} />
          {fullDetails && locationLinks ? (
            <LocationWithLinks location={location} links={locationLinks} />
          ) : (
            <p>{location}</p>
          )}
        </div>
      )}
      {rateMessage && (
        <div className={styles.rate}>
          <div className={`${styles.rate_icon} ${styles.icon}`} />
          <p>{rateMessage}</p>
        </div>
      )}
      {time && (
        <div className={styles.time}>
          <div className={`${styles.time_icon} ${styles.icon}`} />
          <p>{time}</p>
        </div>
      )}
    </div>
  );
};

export default JobDetailsWrap;
