import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAssignmentDetails,
  IDraftJobResult,
  IPendingApprovalJobs,
  IPreferredQualifications,
  JobCategoriesTypes,
  PostUserJobInformation,
  PostUserJobPoster,
  PreferredJobTypes,
} from '../../../core/models/models';
import { stat } from 'fs';

interface PostUserJobState {
  posterInfo: PostUserJobPoster;
  post: PostUserJobInformation;
  drafts: IDrafts;
  pendingApproval: IPendingApprovalJobs;
  isLoading: boolean;
  error: string | null;
}

interface IDrafts {
  pageNumber: number;
  pageSize: number;
  total: number;
  totalPages: number;
  results: IDraftJobResult[];
}

type ChosenKeys =
  | 'company_name'
  | 'job_title'
  | 'job_location'
  | 'days_and_hours'
  | 'highest_rate'
  | 'job_description';

const initialState: PostUserJobState = {
  posterInfo: {
    subscriptions: {
      basic_subscriptions: [],
      premium_subscriptions: [],
    },
    job_categories: [
      'F&B',
      'Sales / Retail',
      'Promoter',
      'Packer / Logistic',
      'Customer Service',
      'Cleaning',
      'Driver / Delivery',
      'Education / Training',
      'Admin / Secretary',
      'Telemarketing',
      'Healthcare',
      'Tuition',
      'Other',
    ],
    preferred_qualifications: [
      'No preference',
      'Diploma Holder',
      'A-level Holder',
      'Degree Holder',
    ],
    job_types: ['Full-time', 'Part-time', 'Internship', 'Contract'],
    job_description_character_limit: 2600,
    company_name_character_limit: 50,
    job_title_character_limit: 50,
    location_character_limit: 50,
    working_hours_character_limit: 50,
  },
  post: {
    chosen_category: '',
    preferred_qualifications: ['No preference'],
    job_type: '',
    company_name: '',
    job_title: '',
    job_location: '',
    days_and_hours: '',
    lowest_rate: '',
    highest_rate: '',
    job_description: '',
    subscription_id: '',
  },
  drafts: {
    pageNumber: 1,
    pageSize: 6,
    total: 0,
    totalPages: 0,
    results: [],
  },
  pendingApproval: {
    pageNumber: 1,
    pageSize: 6,
    total: 0,
    totalPages: 0,
    results: [],
  },
  isLoading: false,
  error: '',
};

export const postUserJobSlice = createSlice({
  name: 'post_assignment',
  initialState,
  reducers: {
    setAssignmentPoster: (
      state,
      { payload }: PayloadAction<PostUserJobPoster>
    ) => {
      state.posterInfo = payload;
    },
    setPostDrafts: (state, { payload }: PayloadAction<IDrafts>) => {
      state.drafts = payload;
    },
    setPendingApprovalPosts: (
      state,
      { payload }: PayloadAction<IPendingApprovalJobs>
    ) => {
      state.pendingApproval = payload;
    },
    setSelectedUserSubscription: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.post = {
        ...state.post,
        subscription_id: payload,
      };
    },
    switchActivePreferenceQualificationItem(
      state,
      {
        payload: { array, value, add },
      }: PayloadAction<{
        array: 'preferred_qualifications';
        value: IPreferredQualifications;
        add: boolean;
      }>
    ) {
      if (add) {
        if (value === 'No preference') {
          state.post[array] = ['No preference'];
        } else {
          if (state.post[array].includes('No preference')) {
            state.post[array] = [];
          }

          state.post[array].push(value);
        }
      } else {
        if (state.post[array].includes('No preference')) {
          return;
        }

        state.post[array].splice(state.post[array].indexOf(value), 1);
      }
    },
    setEditPostedJob(state, action: PayloadAction<IAssignmentDetails>) {
      const {
        category,
        job_type,
        highest_rate,
        lowest_rate,
        company_name,
        title,
        location,
        description,
        time,
        preferred_qualifications,
      } = action.payload;

      state.post = {
        ...state.post,
        chosen_category: category,
        job_type,
        highest_rate: highest_rate.toString() || '',
        lowest_rate: lowest_rate.toString() || '',
        company_name: company_name || '',
        job_title: title || '',
        job_location: location || '',
        job_description: description.join(`\r\n`) || '',
        days_and_hours: time || '',
        preferred_qualifications: preferred_qualifications || [],
      };
    },
    resetEditPostedJob(state) {
      state.post = initialState.post;
    },
    setDraftPostJobInfo(state, action: PayloadAction<IDraftJobResult>) {
      const {
        category,
        job_type,
        additional_info,
        lowest_rate,
        highest_rate,
        company_name,
        created_at,
        location,
        time,
        preferred_qualifications,
        title,
        id,
      } = action.payload;

      state.post = {
        chosen_category: category || 'Other',
        preferred_qualifications: preferred_qualifications || ['No preference'],
        job_type: job_type || 'Full-time',
        company_name: company_name || '',
        job_title: title || '',
        job_location: location || '',
        days_and_hours: time || '',
        lowest_rate: lowest_rate?.toString() || '',
        highest_rate: highest_rate?.toString() || '',
        job_description: additional_info?.join(' ') || '',
      };
    },
    setJobCategory(state, { payload }: PayloadAction<JobCategoriesTypes>) {
      state.post.chosen_category = payload;
    },
    setJobType(state, { payload }: PayloadAction<PreferredJobTypes>) {
      state.post.job_type = payload;
    },
    setChosenValue(
      state,
      {
        payload: { key, value },
      }: PayloadAction<{ key: ChosenKeys; value: string }>
    ) {
      state.post[key] = value;
    },
    setJobRate(
      state,
      { payload }: PayloadAction<{ min?: string; max?: string }>
    ) {
      state.post = {
        ...state.post,
        ...(payload.min !== undefined && { lowest_rate: payload.min || '' }),
        ...(payload.max !== undefined && { highest_rate: payload.max || '' }),
      };
    },
  },
});

export const {
  setAssignmentPoster,
  setPostDrafts,
  setPendingApprovalPosts,
  setSelectedUserSubscription,
  switchActivePreferenceQualificationItem,
  setEditPostedJob,
  resetEditPostedJob,
  setDraftPostJobInfo,
  setJobCategory,
  setJobType,
  setChosenValue,
  setJobRate,
} = postUserJobSlice.actions;

export default postUserJobSlice.reducer;
