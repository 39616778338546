import React from 'react';
import styles from '../_styles.module.scss';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import UIButton from '../../UI/UIButton/UIButton';
import { openModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';

const PurchaseCreditBumpOrShortlistContent = () => {
  const { purchaseCredit } = useAppSelector(state => state.modalDialogReducer);
  const dispatch = useAppDispatch();

  return (
    <div className={styles.modal}>
      <UIModalDialogHeader heading={'Info message'} />
      <div className={styles.modal_body}>
        <p className={styles.message}>
          {`You do not have any more ${purchaseCredit} left :(`}
        </p>
        <UIButton
          title={'Purchase'}
          callback={() =>
            dispatch(
              openModal(
                purchaseCredit === 'bump'
                  ? 'purchase_bump'
                  : 'purchase_shortlist'
              )
            )
          }
          classModificator={styles.back_btn}
        />
      </div>
    </div>
  );
};

export default PurchaseCreditBumpOrShortlistContent;
