import React, { FC, useCallback, useMemo } from 'react';
import './JobDetailsQualification.scss';
import { IPreferredQualifications } from '../../../core/models/models';
import useWebContent from "../../../core/hooks/useWebContent";

interface JobDetailsQualificationProps {
  industry?: string | null;
  qualifications: string | string[] | IPreferredQualifications[] | null;
  fullWidth?: boolean;
}

const JobDetailsQualification: FC<JobDetailsQualificationProps> = ({
  industry,
  qualifications,
  fullWidth,
}) => {
    const [webContent] = useWebContent();

  const joinQualifications = useCallback(
    (qualify: IPreferredQualifications[] | string[] | string | null) => {
      if (qualify === null || qualify.length === 0) {
        return '-';
      } else if (qualify.length === 1) {
        return qualify[0];
      } else if (typeof qualify === 'string') {
        return qualify;
      } else {
        return qualify.join(', ');
      }
    },
    [qualifications]
  );

  return (
    <div
      className='qualification'
      style={{
        width: fullWidth ? '100%' : '50%',
      }}
    >
      {industry && (
        <p className='qualification--title'>
          <b>{webContent.job_industry_web}</b> <span>{industry ? industry : '-'}</span>
        </p>
      )}
      <p className='qualification--title'>
        <b>{webContent.job_preferred_qualification_web}</b>{' '}
        <span>{joinQualifications(qualifications)}</span>
      </p>
    </div>
  );
};

export default JobDetailsQualification;
