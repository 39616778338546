import React, { FC } from 'react';
import FBLogo from '../../assets/images/F&B_logo.png';
import RetailLogo from '../../assets/images/retail-logo.png';
import PromoterLogo from '../../assets/images/promoter_logo.png';
import LogisticLogo from '../../assets/images/logistic_logo.png';
import CustServLogo from '../../assets/images/customer_service_logo.png';
import CleaningLogo from '../../assets/images/cleaning_logo.png';
import DeliveryLogo from '../../assets/images/delivery_logo.png';
import TrainingLogo from '../../assets/images/training_logo.png';
import SecretaryLogo from '../../assets/images/secretary_logo.png';
import TeleMLogo from '../../assets/images/telemarketing_logo.png';
import HealthLogo from '../../assets/images/healthcare_logo.png';
import TuitionLogo from '../../assets/images/tution_logo.png';
import styles from '../JobsByIndustries/_styles.module.scss';
import SortIndustrie from './SortIndustries';
import { JobCategoriesTypes } from '../../core/models/models';

type industriesTypes = {
  name: JobCategoriesTypes;
  logo: any;
  key: string;
};

const industries: industriesTypes[] = [
  { name: 'F&B', logo: FBLogo, key: 'fb1' },
  { name: 'Sales / Retail', logo: RetailLogo, key: 'sr2' },
  { name: 'Promoter', logo: PromoterLogo, key: 'promoter' },
  { name: 'Packer / Logistic', logo: LogisticLogo, key: 'pl3' },
  { name: 'Customer Service', logo: CustServLogo, key: 'cs4' },
  { name: 'Cleaning', logo: CleaningLogo, key: 'ch5' },
  { name: 'Driver / Delivery', logo: DeliveryLogo, key: 'dd6' },
  { name: 'Education / Training', logo: TrainingLogo, key: 'et7' },
  { name: 'Admin / Secretary', logo: SecretaryLogo, key: 'as8' },
  { name: 'Telemarketing', logo: TeleMLogo, key: 'cct9' },
  { name: 'Healthcare', logo: HealthLogo, key: 'hth10' },
  { name: 'Tuition', logo: TuitionLogo, key: 'bhs11' },
];

const JobsByIndustries: FC = () => {
  return (
    <div className={styles.industries}>
      <h3 className={styles.heading}>
        View jobs <span>by industries</span>
      </h3>
      <ul className={styles.industries_list}>
        {industries.map(industrie => (
          <SortIndustrie
            key={industrie.key}
            industrie={industrie.name}
            logo={industrie.logo}
          />
        ))}
      </ul>
    </div>
  );
};

export default JobsByIndustries;
