import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatDate } from '../../../core/functions';
import { Invoice } from '../../../core/models/models';

export interface InvoiceDescription {
  id: number;
  package_name: string;
  shortlists_amount: string;
  reposts_amount: string;
  posts_amount: string;
  validity_days: string;
  total_price: string;
}

interface AdminInvoiceForm {
  billTo: string;
  invoiceId: string;
  invoiceDate: string;
  description: InvoiceDescription[];
}

interface AdminInvoicesState {
  invoices: Invoice[];
  form: AdminInvoiceForm;
}

const getInvoiceDescriptionRowTemplate = () => ({
  id: new Date().getTime() + Math.random(),
  package_name: '',
  shortlists_amount: '',
  reposts_amount: '',
  posts_amount: '',
  validity_days: '',
  total_price: '',
});

const initialState: AdminInvoicesState = {
  invoices: [],
  form: {
    billTo: '',
    invoiceId: '',
    invoiceDate: formatDate(new Date()),
    description: [getInvoiceDescriptionRowTemplate()],
  },
};

const adminInvoicesSlice = createSlice({
  name: 'adminInvoices',
  initialState,
  reducers: {
    setInvoices(state, { payload }: PayloadAction<Invoice[]>) {
      // console.log(payload);
      state.invoices = payload;
    },
    setForm(state, { payload }: PayloadAction<Partial<AdminInvoiceForm>>) {
      state.form = { ...state.form, ...payload };
    },
    resetForm(state) {
      state.form = initialState.form;
    },
    setFormDescription(
      state,
      { payload }: PayloadAction<InvoiceDescription['id'] | undefined>
    ) {
      state.form.description = payload
        ? state.form.description.filter(row => row.id !== payload)
        : state.form.description.concat(getInvoiceDescriptionRowTemplate());
    },
    setFormDescriptionValue(
      state,
      {
        payload: { rowId, key, value },
      }: PayloadAction<{
        rowId: InvoiceDescription['id'];
        key: keyof Omit<InvoiceDescription, 'id'>;
        value: string;
      }>
    ) {
      state.form.description = state.form.description.map(row =>
        row.id === rowId
          ? {
              ...row,
              [key]: value,
            }
          : row
      );
    },
  },
});

export const {
  setInvoices,
  setForm,
  resetForm,
  setFormDescription,
  setFormDescriptionValue,
} = adminInvoicesSlice.actions;

export default adminInvoicesSlice.reducer;
