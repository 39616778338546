import { adminAPI, employerAPI } from '../../core/api/api';
import { setLoading } from '../reducers/ui/HandlerSlice';
import { AppDispatch, RootState } from '../store';
import { openErrorModal, openInfoModal } from '../reducers/ui/ModalDialogSlice';
import {
  repostAssignment,
  setDetailsLoading,
  setEmptyJobReviewCandidates,
  setJobDetails,
  setJobReviewCandidates,
  setJobReviewCandidatesLoading,
  setManageAssignmentPosts,
  setManageJobsLoading,
  setReviewCandidateInfo,
} from '../reducers/api/UserManageJobsSlice';
import { modalContentTexts } from '../../core/constants/modalsContentText';
import {
  IManageJobsParams,
  PostUserJobInformation,
} from '../../core/models/models';
import {
  resetEditPostedJob,
  setAssignmentPoster,
  setPendingApprovalPosts,
  setPostDrafts,
} from '../reducers/api/PostUserJobSlice';

export const getPosterAssignmentJob = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  return employerAPI
    .getPosterAssignmentJob()
    .then(response => {
      dispatch(setAssignmentPoster(response.data));
    })
    .catch(error => {
      // dispatch(openErrorModal(modalContentTexts.error.text));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const newPostAssignmentJob =
  (data: PostUserJobInformation) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));

    const webContent = getState().WebContentReducer.webContent;

    return employerAPI
      .newPostAssignmentJob(data)
      .then(response => {
        dispatch(getPosterAssignmentJob());
        dispatch(resetEditPostedJob());
        dispatch(
          openInfoModal({
            title: 'Success',
            message: webContent.posting_successful_modal_web,
          })
        );

        return response;
      })
      .catch(error => {
        dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const newEditedPostAssignment =
  (data: PostUserJobInformation, assignmentId: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    const newPostdata = { ...data };

    delete newPostdata.subscription_id;

    return employerAPI
      .newEditedPostAssignment(newPostdata, assignmentId)
      .then(response => {
        // console.log(response);
        dispatch(resetEditPostedJob());
        dispatch(
          openInfoModal({
            title: modalContentTexts.saveEditedAssignmentSuccess.title,
            message: modalContentTexts.saveEditedAssignmentSuccess.text,
          })
        );

        return response;
      })
      .catch((error: any) => {
        // console.log(error);
        dispatch(openErrorModal(error.response.data.message));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

//Drafts
export const getPostDrafts =
  (page_number: number) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return employerAPI
      .getPostDrafts(page_number)
      .then(response => {
        dispatch(setPostDrafts(response.data));
      })
      .catch(error => {
        // dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getPendingApprovalPosts =
  (page_number: number) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return employerAPI
      .getPendingApprovalPosts(page_number)
      .then(response => {
        dispatch(setPendingApprovalPosts(response.data));
      })
      .catch(error => {
        // dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getAssignmentJobDetails =
  (assignment_id: string) => async (dispatch: AppDispatch) => {
    dispatch(setDetailsLoading(true));

    return employerAPI
      .getAssignmentJobDetails(assignment_id)
      .then(response => {
        dispatch(setJobDetails(response.data));

        return response;
      })
      .catch(error => {
        // dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        // dispatch(setLoading(false));
        dispatch(setDetailsLoading(false));
      });
  };

export const getManageAssignmentPosts =
  (data: IManageJobsParams) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    dispatch(setManageJobsLoading(true));

    return employerAPI
      .getManageAssignmentPosts(data)
      .then(response => {
        dispatch(setManageAssignmentPosts(response.data));
      })
      .catch(error => {
        // dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
        dispatch(setManageJobsLoading(false));
      });
  };

export const newDraftAssignmentJob =
  (draft: PostUserJobInformation) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return employerAPI
      .newDraftAssignmentJob(draft)
      .then(response => {
        if (response.status_code === 200) {
          dispatch(
            openInfoModal({
              title: modalContentTexts.saveDraftSuccess.title,
              message: modalContentTexts.saveDraftSuccess.text,
            })
          );
        }
      })
      .catch(error => {
        dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const repostEmployerAssignment =
  (assignmentId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return employerAPI
      .repostAssignmentJob(assignmentId)
      .then(response => {
        if (response.status_code === 200) {
          dispatch(repostAssignment());

          if (response.banned) {
            openInfoModal({
              message: response.message,
              title: 'Info message',
            });
          } else {
            dispatch(
              openInfoModal({
                message: `The job ${assignmentId} has been bumped`,
                title: 'Info message',
              })
            );
          }
        }
      })
      .catch(err => {
        if (err.response) {
          if (typeof err.response.data.message === 'string') {
            dispatch(openErrorModal(err.response.data.message));
          } else {
            dispatch(openErrorModal(err.response.status));
          }
        } else if (err.request) {
          dispatch(openErrorModal(err.request.data || err.message));
        } else {
          dispatch(openErrorModal(err.message));
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getAssignmentJobReviewCandidates =
  (assignmentId: string, orderBy: string, page: number) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setEmptyJobReviewCandidates());
    dispatch(setJobReviewCandidatesLoading(true));

    return employerAPI
      .getAssignmentJobReviewCandidates(assignmentId, orderBy, page)
      .then(response => {
        dispatch(setJobReviewCandidates(response));
      })
      .catch(error => {
        // console.log('error', error);
        dispatch(setEmptyJobReviewCandidates());
      })
      .finally(() => {
        dispatch(setJobReviewCandidatesLoading(false));
      });
  };

export const getAssignmentJobReviewCandidateInfo =
  (id: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return employerAPI
      .getAssignmentJobReviewCandidateInfo(id)
      .then(response => {
        dispatch(setReviewCandidateInfo({ id: id, data: response }));
        // dispatch(
        //   openInfoModal({
        //     title: 'Success',
        //     message: 'You have already shortlisted this user',
        //   })
        // );
      })
      .catch(({ response }) => {
        const message = response.data.message || modalContentTexts.error.text;

        // dispatch(openErrorModal(modalContentTexts.error.text));
        dispatch(openErrorModal(message));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
