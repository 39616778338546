import React, { FC } from 'react';
import styles from './_styles.module.scss';
import Icon from '../../../UI/UIIcon/UIIcon';
import TGForwardLink from '../../../UI/TGForwardLink/TGForwardLink';
import { tgChannels } from '../../../../core/constants/links';
import useWebContent from '../../../../core/hooks/useWebContent';

const PhoneImage: FC = () => {
  const [webContent] = useWebContent();

  return (
    <div className={styles.phone_content}>
      <div className={`${styles.loc_item} ${styles.heading}`}>
        <span>{webContent.posting_location_two_web}</span>
      </div>

      <Icon name='telegram' className={styles.tg_main_icon} />

      <div className={styles.tg_channels}>
        <h4 className={styles.tg_heading}>
          {webContent.all_posts_will_be_posted_in_web}
        </h4>
        <TGForwardLink
          urlTg={webContent.all_posts_channel_link_web}
          title={webContent.all_posts_channel_web}
        />
      </div>

      <div className={styles.tg_channels}>
        <h4 className={styles.tg_heading}>
          {webContent.part_time_posted_in_web}
        </h4>
        <TGForwardLink
          urlTg={webContent.part_time_posts_channel_link_web}
          title={webContent.part_time_posts_channel_web}
        />
      </div>

      <div className={styles.tg_channels}>
        <h4 className={styles.tg_heading}>
          {webContent.full_time_posted_in_web}
        </h4>
        <TGForwardLink
          urlTg={webContent.full_time_posts_channel_link_web}
          title={webContent.full_time_posts_channel_web}
        />
      </div>
    </div>
  );
};

export default PhoneImage;
