import { useEffect, useRef } from 'react';
import {
  setSearchParamsJobTypesFilter,
  setSelectedJob,
} from '../../redux/reducers/api/CandidateAssignmentsSlice';
import { PreferredJobTypes } from '../models/models';
import { getAssignmentDetailsAsync } from '../../redux/thunk/assignments';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import {
  selectActiveFilters,
  selectAssignments,
  selectCurrentPage,
  selectSelectedJob,
  selectTab,
} from '../utils/selectors';
import { ArrayParam, StringParam, useQueryParam } from 'use-query-params';
import { useWindowSize } from './useWindowSize';
import { breakpointForJobsList } from '../constants/variables';

const possibleJobTypes = ['Full-time', 'Part-time', 'Internship', 'Contract'];

const useSyncSearchAssignments = (
  searchCallback: (page: number, selectedReplace?: any) => void
) => {
  const dispatch = useAppDispatch();
  const size = useWindowSize();
  const [jobTypes, setJobTypes] = useQueryParam('job_types', ArrayParam);
  const [selectedJobId, setSelectedJobId] = useQueryParam('id', StringParam);

  const activeFilters = useAppSelector(selectActiveFilters);
  const assignments = useAppSelector(selectAssignments);
  const selectedJob = useAppSelector(selectSelectedJob);
  const pageNumber = useAppSelector(selectCurrentPage);
  const tab = useAppSelector(selectTab);

  const initialRender = useRef(true);

  useEffect(() => {
    if (jobTypes || selectedJobId) {
      if (jobTypes) {
        const filteredJobTypes = jobTypes.filter((type, index) => {
          return (
            possibleJobTypes.indexOf(type as PreferredJobTypes) !== -1 &&
            jobTypes.indexOf(type) === index
          );
        });

        dispatch(
          setSearchParamsJobTypesFilter(filteredJobTypes as PreferredJobTypes[])
        );
      } else {
        searchCallback(1, !selectedJobId);
      }

      if (selectedJobId) {
        dispatch(getAssignmentDetailsAsync(selectedJobId as string));
      }
    } else {
      searchCallback(pageNumber, !selectedJob && !selectedJobId);
    }

    return () => {
      setSelectedJobId(null);
      setJobTypes(null);
      dispatch(setSelectedJob(null));
      dispatch(setSearchParamsJobTypesFilter([]));
    };
  }, []);

  useEffect(() => {
    setJobTypes(activeFilters.job_types || null, 'replace');

    if (size.width > breakpointForJobsList) {
      setSelectedJobId(selectedJob?.assignment_id || null, 'replaceIn');
    }
  }, [activeFilters.job_types, selectedJob]);

  useEffect(() => {
    if (!initialRender.current) {
      searchCallback(1, !selectedJob && !selectedJobId);
    }
  }, [activeFilters.job_types]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      searchCallback(1, true);
    }
  }, [tab]);

  useEffect(() => {
    if (!assignments.length) {
      setSelectedJobId(null);
    }
  }, [assignments]);
};

export default useSyncSearchAssignments;
