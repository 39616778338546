import React, { FC, Fragment } from 'react';
import styles from './_styles.module.scss';

const LocationWithLinks: FC<{
  location: string;
  links: { [Key in string]: string };
}> = ({ location, links }) => {
  const linkRegex = /\d{6}/;
  const globalLinkRegex = /\d{6}/g;

  const locationTextParts = location.split(linkRegex);
  const locationLinks = location.match(globalLinkRegex) as Array<
    keyof typeof links
  > | null;

  const linkFullKey = locationLinks ? locationLinks[0] : 0;
  const linkFullHref = links[linkFullKey];

  const linkContent = () => (
    <>
      {!!locationTextParts?.length &&
        !!locationLinks?.length &&
        locationTextParts.map((part, idx, arr) => {
          const linkKey = locationLinks[idx];
          const linkHref = links[linkKey];

          if (!part) {
            return typeof arr[idx + 1] === 'string' ? (
              <p>{locationLinks[idx]}</p>
            ) : (
              part
            );
          }

          return (
            <Fragment key={idx}>
              {part}
              <p className={styles.link}>{linkKey}</p>
            </Fragment>
          );
        })}
    </>
  );

  return (
    <>
      {(!!locationTextParts?.length && !!locationLinks?.length && (
        <a
          className={styles.location_link}
          target='_blank'
          rel='noreferrer'
          href={linkFullHref}
        >
          {linkContent()}
        </a>
      )) || (
        <div className={styles.location}>
          <p>{location}</p>
        </div>
      )}
    </>
  );
};

export default LocationWithLinks;
