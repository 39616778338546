import React, { FC } from 'react';
import styles from './_styles.module.scss';
import { ReactComponent as LineSvg } from '../../../../assets/icons/empl_line_iphone.svg';
import PhoneImage from './PhoneImage';
import useWebContent from '../../../../core/hooks/useWebContent';

const PostLocationMob: FC = () => {
  const [webContent] = useWebContent();

  return (
    <div className={styles.info_mob}>
      <div className={styles.loc_item}>
        <span>{webContent.posting_location_one_web}</span>
      </div>

      <div className={styles.plus_wrap}>
        <span className={styles.plus} />
        <span className={`${styles.plus_vert} ${styles.plus}`} />
      </div>

      <div className={styles.loc_item}>
        <span>{webContent.posting_location_three_web}</span>
      </div>

      <div className={styles.plus_wrap}>
        <span className={styles.plus} />
        <span className={`${styles.plus_vert} ${styles.plus}`} />
      </div>

      <div className={styles.phone_wrap}>
        <PhoneImage />
      </div>

      <div className={styles.phone_bt_line}>
        <LineSvg />
      </div>
    </div>
  );
};

export default PostLocationMob;
