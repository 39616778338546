import React, { FC, useCallback, useEffect } from 'react';
import styles from './_styles.module.scss';
import { Container } from '@mui/material';
import useWebContent from '../../../../core/hooks/useWebContent';

const OtherDetails: FC = () => {
  const [isAnswers, setAnswers] = React.useState<any>({
    price_page_shortlist: '',
    price_page_bump: '',
  });

  const [webContent] = useWebContent();

  // const getAnswers = useCallback(async () => {
  //   const answers = await getAnswersForOtherDetails();
  //
  //   setAnswers(answers);
  // }, []);

  useEffect(() => {}, [webContent]);

  return (
    <section className={styles.details}>
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className={styles.head}>
          {/*<img className={styles.head_img} src={circleImg} />*/}

          <h2 className={styles.head_heading}>
            <span>Other</span> details
          </h2>
        </div>
        <ul className={styles.chat}>
          <li className={styles.chat_massage_qst}>
            <span>Question:</span> {webContent.other_details_question_one_web}
          </li>
          <li className={styles.chat_massage}>
            {webContent.other_details_answer_one_web}
          </li>
          <li className={styles.chat_massage_qst}>
            <span>Question:</span> {webContent.other_details_question_two_web}
          </li>
          <li className={styles.chat_massage}>
            {webContent.other_details_answer_two_web}
          </li>
        </ul>
      </Container>
    </section>
  );
};

export default OtherDetails;
