import React, { useEffect } from 'react';
import { CandidateHeader } from '../../components/Candidate/CandidateHeader';
import Footer from '../../components/Footer/Footer';
import styles from './_styles.module.scss';
import { getCandidateProfileInfo } from '../../redux/thunk/profile';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';

const CandidateLayout: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}) => {
  return (
    <div className={styles.layout}>
      <div className={styles.layout_content}>
        <CandidateHeader />
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default CandidateLayout;
