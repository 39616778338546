import React, { useEffect, useRef } from 'react';
import style from './_index.module.scss';
import AssignmentCard from '../../components/AssignmentCard';
import { getAdminAssignmentById } from '../../redux/thunk/adminAssignments';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import { AdminAssignmentsType } from '../../core/models/models';

const AssignmentsList: React.FC<{
  children?: React.ReactNode;
  assignmentsType: AdminAssignmentsType;
}> = ({ children, assignmentsType, ...rest }) => {
  const dispatch = useAppDispatch();
  const {
    current,
    assignments,
    pagination: { pageNumber, pageSize, totalPages, total },
  } = useAppSelector(state => state.adminAssignments[assignmentsType]);
  const jobsListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = jobsListRef.current;

    node?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [pageNumber]);

  return (
    <div className={style.container} ref={jobsListRef}>
      {assignments?.map(assignment => (
        <AssignmentCard
          key={assignment.assignment_id}
          active={assignment.assignment_id === current?.assignment_id}
          assignment={assignment}
          onClick={() =>
            dispatch(
              getAdminAssignmentById(assignment.assignment_id, assignmentsType)
            )
          }
        />
      ))}
    </div>
  );
};

export default AssignmentsList;
