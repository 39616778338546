import React, { FC } from 'react';
import styles from './_styles.module.scss';
import UIRouterLink from '../../../UI/UIRouterLink/UIRouterLink';
import UIButton from '../../../UI/UIButton/UIButton';
import { openModal } from '../../../../redux/reducers/ui/ModalDialogSlice';
import { useAppDispatch } from '../../../../core/hooks/reduxHooks';
import { setSelectedAssignmentId } from '../../../../redux/reducers/ui/SubscriptionPackagesSlice';
import useWebContent from '../../../../core/hooks/useWebContent';

const ShortlistsAndBumps: FC<{
  assignmentId: string;
  shortlisted: number;
  shortlists_left: number;
  bumps: number;
}> = ({ assignmentId, shortlisted, shortlists_left, bumps }) => {
  const dispatch = useAppDispatch();
  const [webContent] = useWebContent();

  return (
    <div className={styles.payment_info}>
      <div className={styles.wrapper}>
        <p className={styles.value}>{shortlisted}</p>
        <p className={styles.title}>{webContent.shortlisted_web}</p>
      </div>
      <div className={styles.wrapper}>
        <p className={styles.value}>{shortlists_left}</p>
        <p className={styles.title}>{webContent.shortlists_left_web}</p>
        <UIButton
          title={webContent.buy_shortlists_web}
          callback={() => {
            dispatch(setSelectedAssignmentId(assignmentId));
            dispatch(openModal('purchase_shortlist'));
          }}
          classModificator={styles.btn}
        />
      </div>
      <div className={styles.wrapper}>
        <p className={styles.value}>{bumps}</p>
        <p className={styles.title}>{webContent.bumps_left_web}</p>
        <UIButton
          title={webContent.buy_bumps_web}
          callback={() => {
            dispatch(setSelectedAssignmentId(assignmentId));
            dispatch(openModal('purchase_bump'));
          }}
          classModificator={styles.btn}
        />
      </div>
    </div>
  );
};

export default ShortlistsAndBumps;
