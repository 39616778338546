import React, { FC, useCallback } from 'react';
import styles from './_styles.module.scss';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { ReactComponent as ArrowSvg } from '../../../../assets/icons/dropdown-arrow-up.svg';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&:MuiPaper-root-MuiAccordion-root.Mui-expanded': {
    margin: 0,
    width: '100%',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0 20px',
  borderTop: ' 0.5px solid rgba(21%, 26%, 37%, 0.5)',
  '&:MuiPaper-root-MuiAccordion-root.Mui-expanded': {
    margin: 0,
    width: '100%',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowSvg className={styles.arrowSvg} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  width: '100%',
  alignContent: 'center',
  flexDirection: 'row-reverse',
  padding: 0,
  minHeight: '30px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    display: 'none',
  },
}));

const ReviewCandidatesAccordion: FC = ({ children }) => {
  const [isActiveAccordion, setActiveAccordion] = React.useState<boolean>(true);

  return (
    <div className={styles.accordion}>
      <Accordion
        expanded={isActiveAccordion}
        // onChange={() => setActiveAccordion(!isActiveAccordion)}
      >
        {/*<AccordionSummary*/}
        {/*  aria-controls='review-candidates'*/}
        {/*  id='review-candidates'*/}
        {/*/>*/}

        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ReviewCandidatesAccordion;
