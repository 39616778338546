import React, { FC } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import Tabs from '../../components/Tabs/Tabs';
import styles from './_styles.module.scss';
import RequireAuth from '../../containers/hoc/RequireAuth';

const postAJobMap = [
  {
    goTo: '/employer/post-jobs',
    title: 'Post a job',
    end: true,
    className: styles.tab,
  },
  // {
  //   goTo: '/employer/post-jobs/drafts',
  //   title: 'Drafts',
  // },
  {
    goTo: '/employer/post-jobs/pending-approval',
    title: 'Pending approval',
    className: styles.tab,
  },
];

const editAJobMap = [
  {
    goTo: '/employer/post-jobs',
    title: 'Edit a job',
    end: true,
    className: styles.tab,
  },
  // {
  //   goTo: '/employer/post-jobs/drafts',
  //   title: 'Drafts',
  // },
  {
    goTo: '/employer/post-jobs/pending-approval',
    title: 'Pending approval',
    className: styles.tab,
  },
];

const EmployerPostJobPage: FC = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const assignmentId = searchParams.get('edit_id');

  const currentPathViewJob = location.pathname.includes('/view-job');

  return (
    <RequireAuth role={'employer'}>
      {!currentPathViewJob && (
        <div className={styles.tabs_wrap}>
          <Tabs links={assignmentId ? editAJobMap : postAJobMap} />
        </div>
      )}
      <Outlet />
    </RequireAuth>
  );
};

export default EmployerPostJobPage;
