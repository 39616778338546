import React, { FC } from 'react';
import styles from './_styles.module.scss';
import { useNavigate } from 'react-router-dom';

const UIGoBack: FC<{ searchPage?: boolean }> = ({ searchPage }) => {
  const navigate = useNavigate();

  return (
    <button
      type='button'
      className={styles.go_back}
      onClick={searchPage ? () => navigate('/search') : () => navigate(-1)}
    />
  );
};

export default UIGoBack;
