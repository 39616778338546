import React, { useEffect, useMemo, useState } from 'react';
import Input from '../../../components/UI/Input';
import Table from '../../../components/UI/Table';
import UIButton from '../../../components/UI/UIButton/UIButton';
import UiDatePicker from '../../../components/UI/UIDatePicker/UIDatePicker';
import InfoGridCard from '../../../containers/InfoGridCard/InfoGridCard';
import { AdminInvoiceDescriptionTableHeaders } from '../../../core/constants/config';
import { formatDate, getDateFromLocaleString } from '../../../core/functions';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { CreateAdminInvoicePayload } from '../../../core/models/models';
import { adminAPI } from '../../../core/api/api';
import {
  resetForm,
  setForm,
  setFormDescription,
  setFormDescriptionValue,
} from '../../../redux/reducers/api/AdminInvoicesSlice';
import {
  openErrorModal,
  openInfoModal,
} from '../../../redux/reducers/ui/ModalDialogSlice';
import style from './_index.module.scss';
import { downloadPDFBlob } from '../../../core/utils/downloadBlob';

const CreateInvoice: React.FC = () => {
  const [isTax, setTax] = useState<number>(7);
  const { billTo, invoiceId, invoiceDate, description } = useAppSelector(
    state => state.adminInvoices.form
  );
  const dispatch = useAppDispatch();

  const subtotal = useMemo(
    () => description.reduce((acc, current) => acc + +current.total_price, 0),
    [description]
  );

  useEffect(() => {
    adminAPI.getCreateAdminInvoicePoster().then(res => {
      setTax(res.GST);
    });
  }, []);

  const total = useMemo(
    () => (subtotal + subtotal * (isTax / 100)).toFixed(2),
    [subtotal]
  );

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const payload: CreateAdminInvoicePayload = {
      name: billTo,
      date: invoiceDate,
      number: invoiceId,
      item_objects: description.map(
        ({
          package_name,
          reposts_amount,
          shortlists_amount,
          posts_amount,
          validity_days,
          total_price,
        }) => ({
          package_name,
          reposts_amount,
          shortlists_amount,
          posts_amount,
          validity_days,
          price: +total_price,
        })
      ),
    };

    adminAPI
      .createAdminInvoice(payload)
      .then(data => {
        // const url = window.URL.createObjectURL(
        //   new Blob([data], { type: 'application/pdf' })
        // );
        // const link = document.createElement('a');
        //
        // console.log(url);
        //
        // link.href = url;
        // link.download = `${invoiceId || 'invoice'}.pdf`;
        // document.body.appendChild(link);
        // link.click();
        //
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(url);
        downloadPDFBlob(data, `${invoiceId || 'invoice'}`);

        dispatch(
          openInfoModal({
            title: 'Invoice creation',
            message: 'Invoice is successfully created',
          })
        );
        dispatch(resetForm());
      })
      .catch(error => {
        dispatch(
          openErrorModal(
            error?.response?.data?.message || 'Something went wrong'
          )
        );
      });
  };

  return (
    <form className={style.container} onSubmit={onSubmit}>
      <InfoGridCard heading='InvoiceInfo' logo='info' position='top'>
        <div className={style.invoiceInfo}>
          <Input
            id='billTo'
            label='Bill to'
            value={billTo}
            onChange={({ target: { value } }) =>
              dispatch(setForm({ billTo: value }))
            }
          />
          <Input
            id='invoiceId'
            label='Invoice #'
            value={invoiceId}
            onChange={({ target: { value } }) =>
              dispatch(setForm({ invoiceId: value }))
            }
          />
          <div className={style.datePickerContainer}>
            <label htmlFor='invoiceDate'>Invoice Date</label>
            <UiDatePicker
              selected={getDateFromLocaleString(invoiceDate)}
              onChange={date =>
                dispatch(
                  setForm({
                    invoiceDate: formatDate(
                      date ?? getDateFromLocaleString(invoiceDate)
                    ),
                  })
                )
              }
              id='invoiceDate'
            />
          </div>
        </div>
      </InfoGridCard>
      <InfoGridCard position='bottom' heading='Add description' logo='book'>
        <Table className={style.table}>
          <Table.Head>
            {AdminInvoiceDescriptionTableHeaders.map(({ label, id }) => (
              <th key={id}>{label}</th>
            ))}
            <th />
          </Table.Head>
          <Table.Body>
            {description.map(row => (
              <tr key={row.id}>
                {AdminInvoiceDescriptionTableHeaders.map(({ id }) => (
                  <td key={id}>
                    {id === 'total_price' ? <span>$</span> : null}
                    <input
                      type={id === 'package_name' ? 'text' : 'number'}
                      value={row[id]}
                      onChange={({ target: { value } }) =>
                        dispatch(
                          setFormDescriptionValue({
                            rowId: row.id,
                            key: id,
                            value,
                          })
                        )
                      }
                      onKeyDown={event => {
                        if (id !== 'package_name' && event.key === 'e') {
                          event.preventDefault();
                        }
                      }}
                    />
                  </td>
                ))}
                <td>
                  <button
                    disabled={description.length <= 1}
                    onClick={() => dispatch(setFormDescription(row.id))}
                  >
                    &ndash;
                  </button>
                </td>
              </tr>
            ))}
          </Table.Body>
        </Table>
        <UIButton
          classModificator={style.greenButton}
          title='+ Add line'
          callback={() => dispatch(setFormDescription())}
        />
        <div className={style.paymentInfo}>
          <p className={style.subtotal}>Subtotal: ${subtotal}</p>
          <p className={style.tax}>Tax rate {Math.round(isTax)} %</p>
          <hr />
          <p className={style.total}>
            Total: <span>${total}</span>
          </p>
        </div>
      </InfoGridCard>
      <UIButton
        title='Save PDF'
        type='submit'
        classModificator={style.submitButton}
      />
    </form>
  );
};

export default CreateInvoice;
