import { Cookies } from 'react-cookie';
import { apiClient, apiClientPaymentIntent } from '../services/axios';

export const AuthTokenApi = (token: string | null) => {
  const cookies = new Cookies();

  if (token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiClientPaymentIntent.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    cookies.remove('token');
    cookies.remove('refresh_token');
    delete apiClient.defaults.headers.common.Authorization;
    delete apiClientPaymentIntent.defaults.headers.common.Authorization;
  }
};

export const setTokensToCookies = (
  access_token: string,
  refresh_token: string
) => {
  const cookies = new Cookies();

  cookies.set('token', access_token);
  cookies.set('refresh_token', refresh_token);
};
