import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './_styles.module.scss';
import InfoGridCard from '../../../containers/InfoGridCard/InfoGridCard';
import UIInput from '../../UI/UIInput/UIInput';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import AccountStatus from './AccountStatus';
import ProposedSalary from '../../ProposedSalary/ProposedSalary';
import UIProfileCheckbox from '../../UI/UIProfileCheckbox/UIProfileCheckbox';
import UIButton from '../../UI/UIButton/UIButton';
import { UIMultipleSelect } from '../../UI/UIMultipleSelect/UIMultipleSelect';
import {
  IAvailableSubscription,
  IPreferredQualifications,
} from '../../../core/models/models';
import {
  switchActivePreferenceQualificationItem,
  setJobCategory,
  setJobType,
  setChosenValue,
  setJobRate,
  setEditPostedJob,
  resetEditPostedJob,
  setSelectedUserSubscription,
} from '../../../redux/reducers/api/PostUserJobSlice';
import { validateCurrentInputValue } from '../../../core/utils/validateCurrentInputValue';
import UITextarea from '../../UI/UITextarea/UITextarea';
import {
  emojisRegex,
  proposedSalaryRegex,
} from '../../../core/constants/regex';
import { useSearchParams } from 'react-router-dom';
import {
  getPosterAssignmentJob,
  newDraftAssignmentJob,
  newEditedPostAssignment,
  newPostAssignmentJob,
} from '../../../redux/thunk/employerAssignments';
import UISelect from '../../UI/UISelect/UISelect';
import {
  openInfoModal,
  openModal,
} from '../../../redux/reducers/ui/ModalDialogSlice';
import useWebContent from '../../../core/hooks/useWebContent';

const PostJobSection: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSelectedSubscription, setSelectedSubscription] =
    useState<IAvailableSubscription>({
      handle: 'None',
      subscription_id: '',
      posts_left: 0,
      number_of_repost: 0,
      number_of_shortlist: 0,
      until_date: '',
    });
  const [isError, setError] = useState<boolean>(false);
  const { post, posterInfo } = useAppSelector(
    state => state.postUserJobReducer
  );
  const { jobDetails } = useAppSelector(state => state.UserManageJobsReducer);

  const dispatch = useAppDispatch();
  const postAJobEmployerRef = useRef<HTMLFormElement>(null);
  const [webContent] = useWebContent();

  const assignmentId = useMemo(
    () => searchParams.get('edit_id'),
    [searchParams]
  );
  const draftId = useMemo(() => searchParams.get('draft_id'), [searchParams]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();

      if (
        posterInfo.subscriptions.basic_subscriptions.length === 0 &&
        posterInfo.subscriptions.premium_subscriptions.length === 0 &&
        !assignmentId
      ) {
        dispatch(openModal('purchase_more_credits'));

        return;
      }

      if (
        !post.company_name ||
        !post.chosen_category ||
        !post.preferred_qualifications.length ||
        !post.job_type ||
        !post.job_title ||
        !post.job_location ||
        !post.job_description ||
        !post.highest_rate ||
        !post.days_and_hours
      ) {
        setError(true);

        return;
      } else {
        setError(false);

        if (assignmentId) {
          return dispatch(newEditedPostAssignment(post, assignmentId)).then(
            res => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (res.status === 200) {
                // console.log(assignmentId);
                searchParams.delete('edit_id');
                setSearchParams(searchParams, { replace: true });
              }
            }
          );
        } else {
          if (isSelectedSubscription.subscription_id.length) {
            dispatch(newPostAssignmentJob(post));
          } else {
            dispatch(
              openInfoModal({
                message: 'Please select a subscription',
              })
            );
          }
        }
      }
    },
    [post, isSelectedSubscription, dispatch, assignmentId]
  );

  const onChangeLowestRate = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (value.match(proposedSalaryRegex) || value === '') {
        dispatch(setJobRate({ min: value.trim() }));
      }
    },
    []
  );

  const onChangeHighestRate = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (value.match(proposedSalaryRegex) || value === '') {
        dispatch(setJobRate({ max: value.trim() }));
      }
    },
    []
  );

  const emojisHandler = (value: string): boolean => {
    return (
      !!value.match(emojisRegex)?.length ||
      value.includes('!') ||
      value.includes('?')
    );
  };

  useEffect(() => {
    dispatch(
      setSelectedUserSubscription(isSelectedSubscription.subscription_id)
    );
  }, [isSelectedSubscription.subscription_id, posterInfo.subscriptions]);

  useEffect(() => {
    dispatch(getPosterAssignmentJob());
    if (assignmentId && jobDetails) {
      dispatch(setEditPostedJob(jobDetails));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!assignmentId && !draftId) {
      dispatch(resetEditPostedJob());
    }
  }, [searchParams, assignmentId, draftId]);

  return (
    <section id={'Post_a_job'} className={styles.section}>
      <form
        ref={postAJobEmployerRef}
        className={styles.form}
        onSubmit={onSubmit}
      >
        {!assignmentId && (
          <InfoGridCard
            heading={'Account status'}
            className={`${styles.infoGridCard} ${
              ''
              // !isSelectedSubscription.subscription_id && styles.aloneGrid
            }`}
            logo='status'
            role='employer'
            position={'top'}
          >
            <AccountStatus
              subscriptions={posterInfo.subscriptions}
              selectedSubscription={isSelectedSubscription}
              setSelectedSubscription={setSelectedSubscription}
            />
          </InfoGridCard>
        )}

        {
          // (isSelectedSubscription.subscription_id || assignmentId) &&
          <>
            <InfoGridCard
              heading='Job info'
              className={styles.infoGridCard}
              logo='info'
              role='employer'
              position={assignmentId ? 'top' : ''}
            >
              <div className={styles.job_info}>
                <UISelect
                  heading={'Please select the job category:'}
                  list={posterInfo.job_categories}
                  selectedOption={post.chosen_category}
                  setSelectedOption={option => dispatch(setJobCategory(option))}
                  error={isError && !post.chosen_category}
                />
                <UIMultipleSelect
                  heading={'Preferred qualification:'}
                  placeholder={''}
                  possibleList={posterInfo.preferred_qualifications}
                  error={isError && !post.preferred_qualifications.length}
                  activeList={post.preferred_qualifications}
                  onChangeList={(event, option: IPreferredQualifications) => {
                    dispatch(
                      switchActivePreferenceQualificationItem({
                        array: 'preferred_qualifications',
                        value: option,
                        add: event.target.checked,
                      })
                    );
                  }}
                />
                <div className={styles.job_types}>
                  <p
                    className={`${styles.heading} ${
                      isError && !post.job_type && styles.heading_error
                    }`}
                  >
                    Job type
                  </p>
                  <ul className={styles.types_list}>
                    {posterInfo.job_types.map(type => (
                      <li key={type}>
                        <UIProfileCheckbox
                          name='job_types'
                          className={styles.checkbox}
                          title={type}
                          id={type}
                          type={'radio'}
                          checked={post.job_type === type}
                          onChange={() => {
                            dispatch(setJobType(type));
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </InfoGridCard>
            <InfoGridCard
              heading='About'
              className={styles.infoGaboutridCard}
              logo='book'
              role='employer'
              position='bottom'
            >
              <div className={styles.about}>
                <UIInput
                  id={'company_name'}
                  title={webContent.company_name_field_web}
                  placeholder={webContent.company_name_field_prefill_web}
                  borderColor={'secondary'}
                  error={isError && !post.company_name}
                  maxLength={posterInfo.company_name_character_limit}
                  value={validateCurrentInputValue(post.company_name)}
                  onChange={event => {
                    return emojisHandler(event.target.value)
                      ? dispatch(
                          openInfoModal({
                            title: '',
                            message: webContent.has_bad_characters_modal,
                          })
                        )
                      : dispatch(
                          setChosenValue({
                            key: 'company_name',
                            value: validateCurrentInputValue(
                              event.target.value
                            ),
                          })
                        );
                  }}
                />
                <UIInput
                  id={'job_title'}
                  title={webContent.job_title_field_web}
                  placeholder={webContent.job_title_field_prefill_web}
                  borderColor={'secondary'}
                  error={isError && !post.job_title}
                  value={validateCurrentInputValue(post.job_title)}
                  maxLength={posterInfo.job_title_character_limit}
                  onChange={event => {
                    return emojisHandler(event.target.value)
                      ? dispatch(
                          openInfoModal({
                            title: '',
                            message: webContent.has_bad_characters_modal,
                          })
                        )
                      : dispatch(
                          setChosenValue({
                            key: 'job_title',
                            value: validateCurrentInputValue(
                              event.target.value
                            ),
                          })
                        );
                  }}
                />
                <UIInput
                  id={'job_location'}
                  title={webContent.job_location_field_web}
                  placeholder={webContent.job_location_field_prefill_web}
                  borderColor={'secondary'}
                  error={isError && !post.job_location}
                  value={validateCurrentInputValue(post.job_location)}
                  maxLength={posterInfo.location_character_limit}
                  onChange={event => {
                    return emojisHandler(event.target.value)
                      ? dispatch(
                          openInfoModal({
                            title: '',
                            message: webContent.has_bad_characters_modal,
                          })
                        )
                      : dispatch(
                          setChosenValue({
                            key: 'job_location',
                            value: validateCurrentInputValue(
                              event.target.value
                            ),
                          })
                        );
                  }}
                />
                <UIInput
                  id={'day_and_hours'}
                  title={webContent.day_and_time_prefix_web}
                  placeholder={webContent.days_and_hours_field_prefill_web}
                  borderColor={'secondary'}
                  error={isError && !post.days_and_hours}
                  value={validateCurrentInputValue(post.days_and_hours)}
                  maxLength={posterInfo.working_hours_character_limit}
                  onChange={event => {
                    return emojisHandler(event.target.value)
                      ? dispatch(
                          openInfoModal({
                            title: '',
                            message: webContent.has_bad_characters_modal,
                          })
                        )
                      : dispatch(
                          setChosenValue({
                            key: 'days_and_hours',
                            value: validateCurrentInputValue(
                              event.target.value
                            ),
                          })
                        );
                  }}
                />

                <div className={styles.rate_wrap}>
                  <ProposedSalary
                    title={webContent.job_proposed_salary_web}
                    typeOfJob={post.job_type}
                    lowestRate={post.lowest_rate}
                    highestRate={post.highest_rate}
                    onChangeLowestRate={onChangeLowestRate}
                    onChangeHighestRate={onChangeHighestRate}
                    error={isError && !post.highest_rate}
                    color={'employer'}
                  />
                </div>

                <label className={styles.description}>
                  <UITextarea
                    id={'job_description'}
                    title={webContent.job_description_field_web}
                    placeholder={webContent.job_description_field_prefill_web}
                    rows={10}
                    maxLength={posterInfo.job_description_character_limit}
                    value={validateCurrentInputValue(post.job_description)}
                    error={isError && !post.job_description}
                    onChange={event => {
                      return emojisHandler(event.target.value)
                        ? dispatch(
                            openInfoModal({
                              title: '',
                              message: webContent.has_bad_characters_modal,
                            })
                          )
                        : dispatch(
                            setChosenValue({
                              key: 'job_description',
                              value: validateCurrentInputValue(
                                event.target.value
                              ),
                            })
                          );
                    }}
                  />
                </label>
              </div>
            </InfoGridCard>

            <div className={styles.form_btns}>
              {/*<UIButton*/}
              {/*  title='Save draft'*/}
              {/*  classModificator={`${styles.btn} ${styles.draft_btn}`}*/}
              {/*  callback={() => dispatch(newDraftAssignmentJob(post))}*/}
              {/*/>*/}
              <UIButton
                title={assignmentId ? 'Update job' : 'Post job'}
                type='submit'
                // disabled={!posterInfo.posts_left}
                classModificator={`${styles.btn} ${styles.submit_btn}`}
                /*{!posterInfo.posts_left && styles.submit_disabled}*/
              />
            </div>
          </>
        }
      </form>
    </section>
  );
};

export default PostJobSection;
