import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import InfoGridCard from '../../../containers/InfoGridCard/InfoGridCard';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import UIProfileCheckbox from '../../UI/UIProfileCheckbox/UIProfileCheckbox';
import ProfileFormGroup from '../../../containers/ProfileFormGroup/ProfileFormGroup';
import noPhoto from '../../../assets/images/profile-nophoto.png';
import UIInput from '../../UI/UIInput/UIInput';
import Icon from '../../UI/UIIcon/UIIcon';
import UIRadioButton from '../../UI/UIRadioButton/UIRadioButton';
import UIButton from '../../UI/UIButton/UIButton';
import './Profile.scss';
import 'react-datepicker/dist/react-datepicker.css';
import UIDatePicker from '../../UI/UIDatePicker/UIDatePicker';
import {
  setChosenJobCategory,
  setChosenValue,
  setUserPhoto,
  setVerifyEmail,
  switchChosenArrayItem,
} from '../../../redux/reducers/api/UserInformationSlice';
import { ProfileFormType } from '../../../core/models/models';
import {
  openInfoModal,
  openModal,
} from '../../../redux/reducers/ui/ModalDialogSlice';
import { setUserPhoneNumber } from '../../../redux/reducers/api/UserTokenSlice';
import {
  getResume,
  updateCandidateProfileInfo,
  verifyEmailHandler,
} from '../../../redux/thunk/profile';
import { UIMultipleSelect } from '../../UI/UIMultipleSelect/UIMultipleSelect';
import { getDate, getStringDate } from '../../../core/functions';
import styles from '../../ModalsContent/ApplyModalContent/_styles.module.scss';
import useWebContent from '../../../core/hooks/useWebContent';

const ProfileInformation: FC<{ registration: boolean }> = ({
  registration,
}) => {
  const dispatch = useAppDispatch();
  const { information, isLoadingUpdateProfile, requiredFields, complete } =
    useAppSelector(state => state.userInformationReducer);
  const [webContent] = useWebContent();
  const [resume, setResume] = useState<File | null>(null);
  const [photo, setPhoto] = useState<File | null>(null);

  const fileUploader = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'resume' | 'photo'
  ) => {
    e.preventDefault();

    if (e.target.files) {
      switch (type) {
        case 'resume': {
          if (e.target.files[0].size > information.chosenResumeMaxFileSize) {
            const message =
              webContent.candidate_profile_resume_attachment_size_modal_web.split(
                '{}'
              );
            const fileSize = (
              information.chosenResumeMaxFileSize /
              (1024 * 1024)
            ).toFixed(2);

            dispatch(
              openInfoModal({
                title: 'resume',
                message: message.join(fileSize),
              })
            );
          } else {
            setResume(e.target.files[0]);
          }

          break;
        }

        case 'photo': {
          if (e.target.files[0].size > information.profilePictureMaxSize) {
            const message =
              webContent.candidate_profile_picture_size_modal_web.split('{}');
            const fileSize = (
              information.profilePictureMaxSize /
              (1024 * 1024)
            ).toFixed(2);

            dispatch(
              openInfoModal({ title: 'Photo', message: message.join(fileSize) })
            );
          } else {
            setPhoto(e.target.files[0]);
            dispatch(setUserPhoto());
          }

          break;
        }
      }
    }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();

      if (!complete && requiredFields?.length) {
        const conditionTest = requiredFields.map(require => {
          if (!information[require as keyof typeof information]) {
            return false;
          }

          if (
            typeof information[require as keyof typeof information] === 'string'
          ) {
            return !!information[require as keyof typeof information];
          }

          if (Array.isArray(information[require as keyof typeof information])) {
            return (
              (information[require as keyof typeof information] as [])?.length >
              0
            );
          }

          return true;

          // return (
          //   (Array.isArray(information[require as keyof typeof information]) ||
          //     !!information[require as keyof typeof information]) &&
          //   information[require as keyof typeof information]?.length > 0
          // );

          // return (!!information[require as keyof typeof information].length
          //   ?
          //     !!information[require as keyof typeof information].length > 0
          //   : true;
        });

        if (
          !conditionTest.every(item => item) ||
          (requiredFields?.includes('chosenProfilePicture') &&
            !information.profilePictureComplete &&
            !photo)
        ) {
          dispatch(
            openInfoModal({
              title: '',
              message: webContent.profile_incomplete_web,
            })
          );

          return;
        }
      }

      const profile: ProfileFormType = {
        ...(information.birthDate && { birthDate: information.birthDate }),
        ...(information.name && { name: information.name }),
        ...(information.email && { email: information.email }),
        ...(information.highestQualification && {
          highestQualification: information.highestQualification,
        }),
        ...(information.years_working && {
          years_working: +information.years_working,
        }),
        ...(information.detailsOfExperience && {
          detailsOfExperience: information.detailsOfExperience,
        }),
        ...(information.chosenGender && {
          chosenGender: information.chosenGender,
        }),
        ...(information.chosenCitizenship && {
          chosenCitizenship: information.chosenCitizenship,
        }),
        ...(information.chosenGroup && {
          chosenGroup: information.chosenGroup,
        }),
        ...(information.chosenRace && { chosenRace: information.chosenRace }),
        ...(information.chosenJobCategories && {
          chosenJobCategories: information.chosenJobCategories,
        }),
        ...(information.chosenLocations && {
          chosenLocations: information.chosenLocations,
        }),
        ...(information.chosenNotifications && {
          chosenNotifications: information.chosenNotifications,
        }),
        ...(information.chosenPreferredJobTypes && {
          chosenPreferredJobTypes: information.chosenPreferredJobTypes,
        }),
      };

      const profileData = new FormData();

      for (const key in profile) {
        if (Array.isArray(profile[key as keyof typeof profile])) {
          profileData.append(
            key,
            JSON.stringify(profile[key as keyof typeof profile])
          );
        } else {
          profileData.append(
            key,
            profile[key as keyof typeof profile] as string
          );
        }
      }

      if (resume) {
        profileData.append('chosenResume', resume);
      }

      if (photo) {
        profileData.append('chosenProfilePicture', photo);
      }

      dispatch(updateCandidateProfileInfo(profileData));
    },
    [photo, resume, information, complete, requiredFields]
  );

  const profilePictureSrc = useMemo(
    () =>
      photo
        ? URL.createObjectURL(photo)
        : information.chosenProfilePicture
        ? `data:image/jpeg;base64,${information.chosenProfilePicture}`
        : noPhoto,
    [photo, information.chosenProfilePicture]
  );

  return (
    <form onSubmit={onSubmit} className={'profile__information info-grid'}>
      {!registration && (
        <InfoGridCard
          heading={'Information'}
          logo={'info'}
          position={'top'}
          role='candidate'
        >
          <div className='profile__information_main-wrapper'>
            {information.chosenProfilePicture !== undefined && (
              <div className='profile__information--photo-block'>
                <div className='profile__information--photo-wrapper'>
                  <img
                    src={profilePictureSrc}
                    className={'photo-img'}
                    alt='No photo'
                  />
                  <input
                    type='file'
                    id='photo'
                    accept='image/png, image/jpeg, image/jpg'
                    onChange={event => fileUploader(event, 'photo')}
                  />
                  <UIButton
                    title='Upload photo'
                    classModificator='upload-photo'
                  />
                </div>
                {requiredFields?.includes('chosenProfilePicture') &&
                  !information.profilePictureComplete &&
                  !photo && (
                    <span
                      style={{
                        color: '#f32a2a',
                        fontWeight: 500,
                      }}
                    >
                      Required
                    </span>
                  )}
              </div>
            )}
            <div className='profile__information--inputs-wrapper'>
              {information.hpNumber !== undefined && (
                <UIInput
                  id={'candidate_phone'}
                  title='Phone:'
                  name='phone'
                  value={information.hpNumber}
                  onChange={event =>
                    dispatch(
                      setChosenValue({
                        key: 'hpNumber',
                        value: event.target.value,
                      })
                    )
                  }
                  phone
                  button='Change'
                  buttonHandler={() => dispatch(openModal('newPhone'))}
                  disabled={true}
                />
              )}
              {information.hpNumber !== undefined && (
                <UIInput
                  id={'candidate_pass'}
                  title='Password:'
                  type='password'
                  value={'88888888888'}
                  disabled={true}
                  button='Change'
                  buttonHandler={() => {
                    dispatch(
                      setUserPhoneNumber(information.hpNumber as string)
                    );
                    dispatch(openModal('updatePasswordProfile'));
                  }}
                />
              )}
              <div className='profile__field-wrapper'>
                {information.name !== undefined && (
                  <UIInput
                    id={'candidate_name'}
                    name='name'
                    title='Name:'
                    required={requiredFields?.includes('name')}
                    value={information.name}
                    error={
                      requiredFields?.includes('name') &&
                      !information.name?.length
                    }
                    onChange={event =>
                      dispatch(
                        setChosenValue({
                          key: 'name',
                          value: event.target.value,
                        })
                      )
                    }
                  />
                )}
              </div>
              {information.email !== undefined && (
                <UIInput
                  id={'candidate_email'}
                  title='Email:'
                  type='email'
                  name='email'
                  button={
                    ((!information.is_verified_email ||
                      (information.is_verified_email &&
                        information.email !== information.verified_email)) &&
                      'Verify') ||
                    undefined
                  }
                  value={information.email}
                  required={requiredFields?.includes('email')}
                  error={
                    requiredFields?.includes('email') &&
                    !information.email?.length
                  }
                  onChange={event =>
                    dispatch(
                      setChosenValue({
                        key: 'email',
                        value: event.target.value,
                      })
                    )
                  }
                  buttonHandler={() => {
                    if (information.email?.length) {
                      dispatch(
                        verifyEmailHandler({ email: information.email })
                      );
                      dispatch(setVerifyEmail('candidate'));
                    }
                  }}
                />
              )}
              {information.highestQualification !== undefined && (
                <UIInput
                  id='qualification'
                  title='Highest Qualification:'
                  direction='row'
                  className={styles.row_input}
                  value={information.highestQualification}
                  required={requiredFields?.includes('highestQualification')}
                  error={
                    requiredFields?.includes('highestQualification') &&
                    !information.highestQualification?.length
                  }
                  maxLength={50}
                  onChange={event =>
                    dispatch(
                      setChosenValue({
                        key: 'highestQualification',
                        value: event.target.value,
                      })
                    )
                  }
                />
              )}
              <div className='profile__field-wrapper'>
                {information.years_working_values !== undefined && (
                  <UIMultipleSelect
                    className='profile__selectbox'
                    heading={webContent.years_of_working_experience_web}
                    possibleList={information.years_working_values}
                    activeList={
                      typeof information.years_working === 'number'
                        ? [information.years_working]
                        : []
                    }
                    error={
                      requiredFields?.includes('years_working') &&
                      !information.years_working
                    }
                    onChangeList={(event, option: string) => {
                      dispatch(
                        setChosenValue({
                          key: 'years_working',
                          value: option,
                        })
                      );
                    }}
                    required={requiredFields?.includes('years_working')}
                  />
                )}
              </div>
            </div>
          </div>
          <div className='profile__information--resume-wrapper'>
            {information.detailsOfExperience !== undefined && (
              <>
                <p
                  style={{
                    color:
                      requiredFields?.includes('detailsOfExperience') &&
                      !information.detailsOfExperience?.length
                        ? '#f32a2a'
                        : '#35435e',
                  }}
                >
                  {webContent.details_of_your_past_working_experience_web}
                  {requiredFields?.includes('detailsOfExperience') && (
                    <span
                      style={{
                        color: !information.detailsOfExperience?.length
                          ? '#f32a2a'
                          : '#35435e',
                      }}
                    >
                      *
                    </span>
                  )}
                </p>
                <textarea
                  id={'detailsOfExperience'}
                  rows={10}
                  name='detailsOfExperience'
                  defaultValue={information.detailsOfExperience}
                  maxLength={information.experienceLimit}
                  onChange={event =>
                    dispatch(
                      setChosenValue({
                        key: 'detailsOfExperience',
                        value: event.target.value,
                      })
                    )
                  }
                  className='profile__information--resume-textarea'
                />
              </>
            )}
            <div className={'profile__information--resume-file-wrapper'}>
              {resume ? (
                <div className='profile__information--resume-file'>
                  <span className='profile__information--resume-name'>
                    {resume.name}
                  </span>
                  <Icon
                    name='close'
                    className='profile__information--resume-file-delete'
                    onClick={(e: { stopPropagation: () => void }) => {
                      e.stopPropagation();
                      setResume(null);
                    }}
                  />
                </div>
              ) : information.chosenResume ? (
                <div className='profile__information--resume-file'>
                  <span
                    className='profile__information--resume-name'
                    onClick={() =>
                      dispatch(getResume(information.chosenResume as string))
                    }
                  >
                    {information.chosenResume}
                  </span>
                  <Icon
                    name='close'
                    className='profile__information--resume-file-delete'
                    onClick={(e: { stopPropagation: () => void }) => {
                      e.stopPropagation();
                      dispatch(
                        setChosenValue({ key: 'chosenResume', value: null })
                      );
                    }}
                  />
                </div>
              ) : null}
              <button className='profile__information--resume-file-btn'>
                <input
                  type='file'
                  onChange={event => fileUploader(event, 'resume')}
                  accept='.pdf, .doc, .docx'
                />
                Upload resume
              </button>
            </div>
          </div>
        </InfoGridCard>
      )}

      <InfoGridCard heading={'Details'} logo={'details'} role={'candidate'}>
        <div className='profile__details--wrapper'>
          {information.citizenship_choices !== undefined &&
            information.chosenCitizenship !== undefined && (
              <ProfileFormGroup
                className='profile__details--citizenship'
                heading='Citizenship'
                required={requiredFields?.includes('chosenCitizenship')}
                error={
                  requiredFields?.includes('chosenCitizenship') &&
                  !information.chosenCitizenship?.length
                }
              >
                {information.citizenship_choices.map(citizenship => (
                  <li
                    className='form__fields--item'
                    key={citizenship.replace(/\s+/g, '')}
                  >
                    <UIProfileCheckbox
                      title={citizenship}
                      checked={citizenship === information.chosenCitizenship}
                      id={citizenship.replace(/\s+/g, '')}
                      name='chosenCitizenship'
                      onChange={event =>
                        dispatch(
                          setChosenValue({
                            key: 'chosenCitizenship',
                            value: event.target.value,
                          })
                        )
                      }
                      type={'radio'}
                    />
                  </li>
                ))}
              </ProfileFormGroup>
            )}
          {information.birthDate !== undefined && (
            <ProfileFormGroup
              className='profile__details--birthday'
              heading={'Birthday'}
              required={requiredFields?.includes('birthDate')}
              error={
                requiredFields?.includes('birthDate') &&
                !information.birthDate?.length
              }
            >
              <UIDatePicker
                name='birthday'
                selected={
                  information.birthDate?.length
                    ? getDate(information.birthDate)
                    : null
                }
                onChange={date =>
                  dispatch(
                    setChosenValue({
                      key: 'birthDate',
                      value: getStringDate(date!),
                    })
                  )
                }
              />
            </ProfileFormGroup>
          )}
          {information.races !== undefined && (
            <ProfileFormGroup
              className='profile__details--race'
              heading={'Race'}
              required={requiredFields?.includes('chosenRace')}
              error={
                requiredFields?.includes('chosenRace') &&
                !information.chosenRace?.length
              }
            >
              {information.races.map(race => (
                <li className='form__fields--item' key={race}>
                  <UIProfileCheckbox
                    name='chosenRace'
                    checked={race === information.chosenRace}
                    onChange={event =>
                      dispatch(
                        setChosenValue({
                          key: 'chosenRace',
                          value: event.target.value,
                        })
                      )
                    }
                    title={race}
                    id={race}
                    type={'radio'}
                  />
                </li>
              ))}
            </ProfileFormGroup>
          )}
          {information.gender_choices !== undefined && (
            <ProfileFormGroup
              className='profile__details--gender'
              heading={'Gender'}
              required={requiredFields?.includes('chosenGender')}
              error={
                requiredFields?.includes('chosenGender') &&
                !information.chosenGender?.length
              }
            >
              {information.gender_choices.map(gender => (
                <li className='form__fields--item' key={gender}>
                  <UIProfileCheckbox
                    name='chosenGender'
                    checked={gender === information.chosenGender}
                    onChange={event =>
                      dispatch(
                        setChosenValue({
                          key: 'chosenGender',
                          value: event.target.value,
                        })
                      )
                    }
                    title={gender}
                    id={gender}
                    type={'radio'}
                  />
                </li>
              ))}
            </ProfileFormGroup>
          )}
          {information.group_choices !== undefined && (
            <ProfileFormGroup
              heading={'Group'}
              className='profile__details--group'
              required={requiredFields?.includes('chosenGroup')}
              error={
                requiredFields?.includes('chosenGroup') &&
                !information.chosenGroup?.length
              }
            >
              {information.group_choices.map(group => (
                <li className='form__fields--item' key={group}>
                  <UIProfileCheckbox
                    name='chosenGroup'
                    checked={group === information.chosenGroup}
                    onChange={event =>
                      dispatch(
                        setChosenValue({
                          key: 'chosenGroup',
                          value: event.target.value,
                        })
                      )
                    }
                    title={group}
                    id={group}
                  />
                </li>
              ))}
            </ProfileFormGroup>
          )}
        </div>
      </InfoGridCard>
      <InfoGridCard
        heading={webContent.preferred_job_categories_section_title_web}
        logo={'categories'}
        role='candidate'
      >
        {information.jobCategories !== undefined &&
          information.chosenJobCategories !== undefined && (
            <ProfileFormGroup
              className='profile__single--form'
              heading={webContent.preferred_job_categories_section_header_web}
              required={requiredFields?.includes('chosenJobCategories')}
              error={
                requiredFields?.includes('chosenJobCategories') &&
                !information.chosenJobCategories?.length
              }
            >
              {information.jobCategories.map(group => (
                <li className='form_fields--item' key={group}>
                  <UIProfileCheckbox
                    name='chosenJobCategories'
                    checked={information.chosenJobCategories?.includes(group)}
                    onChange={event =>
                      dispatch(
                        setChosenJobCategory({
                          value: group,
                          add: event.target.checked,
                        })
                      )
                    }
                    title={group}
                    id={group}
                  />
                </li>
              ))}
            </ProfileFormGroup>
          )}
      </InfoGridCard>
      <InfoGridCard heading={'Location'} logo={'location'} role={'candidate'}>
        {information.locations !== undefined &&
          information.chosenLocations !== undefined && (
            <ProfileFormGroup
              className='profile__single--form'
              heading='Please select your preferred location. We will send notification based on your preference (Can select multiple options).'
              required={requiredFields?.includes('chosenLocations')}
              error={
                requiredFields?.includes('chosenLocations') &&
                !information.chosenLocations?.length
              }
            >
              {information.locations.map(group => (
                <li className='form_fields--item' key={group}>
                  <UIProfileCheckbox
                    name='chosenLocations'
                    checked={information.chosenLocations?.includes(group)}
                    onChange={event =>
                      dispatch(
                        switchChosenArrayItem({
                          array: 'chosenLocations',
                          value: group,
                          add: event.target.checked,
                        })
                      )
                    }
                    title={group}
                    id={group}
                  />
                </li>
              ))}
            </ProfileFormGroup>
          )}
      </InfoGridCard>
      <InfoGridCard
        heading='Notifications'
        className={'profile__notifications'}
        logo='notification'
        role='candidate'
        position='bottom'
      >
        {information.notifications !== undefined &&
          information.chosenNotifications !== undefined && (
            <ProfileFormGroup
              heading='How would you like to receive job notification?'
              className='profile__notifications--form'
              required={requiredFields?.includes('chosenNotifications')}
              error={
                requiredFields?.includes('chosenNotifications') &&
                !information.chosenNotifications?.length
              }
            >
              {information.notifications.map(notify => (
                <li className='form_fields--item' key={notify}>
                  <UIRadioButton
                    checked={information.chosenNotifications?.includes(notify)}
                    onChange={event =>
                      dispatch(
                        switchChosenArrayItem({
                          array: 'chosenNotifications',
                          value: notify,
                          add: event.target.value === '1',
                        })
                      )
                    }
                    name={notify}
                    label={notify}
                    id={notify}
                  />
                </li>
              ))}
            </ProfileFormGroup>
          )}
        {information.preferredJobTypeChoices !== undefined &&
          information.chosenPreferredJobTypes !== undefined && (
            <ProfileFormGroup
              className='profile__notifications-type--form'
              heading='Please select the type of jobs that you would like to receive notification (Can select multiple options).'
              required={requiredFields?.includes('chosenPreferredJobTypes')}
              error={
                requiredFields?.includes('chosenPreferredJobTypes') &&
                !information.chosenPreferredJobTypes?.length
              }
            >
              {information.preferredJobTypeChoices.map(type => (
                <li className='form_fields--item' key={type}>
                  <UIProfileCheckbox
                    name='chosenPreferredJobTypes'
                    checked={information.chosenPreferredJobTypes?.includes(
                      type
                    )}
                    onChange={event =>
                      dispatch(
                        switchChosenArrayItem({
                          array: 'chosenPreferredJobTypes',
                          value: type,
                          add: event.target.checked,
                        })
                      )
                    }
                    title={type}
                    id={type}
                  />
                </li>
              ))}
            </ProfileFormGroup>
          )}
      </InfoGridCard>
      <div className='profile__buttons'>
        {!registration && (
          <UIButton
            title='Delete profile'
            classModificator='profile__buttons--item profile__buttons--delete'
            callback={() => dispatch(openModal('deleteUser'))}
          />
        )}
        <UIButton
          isLoading={isLoadingUpdateProfile}
          title='Save'
          type='submit'
          classModificator='profile__buttons--item profile__buttons--save'
        />
      </div>
    </form>
  );
};

export default ProfileInformation;
