import React, { FC } from 'react';
import './UIProfileCheckbox.scss';
import cn from 'classnames';

interface UIProfileCheckboxProps {
  title: string;
  id: string;
  checked?: boolean;
  defaultChecked?: boolean;
  type?: 'checkbox' | 'radio';
  name?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UIProfileCheckbox: FC<UIProfileCheckboxProps> = ({
  title,
  id,
  type = 'checkbox',
  name,
  className,
  ...rest
}) => {
  const inputId = name + id;

  return (
    <>
      <input
        type={type}
        className='ui-checkbox__input'
        key={title}
        id={inputId}
        value={title}
        name={name}
        {...rest}
      />
      <label
        className={`${cn({
          'ui-checkbox__label': true,
          'ui-checkbox__label--active': true,
        })} ${className ? className : ''}`}
        htmlFor={inputId}
      >
        {title}
      </label>
    </>
  );
};

export default UIProfileCheckbox;
