import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState, useEffect, FC } from 'react';
import CheckoutForm from './CheckoutForm';
import styles from './_styles.module.scss';
import { setCreatePaymentIntent } from '../../../../core/services/axios';
import { useAppDispatch } from '../../../../core/hooks/reduxHooks';
import { setLoading } from '../../../../redux/reducers/ui/HandlerSlice';
import { IPaymentIntent } from '../../../../core/models/models';

const stripeTestPromise = loadStripe(
  process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY as string
);

const StripeContainer: FC<{ paymentIntentForm: IPaymentIntent }> = ({
  paymentIntentForm,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clientSecret, setClientSecret] = useState<string>('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    dispatch(setLoading(true));
    try {
      setCreatePaymentIntent(paymentIntentForm).then(data => {
        // console.log(data);
        if (data) {
          setClientSecret(data);
          dispatch(setLoading(false));
        }
      });
    } catch (e) {
      // console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  }, []);

  const options = {
    clientSecret,
    theme: 'stripe',
  };

  return (
    <div className={styles.stripe}>
      {clientSecret && stripeTestPromise && (
        <Elements options={options} stripe={stripeTestPromise}>
          <CheckoutForm clientSecretKey={clientSecret} />
        </Elements>
      )}
    </div>
  );
};

export default StripeContainer;
