export const downloadPDFBlob = (file: Blob, fileName?: string) => {
  const blob = new Blob([file], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;

  link.setAttribute('download', `${fileName || 'resume'}`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
