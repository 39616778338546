import React, { FC, useEffect, useMemo } from 'react';
import styles from './_styles.module.scss';
import UIButtonNavLink from '../UI/UINavLink/UIButtonNavLink';
import UIReviewCandidateQuality from '../UI/UIReviewCandidateQuality/UIReviewCandidateQuality';
import UIButton from '../UI/UIButton/UIButton';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import { openModal } from '../../redux/reducers/ui/ModalDialogSlice';
import { CircularProgress } from '@mui/material';
import { getAssignmentJobReviewCandidateInfo } from '../../redux/thunk/employerAssignments';
import { getProposalResume } from '../../redux/thunk/adminAssignments';
import { log } from 'util';
import { setSelectedAssignmentId } from '../../redux/reducers/ui/SubscriptionPackagesSlice';
import useWebContent from "../../core/hooks/useWebContent";

interface IContactInfoProps {
  assignmentId: string;
  shortlistValue: number;
  proposal_id: string;
  role: 'employer' | 'admin';
}

const ContactInfo: FC<IContactInfoProps> = ({
  assignmentId,
  shortlistValue,
  proposal_id,
  role,
}) => {
  const { jobReviewCandidates, jobDetails } = useAppSelector(
    state => state.UserManageJobsReducer
  );

  const { jobReviewCandidatesAdmin } = useAppSelector(
    state => state.adminAssignments
  );

  const dispatch = useAppDispatch();
  const [webContent] = useWebContent();

  const shortlistedData = useMemo(() => {
    if (role === 'employer') {
      const shortlist = jobReviewCandidates.results.find(
        proposal => proposal.id === proposal_id
      );

      return shortlist;
    } else if (role === 'admin') {
      return jobReviewCandidatesAdmin.results.find(
        proposal => proposal.id === proposal_id
      );
    }
  }, [
    jobReviewCandidates.results,
    jobReviewCandidatesAdmin.results,
    proposal_id,
    role,
    jobDetails?.shortlists_left,
  ]);

  const handleShortlist = (id: string) => {
    if (shortlistValue > 0) {
      dispatch(getAssignmentJobReviewCandidateInfo(id));
    } else {
      dispatch(setSelectedAssignmentId(assignmentId));
      dispatch(openModal('purchase_shortlist'));
    }
  };

  useEffect(() => {}, [shortlistedData, shortlistValue]);

  if (shortlistedData && !shortlistedData.shortlisted && role === 'employer') {
    return (
      <div className={styles.contacts}>
        <p className={styles.contacts_title}>
          Press shortlist to get the candidate contact information
        </p>
        <UIButton
          callback={() => handleShortlist(proposal_id)}
          title={'Shortlist'}
          classModificator={styles.btn}
        />
        <p className={styles.shortlists_left}>
          <b>{shortlistValue}</b> shortlists left
        </p>
      </div>
    );
  } else if (
    (shortlistedData &&
      shortlistedData.shortlisted &&
      shortlistedData.shortlisted_data) ||
    (role === 'admin' && shortlistedData && shortlistedData.shortlisted_data)
  ) {
    const { name, email, whatsapp, phone, resume_filename } =
      shortlistedData.shortlisted_data;

    return (
      <div className={styles.contacts}>
        <div className={styles.info}>
          <UIReviewCandidateQuality title={webContent.name_web} quality={name} />
          <UIReviewCandidateQuality title={webContent.email_field_web} quality={email} />
          <UIReviewCandidateQuality
            title={webContent.whatsapp_web}
            quality={phone}
            whatsapp={whatsapp}
          />
          <UIReviewCandidateQuality title={webContent.phone_pref_web} quality={phone} />
          {resume_filename && (
            <div
              onClick={() => {
                dispatch(getProposalResume(proposal_id, resume_filename));
              }}
              className={styles.resume}
            >
              {webContent.resume_web}:{' '}
              <span className={styles.resume_btn}>{resume_filename || ''}</span>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.contacts}>
        <CircularProgress className={styles.circular} />
      </div>
    );
  }
};

export default ContactInfo;
