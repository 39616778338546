import {
  AssignmentFilters,
  IApplyJobInfo,
  IApplyJobPost,
  IAvailableWorkDetails,
  JobCategoriesTypes,
  LocationTypes,
  PreferredJobTypes,
} from '../../../core/models/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CandidateAssignmentsState {
  activeFilters: {
    job_types: PreferredJobTypes[];
    regions: LocationTypes[];
    categories: JobCategoriesTypes[];
    key_words: string;
    page_size: number;
  };
  isJobsLoading: boolean;
  assignments: Array<IAvailableWorkDetails>;
  totalPages: number;
  selectedPage: number;
  possibleFilters: AssignmentFilters;
  selectedJob: IAvailableWorkDetails | null;
  applyJobPoster: IApplyJobInfo | null;
  applyJob: IApplyJobPost;
  tab: 'all' | 'saved' | 'applied';
}

export type ChosenFilters = 'job_types' | 'regions' | 'categories';

type ChosenKeys =
  | 'day_and_time'
  | 'rate'
  | 'group'
  | 'highest_qualification'
  | 'details_of_experience'
  | 'resume';

const initialState: CandidateAssignmentsState = {
  activeFilters: {
    job_types: [],
    categories: [],
    regions: [],
    key_words: '',
    page_size: 6,
  },
  possibleFilters: {
    regions: [],
    allRegions: false,
    categories: [],
    allCategories: false,
    job_types: [],
    allJobTypes: false,
    show_categories_icons_on_website: false,
  },
  assignments: [],
  totalPages: 0,
  selectedPage: 1,
  selectedJob: null,
  applyJobPoster: null,
  applyJob: {
    assignment_id: '',
    day_and_time: '',
    rate: '',
    group: '',
    highest_qualification: '',
    details_of_experience: '',
    resume: null,
  },
  tab: 'all',
  isJobsLoading: false,
};

export const CandidateAssignmentsSlice = createSlice({
  name: 'candidate/assignments',
  initialState,
  reducers: {
    setJobsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isJobsLoading = payload;
    },
    setPossibleFilters(state, action: PayloadAction<AssignmentFilters>) {
      state.possibleFilters = {
        ...state.possibleFilters,
        ...action.payload,
      };
    },
    setSelectedJob(state, action: PayloadAction<IAvailableWorkDetails | null>) {
      state.selectedJob = action.payload;
    },
    setChosenApplyValue(
      state,
      {
        payload: { key, value },
      }: PayloadAction<{ key: ChosenKeys; value: string }>
    ) {
      if (state.applyJob) {
        state.applyJob[key] = value as never;
      }
    },
    setKeyword(state, action: PayloadAction<string>) {
      state.activeFilters.key_words = action.payload;
    },
    setFullTimeFilter(state) {
      state.activeFilters.job_types = ['Full-time'];
    },
    setPartTimeFilter(state) {
      state.activeFilters.job_types = ['Part-time'];
    },
    setSearchParamsJobTypesFilter(
      state,
      action: PayloadAction<PreferredJobTypes[]>
    ) {
      state.activeFilters.job_types = action.payload;
    },
    switchActiveFilterLikeSearchParams(
      state,
      action: PayloadAction<PreferredJobTypes[]>
    ) {
      state.activeFilters.job_types = action.payload;
    },
    switchActiveFiltersItem(
      state,
      {
        payload: { array, value, add },
      }: PayloadAction<{
        array: ChosenFilters;
        value: PreferredJobTypes | LocationTypes | JobCategoriesTypes;
        add: boolean;
      }>
    ) {
      if (add) {
        switch (array) {
          case 'categories':
            state.activeFilters[array] = [
              ...state.activeFilters[array],
              value,
            ] as JobCategoriesTypes[];
            break;
          case 'regions':
            state.activeFilters[array] = [
              ...state.activeFilters[array],
              value,
            ] as LocationTypes[];
            break;
          case 'job_types':
            state.activeFilters[array] = [
              ...state.activeFilters[array],
              value,
            ] as PreferredJobTypes[];
            break;
        }
      } else {
        switch (array) {
          case 'categories':
            state.activeFilters[array] = state.activeFilters[array].filter(
              filter => filter !== value
            ) as JobCategoriesTypes[];
            break;
          case 'regions':
            state.activeFilters[array] = state.activeFilters[array].filter(
              filter => filter !== value
            ) as LocationTypes[];
            break;
          case 'job_types':
            state.activeFilters[array] = state.activeFilters[array].filter(
              filter => filter !== value
            ) as PreferredJobTypes[];
            break;
        }
      }
    },
    setTab(state, action: PayloadAction<'all' | 'saved' | 'applied'>) {
      state.tab = action.payload;
    },
    setAssignments(state, action: PayloadAction<Array<IAvailableWorkDetails>>) {
      state.assignments = action.payload;
    },
    setTotalPages(state, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.selectedPage = action.payload;
    },
    clearActiveFilters(state) {
      state.activeFilters = initialState.activeFilters;
    },
    setApplyPosterInfo(state, action: PayloadAction<IApplyJobInfo>) {
      state.applyJobPoster = action.payload;
    },
    clearApplyPosterInfo(state) {
      state.applyJobPoster = initialState.applyJobPoster;
      state.applyJob = initialState.applyJob;
    },
    setSaveAssignment(
      state,
      { payload: { id, save } }: PayloadAction<{ id: string; save: boolean }>
    ) {
      state.assignments = [...state.assignments].map(job => {
        if (job.assignment_id === id) {
          return {
            ...job,
            saved: save,
          };
        }

        return job;
      });

      if (state.selectedJob) {
        state.selectedJob = {
          ...state.selectedJob,
          saved: save,
        };
      }
    },
    setAllFilters(
      state,
      { payload: { id, value } }: PayloadAction<{ id: string; value: boolean }>
    ) {
      state.possibleFilters[
        id as keyof typeof state.possibleFilters as
          | 'allRegions'
          | 'allJobTypes'
          | 'allCategories'
      ] = value;
    },
    setActiveFilters(
      state,
      {
        payload: { id, value },
      }: PayloadAction<{
        id: ChosenFilters;
        value: PreferredJobTypes[] | LocationTypes[] | JobCategoriesTypes[];
      }>
    ) {
      switch (id) {
        case 'categories':
          state.activeFilters[id] = value as JobCategoriesTypes[];
          break;
        case 'regions':
          state.activeFilters[id] = value as LocationTypes[];
          break;
        case 'job_types':
          state.activeFilters[id] = value as PreferredJobTypes[];
          break;
      }
    },
    setOpenAssignmentsData(
      state,
      {
        payload,
      }: PayloadAction<{
        assignments: IAvailableWorkDetails[];
        totalPages: number;
        // selectedJob: IAvailableWorkDetails;
      }>
    ) {
      state.assignments = payload.assignments;
      state.totalPages = payload.totalPages;
      // state.selectedJob = payload.selectedJob;
    },
  },
});

export const {
  setJobsLoading,
  setPossibleFilters,
  setSelectedJob,
  setKeyword,
  setFullTimeFilter,
  setPartTimeFilter,
  switchActiveFilterLikeSearchParams,
  switchActiveFiltersItem,
  setTab,
  setAssignments,
  setTotalPages,
  setCurrentPage,
  clearActiveFilters,
  setSaveAssignment,
  setApplyPosterInfo,
  clearApplyPosterInfo,
  setChosenApplyValue,
  setAllFilters,
  setActiveFilters,
  setOpenAssignmentsData,
  setSearchParamsJobTypesFilter,
} = CandidateAssignmentsSlice.actions;

export default CandidateAssignmentsSlice.reducer;
