import {
  AdminAssignmentProposalsParams,
  AdminAssignmentsParams,
  AdminAssignmentsType,
} from '../../core/models/models';
import { adminAPI } from '../../core/api/api';
import {
  setAssignments,
  setCurrentAssignment,
  setEmptyAdminJobReviewCandidates,
  setJobReviewCandidates,
  setJobReviewCandidatesAdminLoading,
} from '../reducers/api/AdminAssignmentSlice';
import {
  repostAssignment,
} from '../reducers/api/UserManageJobsSlice';
import { setLoading } from '../reducers/ui/HandlerSlice';
import { AppDispatch, RootState } from '../store';
import {
  openBannedContentModal,
  openErrorModal,
  openInfoModal,
} from '../reducers/ui/ModalDialogSlice';
import { AxiosError } from 'axios';
import { modalContentTexts } from '../../core/constants/modalsContentText';
import { downloadPDFBlob } from '../../core/utils/downloadBlob';
import { setJobReviewCandidatesLoading } from '../reducers/api/UserManageJobsSlice';

// export const getAdminAssignmentProposals =
//   (
//     assignmentId: string,
//     assignmentsType: AdminAssignmentsType,
//     params?: AdminAssignmentProposalsParams
//   ) =>
//   async (dispatch: AppDispatch) => {
//     return adminAPI
//       .getAdminAssignmentProposals(assignmentId, params)
//       .then(({ results }) => {
//         dispatch(setCurrentAssignmentProposals([assignmentsType, results]));
//       })
//       .finally(() => {
//         // dispatch(setLoading(false));
//       });
//   };

export const getAdminAssignmentById =
  (assignmentId: string, assignmentsType?: AdminAssignmentsType) =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return adminAPI
      .getAdminAssignmentById(assignmentId)
      .then(assignment => {
        if (assignmentsType) {
          dispatch(setCurrentAssignment([assignmentsType, assignment]));
        }

        return assignment;
      })
      .catch(err => {
        dispatch(openErrorModal('Job code is wrong. Please check.'));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getAdminAssignments =
  (assignmentsType: AdminAssignmentsType, params: AdminAssignmentsParams) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoading(true));

    return adminAPI
      .getAdminAssignments(assignmentsType, params)
      .then(({ results, ...pagination }) => {
        dispatch(
          setAssignments([
            assignmentsType,
            {
              assignments: results,
              pagination,
            },
          ])
        );

        if (
          !getState().adminAssignments.pending.current &&
          results.length > 0
        ) {
          dispatch(
            getAdminAssignmentById(results[0].assignment_id, assignmentsType)
          );
        }
      })
      .catch(err => {
        if (err.response) {
          if (typeof err.response.data.message === 'string') {
            dispatch(openErrorModal(err.response.data.message));
          } else {
            dispatch(openErrorModal(err.response.status));
          }
        } else if (err.request) {
          dispatch(openErrorModal(err.request.data || err.message));
        } else {
          dispatch(openErrorModal(err.message));
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getAdminAssignmentConfig = () => async (dispatch: AppDispatch) => {
  return adminAPI.getAdminAssignmentConfig();
};

export const addAdminAssignment =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const form = getState().adminAssignments.form;

    dispatch(setLoading(true));

    // form.expiration_date = '';

    return adminAPI
      .addAdminAssignment(form)
      .then(data => {
        const { message, banned, assignment_id } = data;

        if (banned) {
          dispatch(openBannedContentModal(assignment_id));
        }

        if (message && !banned) {
          dispatch(openInfoModal({ title: 'Success', message }));
        }

        return data;
      })
      .catch(error => {
        throw error;
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const approveAdminAssignment =
  (assignmentId: string, banned?: boolean, pending?: boolean) =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return adminAPI
      .approveAdminAssignment(assignmentId, banned, pending)
      .then(data => {
        if (data.banned) {
          dispatch(openBannedContentModal(assignmentId));
        }

        if (data.status_code === 200 && !data.banned) {
          dispatch(
            openInfoModal({ title: 'Job approval', message: data.message })
          );
        }

        return data;
      })
      .catch((error: AxiosError<{ message?: string; status?: string }>) => {
        const data = error?.response?.data;

        if (data) {
          const { message } = data;

          if (message) {
            if (message === 'Assignment contains banned content.') {
              dispatch(openBannedContentModal(assignmentId));
            } else {
              dispatch(openErrorModal(message));
            }
          }
        }

        throw error;
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const rejectAdminAssignment =
  (assignmentId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return adminAPI
      .rejectAdminAssignment(assignmentId)
      .then(data => {
        dispatch(
          openInfoModal({ title: 'Job rejection', message: data.message })
        );

        return data;
      })
      .catch((error: AxiosError<{ message?: string; status?: string }>) => {
        dispatch(
          openErrorModal(
            error?.response?.data?.message || 'Something went wrong'
          )
        );

        throw error;
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const updateAdminAssignment =
  (bannedContent?: boolean) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const form = getState().adminAssignments.form;

    dispatch(setLoading(true));

    // form.expiration_date = '';

    return adminAPI
      .updateAdminAssignment(form, bannedContent || false)
      .then(data => {
        // console.log('data', data);
        const { message, banned, assignment_id } = data;

        if (banned) {
          dispatch(openBannedContentModal(assignment_id));
        }

        if (message && !banned) {
          dispatch(openInfoModal({ title: 'Success', message }));
        }

        return data;
      })
      .catch(error => {
        // console.log(error);
        throw error;
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const deleteAdminAssignment =
  (assignmentId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return adminAPI
      .deleteAdminAssignment(assignmentId)
      .catch(error => {
        // console.log(JSON.stringify(error, null, 2));
        throw error;
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getAdminAssignmentJobReviewCandidateInfo =
  (id: string, orderBy: string, page: number) =>
  async (dispatch: AppDispatch) => {
    dispatch(setJobReviewCandidatesAdminLoading(true));

    return adminAPI
      .getAdminAssignmentJobReviewCandidateInfo(id, orderBy, page)
      .then(data => {
        dispatch(setJobReviewCandidates(data));
      })
      .catch(error => {
        dispatch(setEmptyAdminJobReviewCandidates());
      })
      .finally(() => {
        dispatch(setJobReviewCandidatesAdminLoading(false));
      });
  };

export const repostAdminAssignmentJob =
  (id: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    return adminAPI
      .repostAdminAssignmentJob(id)
      .then(response => {
        if (response.status_code === 200) {
          dispatch(repostAssignment());
          if (response.banned) {
            dispatch(
              openInfoModal({
              message: response.message,
              title: 'Info message',
              })
            );
          } else {
            dispatch(
              openInfoModal({
                message: `The job ${id} has been bumped`,
                title: 'Info message',
              })
            );
          }
        }
      })
      .catch(error => {
        dispatch(openErrorModal(error.response.data.message));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getProposalResume =
  (id: string, name: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return adminAPI
      .getProposalResume(id)
      .then(response => {
        downloadPDFBlob(response.data, name);
      })
      .catch(error => {
        dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
