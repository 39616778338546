import React, { FC } from 'react';
import styles from './_styles.module.scss';

const UITextarea: FC<{
  id: string;
  title: string;
  rows: number;
  value: string;
  fontSizeInput?: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder: string;
  maxLength: number;
  error?: boolean;
  color?: 'candidate' | 'employer' | 'admin';
}> = ({ title, error, id, color, fontSizeInput, ...rest }) => {
  return (
    <>
      <span className={`${styles.title} ${error && styles.title_error}`}>
        {title}
      </span>
      <textarea
        id={id}
        aria-describedby={id}
        style={{ fontSize: `${fontSizeInput}px` }}
        className={`${styles.textarea} ${error && styles.textarea_error} ${
          color === 'candidate'
            ? styles.textarea_candidate
            : color === 'admin'
            ? styles.textarea_admin
            : ''
        }`}
        {...rest}
      />
    </>
  );
};

export default UITextarea;
