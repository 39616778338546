export const modalContentTexts = {
  error: {
    title: 'Error message',
    text: 'Oops, something went wrong',
  },
  saveDraftSuccess: {
    title: 'Save draft',
    text: 'Draft saved successfully',
  },
  saveNewAssignmentSuccess: {
    title: 'Success',
    text: 'Thank you for post the job. The job will be posting after the admin approves it',
  },
  saveEditedAssignmentSuccess: {
    title: 'Success',
    text: 'Thank you for editing the job. The changes will be shown after the admin approves it',
  }
};
