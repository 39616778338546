import React, { FC, useEffect, useRef } from 'react';
import styles from './_styles.module.scss';
import SearchWorkCard from './SearchWorkCard';
import { IAvailableWorkDetails } from '../../../core/models/models';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { setSelectedJob } from '../../../redux/reducers/api/CandidateAssignmentsSlice';

const SearchWorkCardList: FC<{
  works: IAvailableWorkDetails[] | undefined;
}> = ({ works }) => {
  const dispatch = useAppDispatch();
  const selectedJob = useAppSelector(
    state => state.candidateAssignments.selectedJob
  );

  const jobsListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = jobsListRef.current;

    node?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [works]);

  return (
    <div className={styles.work_list} ref={jobsListRef}>
      {works?.map(work => (
        <SearchWorkCard
          key={work.assignment_id}
          selectedJobId={selectedJob?.assignment_id || null}
          work={work}
          onClick={() => dispatch(setSelectedJob(work))}
        />
      ))}
    </div>
  );
};

export default SearchWorkCardList;
