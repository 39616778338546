import {
  AdminAssignmentsOrder,
  AdminAssignmentsType,
  IPreferredQualifications,
  JobCategoriesTypes,
  PreferredJobTypes,
} from '../models/models';

export const adminAssignmentsTypes: AdminAssignmentsType[] = [
  'all',
  'posted_by_staff',
  'on_review',
  'pending',
  'content_review',
];

export const adminAssignmentsPages: {
  [K in AdminAssignmentsType]: string;
} = {
  all: '/',
  posted_by_staff: '/staff',
  on_review: '/approve',
  pending: '/pending',
  content_review: '/banned',
};

export const adminAssignmentsTitles: {
  [K in AdminAssignmentsType]: string;
} = {
  all: 'All jobs',
  posted_by_staff: 'Jobs posted by staff',
  on_review: 'Approve jobs',
  pending: 'Pending jobs',
  content_review: 'Banned content jobs',
};

const date = new Date();

date.setMonth(date.getMonth() + 1);

export const assignmentInitialExpirationDate = date;

export const jobCategories: JobCategoriesTypes[] = [
  'F&B',
  'Sales / Retail',
  'Promoter',
  'Packer / Logistic',
  'Customer Service',
  'Cleaning',
  'Driver / Delivery',
  'Education / Training',
  'Admin / Secretary',
  'Telemarketing',
  'Healthcare',
  'Tuition',
  'Other',
];

export const preferredQualifications: IPreferredQualifications[] = [
  'No preference',
  'Diploma Holder',
  'A-level Holder',
  'Degree Holder',
];

export const jobTypes: PreferredJobTypes[] = [
  'Full-time',
  'Part-time',
  'Internship',
  'Contract',
];

export const adminAssignmentsOrders: AdminAssignmentsOrder[] = [
  'Latest',
  'Expired',
  'Full-time',
  'Part-time',
  'Internship',
  'Contract',
];
