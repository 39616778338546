import React, { useCallback, useEffect } from 'react';
import { useAppSelector } from '../../core/hooks/reduxHooks';
import CandidateDrawer from './CandidateDrawer/CandidateDrawer';
import EmployerDrawer from './EmployerDrawer/EmployerDrawer';
import { useLocation } from 'react-router-dom';

const DrawerContent: React.FC = () => {
  const location = useLocation();
  // const dispatch = useAppDispatch();
  //
  // const { openLoginModalDialog } = modalDialogSlice.actions;
  // const { closeDrawer } = drawerSlice.actions;
  // const { isOpened, type } = useAppSelector(state => state.modalDialogReducer);
  const token = useAppSelector(state => state.userTokenReducer.token);

  const currentRoutsContent = useCallback(() => {
    const currentPathCandidate = location.pathname.includes('/candidate');
    const currentPathEmployer = location.pathname.includes('/employer');

    return currentPathCandidate ? (
      <CandidateDrawer />
    ) : currentPathEmployer ? (
      <EmployerDrawer />
    ) : (
      <CandidateDrawer />
    );
  }, [location.pathname]);

  useEffect(() => {}, [token, location]);

  return <>{currentRoutsContent()}</>;
};

export default DrawerContent;
