import React, { FC } from 'react';
import { Container } from '@mui/material';
import './Visitor.scss';
import Search from '../Search/Search';

const Visitor: FC = () => {
  return (
    <div className='visitor'>
      {/*<Container maxWidth='lg'>*/}
      {/*  <div className='visitor__header'>*/}
      {/*    <div className='visitor__header-heading'>*/}
      {/*      <h2 className='heading'>*/}
      {/*        Apply for jobs <br /> using{' '}*/}
      {/*        <span className='heading-white'>any apps</span>*/}
      {/*      </h2>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Container>*/}

      {/*<div className='visitor__header-map'>*/}
      {/*<img src={googleMap} alt='map' height={503} />*/}
      {/*</div>*/}

      <Container maxWidth='lg'>
        <Search />
      </Container>
    </div>
  );
};

export default Visitor;
