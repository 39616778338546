import React, { useEffect, useLayoutEffect } from 'react';
import './App.scss';
import Navigation from './routes/Navigation';
import ModalDialog from './containers/ModalDialog/ModalDialog';
import { AuthTokenApi } from './core/utils/autrhTokenApi';
import DrawerMenu from './containers/DrawerMenu/DrawerMenu';
import DrawerContent from './components/DrawerContent/DrawerContent';
import { Cookies } from 'react-cookie';
import {
  setAdminRole,
  setError,
  setToken,
} from './redux/reducers/api/UserTokenSlice';
import jwtDecode from 'jwt-decode';
import { store } from './redux/store';
import { useAppDispatch, useAppSelector } from './core/hooks/reduxHooks';
import Loader from './components/Loader/Loader';
import { IDecodedToken } from './core/models/models';
import { apiClient } from './core/services/axios';
import { AxiosError } from 'axios';
import { API } from './core/api/api';
import { openInfoModal } from './redux/reducers/ui/ModalDialogSlice';
import {
  getCandidateJobsChannelsContent,
  getEmployerCandidateCardContent,
  getWebContent
} from './redux/thunk/webContent';

const cookies = new Cookies();

const token = cookies.get('token');
const refresh_token = cookies.get('refresh_token');

if (token && refresh_token) {
  const decodedToken = jwtDecode<IDecodedToken>(token);

  if (decodedToken.exp * 1000 < Date.now()) {
    const decodedRefreshToken = jwtDecode<IDecodedToken>(refresh_token);

    if (decodedRefreshToken.exp * 1000 < Date.now()) {
      cookies.remove('token');
      cookies.remove('refresh_token');
      store.dispatch(setToken(null));
    } else {
      AuthTokenApi(refresh_token);

      if (decodedToken.admin) {
        store.dispatch(setAdminRole(true));
      }

      API.refreshToken().then(() => {
        store.dispatch(setToken(refresh_token));
      });
    }
  } else {
    if (decodedToken.admin) {
      store.dispatch(setAdminRole(true));
    }

    AuthTokenApi(token);

    store.dispatch(setToken(token));
  }
}

const App: React.FC = () => {
  const isLoading = useAppSelector(state => state.UIHandlerReducer.isLoading);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    apiClient.interceptors.response.use(
      config => {
        dispatch(setError(''));

        return config;
      },
      (error: AxiosError) => {
        if (!navigator.onLine) {
          window.location.reload();
        }

        // console.log(error);
        if (error.response?.status === 417) {
          // refresh token
          const decodedRefreshToken = jwtDecode<IDecodedToken>(refresh_token);

          if (decodedRefreshToken.exp * 1000 < Date.now()) {
            AuthTokenApi(null);
            cookies.remove('token');
            cookies.remove('refresh_token');
            dispatch(setToken(null));

            window.location.reload();

            dispatch(
              openInfoModal({
                title: 'Authorization',
                message: 'Your session has expired. Please login again',
              })
            );
          } else {
            AuthTokenApi(refresh_token);

            API.refreshToken().then(() => {
              dispatch(setToken(refresh_token));
            });
          }
        } else if (error.response?.status === 406) {
          // logout
          AuthTokenApi(null);
          cookies.remove('token');
          cookies.remove('refresh_token');
          dispatch(setToken(null));

          window.location.reload();

          dispatch(
            openInfoModal({
              title: 'Authorization',
              message: 'Your session has expired. Please login again',
            })
          );
        }

        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    dispatch(getWebContent());
  }, []);

  return (
    <div className='App'>
      {/*{isLoading && <Loader />}*/}
      <Navigation />
      <DrawerMenu>
        <DrawerContent />
      </DrawerMenu>
      <ModalDialog />
    </div>
  );
};

export default App;
