import React, { useCallback, useEffect, useState } from 'react';
import UiInput from '../../../components/UI/UIInput/UIInput';
import InfoGridCard from '../../../containers/InfoGridCard/InfoGridCard';
import style from './_index.module.scss';
import ProposedSalary from '../../../components/ProposedSalary/ProposedSalary';
import UiDatePicker from '../../../components/UI/UIDatePicker/UIDatePicker';
import UISelect from '../../../components/UI/UISelect/UISelect';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import {
  AdminAssignmentsForm,
  resetForm,
  setForm,
  setPreferredQualification,
} from '../../../redux/reducers/api/AdminAssignmentSlice';
import {
  adminAssignmentsPages,
  adminAssignmentsTypes,
  assignmentInitialExpirationDate,
  jobCategories,
  jobTypes,
  preferredQualifications,
} from '../../../core/constants/api';
import {
  addAdminAssignment,
  getAdminAssignmentById,
  getAdminAssignmentConfig,
  updateAdminAssignment,
} from '../../../redux/thunk/adminAssignments';
import { UIMultipleSelect } from '../../../components/UI/UIMultipleSelect/UIMultipleSelect';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatDate, getDateFromLocaleString } from '../../../core/functions';
import { validateCurrentInputValue } from '../../../core/utils/validateCurrentInputValue';
import UITextarea from '../../../components/UI/UITextarea/UITextarea';
import UIProfileCheckbox from '../../../components/UI/UIProfileCheckbox/UIProfileCheckbox';
import { setError, setLoading } from '../../../redux/reducers/ui/HandlerSlice';
import {
  AddAdminAssignmentError,
  AdminAssignmentsType,
} from '../../../core/models/models';
import UIButton from '../../../components/UI/UIButton/UIButton';
import { AxiosError } from 'axios';
import { openErrorModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import { proposedSalaryRegex } from '../../../core/constants/regex';
import { setJobRate } from '../../../redux/reducers/api/PostUserJobSlice';
import useWebContent from "../../../core/hooks/useWebContent";

const maxDate = new Date();

maxDate.setFullYear(maxDate.getFullYear() + 1);

const PostJob: React.FC = () => {
  const {
    assignmentId,
    chosen_category,
    preferred_qualifications,
    job_type,
    company_name,
    job_title,
    job_location,
    days_and_hours,
    lowest_rate,
    highest_rate,
    job_description,
    client_name,
    client_contact_number,
    client_full_address,
    client_other_info,
    expiration_date,
  } = useAppSelector(state => state.adminAssignments.form);
  const dispatch = useAppDispatch();
  const [webContent] = useWebContent();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState<{
    [Key in keyof AdminAssignmentsForm]?: boolean;
  }>({
    chosen_category: false,
    preferred_qualifications: false,
    job_type: false,
    company_name: false,
    job_title: false,
    job_location: false,
    days_and_hours: false,
    lowest_rate: false,
    highest_rate: false,
    job_description: false,
    client_name: false,
    client_contact_number: false,
    client_full_address: false,
    client_other_info: false,
    expiration_date: false,
  });

  const setFormValue = (
    key: keyof AdminAssignmentsForm,
    value: AdminAssignmentsForm[keyof AdminAssignmentsForm]
  ) =>
    dispatch(
      setForm({
        [key]: value,
      })
    );

  const onChangeLowestRate = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (value.match(proposedSalaryRegex) || value === '') {
        setFormValue('lowest_rate', value.trim());
      }
    },
    []
  );

  const onChangeHighestRate = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (value.match(proposedSalaryRegex) || value === '') {
        setFormValue('highest_rate', value.trim());
      }
    },
    []
  );

  const onSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    setFormErrors(prev =>
      Object.keys(prev).reduce(
        (acc, key) => ({
          ...acc,
          [key]: false,
        }),
        {}
      )
    );

    if (!assignmentId) {
      dispatch(addAdminAssignment())
        .then(data => {
          if (data.status !== 'fail') {
            dispatch(resetForm());
          }

          setFormErrors(prev =>
            Object.keys(prev).reduce(
              (acc, current) => ({ ...acc, [current]: false }),
              {}
            )
          );

          return data;
        })
        .catch((error: AddAdminAssignmentError) => {
          const data = error?.response?.data;

          if (data) {
            const { errors, message } = data;

            if (errors) {
              setFormErrors(prev =>
                Object.keys(errors).reduce(
                  (acc, current) => ({ ...acc, [current]: true }),
                  prev
                )
              );
            }

            if (message) {
              dispatch(openErrorModal(message));
            }
          }
        });
    } else {
      const assignmentTypeSearchParam = searchParams.get(
        'assignmentType'
      ) as AdminAssignmentsType;
      const isAssignmentTypeSearchParamValid = adminAssignmentsTypes.includes(
        assignmentTypeSearchParam
      );

      dispatch(updateAdminAssignment())
        .then(data => {
          if (data?.status === 'success') {
            dispatch(
              getAdminAssignmentById(
                assignmentId,
                isAssignmentTypeSearchParamValid
                  ? assignmentTypeSearchParam
                  : undefined
              )
            ).then(() => {
              if (isAssignmentTypeSearchParamValid) {
                navigate(
                  `/admin/jobs${adminAssignmentsPages[assignmentTypeSearchParam]}`
                );
              }
            });
          }
        })
        .catch(error => {
          const data = error?.response?.data;

          if (data) {
            const { errors, message } = data;

            if (errors) {
              setFormErrors(prev =>
                Object.keys(errors).reduce(
                  (acc, current) => ({ ...acc, [current]: true }),
                  prev
                )
              );
            }

            if (message) {
              dispatch(openErrorModal(message));
            }
          }

          throw error;
        });
    }
  };

  useEffect(() => {
    const assignmentIdSearchParam = searchParams.get('assignmentId');

    if (assignmentIdSearchParam) {
      dispatch(setLoading(true));
      dispatch(getAdminAssignmentById(assignmentIdSearchParam))
        .then(data => {
          if (data) {
            const editAssignmentForm = Object.entries(data).reduce(
              (acc, [key, value]) => {
                if (
                  key === 'status' ||
                  (key === 'expiration_date' && value === '-') ||
                  key === 'bumps' ||
                  key === 'created_at' ||
                  key === 'expected_salary_divider' ||
                  key === 'preference' ||
                  key === 'posted_by_telegram' ||
                  key === 'shortlisted' ||
                  key === 'shortlists_left'
                ) {
                  return acc;
                }

                if (key === 'expiration_date') {
                  // console.log(formatDate(value));
                }

                if (key === 'highest_rate' || key === 'lowest_rate') {
                  return {
                    ...acc,
                    [key]: `${value}`,
                  };
                }

                if (key === 'description') {
                  return {
                    ...acc,
                    job_description: value.join(`\r\n`),
                  };
                }

                return {
                  ...acc,
                  [key === 'category'
                    ? 'chosen_category'
                    : key === 'assignment_id'
                    ? 'assignmentId'
                    : key === 'location'
                    ? 'job_location'
                    : key === 'time'
                    ? 'days_and_hours'
                    : key === 'title'
                    ? 'job_title'
                    : key === 'posted_by_hp_number'
                    ? 'client_contact_number'
                    : key === 'posted_by_name'
                    ? 'client_name'
                    : key]:
                    key === 'expiration_date' ? formatDate(value) : value,
                };
              },
              {}
            );

            dispatch(resetForm());
            dispatch(setForm(editAssignmentForm));
          }
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    }

    return () => {
      if (assignmentIdSearchParam) {
        dispatch(resetForm());
      }
    };
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (!assignmentId && !searchParams.get('assignmentType')) {
      dispatch(getAdminAssignmentConfig()).then(data =>
        dispatch(setForm({ expiration_date: formatDate(data.expiration_date) }))
      );
    }
  }, [searchParams, assignmentId, dispatch]);

  return (
    <form
      className={style.container}
      onSubmit={onSubmit}
      onReset={() => dispatch(resetForm())}
    >
      <InfoGridCard
        heading='Client info'
        className={style.infoGridCard}
        logo='info'
        role='admin'
        position='top'
      >
        <UiInput
          value={client_name}
          onChange={({ target: { value } }) =>
            setFormValue('client_name', value.trimStart())
          }
          id={'admin_client_name'}
          title='Client name:'
          placeholder='Client name'
          borderColor={'info'}
          error={formErrors.client_name}
        />
        <UiInput
          value={client_contact_number}
          onChange={({ target: { value } }) =>
            setFormValue('client_contact_number', value.trimStart())
          }
          id={'admin_client_number'}
          title='Number:'
          placeholder='Number'
          borderColor={'info'}
          error={formErrors.client_contact_number}
        />
        <UiInput
          value={client_full_address}
          onChange={({ target: { value } }) =>
            setFormValue('client_full_address', value.trimStart())
          }
          id={'admin_client_address'}
          title='Address:'
          placeholder='Address'
          borderColor={'info'}
          error={formErrors.client_full_address}
        />
        <UiInput
          value={client_other_info}
          onChange={({ target: { value } }) =>
            setFormValue('client_other_info', value.trimStart())
          }
          id={'admin_client_other_info'}
          title='Other info:'
          placeholder='Other info'
          borderColor={'info'}
          error={formErrors.client_other_info}
        />
      </InfoGridCard>
      <InfoGridCard
        heading='Job info'
        className={style.infoGridCard}
        logo='info'
        role='admin'
      >
        <UISelect
          selectedOption={chosen_category}
          list={jobCategories}
          setSelectedOption={option =>
            dispatch(setForm({ chosen_category: option }))
          }
          heading='Select job category:'
          iconType='industries'
        />
        <UIMultipleSelect
          activeList={preferred_qualifications}
          possibleList={preferredQualifications}
          onChangeList={(_, option) =>
            dispatch(setPreferredQualification(option))
          }
          heading='Preferred qualifications:'
          placeholder='None selected'
          iconType='education'
        />
        <div className={style.datePickerContainer}>
          <label htmlFor='expiration_date'>Expiration Date:</label>
          <UiDatePicker
            id='expiration_date'
            name='expiration_date'
            maxDate={maxDate}
            selected={getDateFromLocaleString(expiration_date)}
            onChange={date =>
              dispatch(
                setForm({
                  expiration_date: formatDate(
                    date ??
                      getDateFromLocaleString(expiration_date) ??
                      assignmentInitialExpirationDate
                  ),
                })
              )
            }
          />
        </div>
        <fieldset className={style.jobType}>
          <legend>Job type</legend>
          {jobTypes.map(type => (
            <UIProfileCheckbox
              key={type}
              name='jobType'
              className={style.checkbox}
              title={type}
              id={type}
              type={'radio'}
              checked={job_type === type}
              onChange={({ target: { value } }) =>
                setFormValue('job_type', value)
              }
            />
          ))}
        </fieldset>
      </InfoGridCard>
      <InfoGridCard
        heading='About'
        className={style.infoGridCard}
        logo='book'
        role='admin'
        position='bottom'
      >
        <UiInput
          value={company_name}
          onChange={({ target: { value } }) =>
            setFormValue('company_name', value.trimStart())
          }
          id={'admin_company_name'}
          title='Company name:'
          placeholder='Company name'
          borderColor={'info'}
          error={formErrors.company_name}
        />
        <UiInput
          value={job_title}
          onChange={({ target: { value } }) =>
            setFormValue('job_title', value.trimStart())
          }
          id={'admin_job_title'}
          title='Job title:'
          placeholder='Job title'
          borderColor={'info'}
          error={formErrors.job_title}
        />
        <UiInput
          value={job_location}
          onChange={({ target: { value } }) =>
            setFormValue('job_location', value.trimStart())
          }
          id={'admin_job_location'}
          title='Job location:'
          placeholder='Enter your address and include postal code'
          borderColor={'info'}
          error={formErrors.job_location}
        />
        <UiInput
          value={days_and_hours}
          onChange={({ target: { value } }) =>
            setFormValue('days_and_hours', value.trimStart())
          }
          id={'admin_work_and_days'}
          title='Working days and hours:'
          placeholder='Working days and hours'
          borderColor={'info'}
          error={formErrors.days_and_hours}
        />
        <ProposedSalary
            title={webContent.job_proposed_salary_web}
          highestRate={highest_rate}
          lowestRate={lowest_rate}
          className={style.salary}
          onChangeLowestRate={onChangeLowestRate}
          onChangeHighestRate={onChangeHighestRate}
          typeOfJob={job_type}
          color={'admin'}
          error={formErrors.lowest_rate}
        />
        <label className={style.description}>
          <UITextarea
            id={'job_description'}
            title={'Job description:'}
            rows={10}
            maxLength={2600}
            value={validateCurrentInputValue(job_description)}
            placeholder='Please key in the job description...'
            // error={isError && !post.job_description}
            onChange={({ target: { value } }) =>
              setFormValue('job_description', value.trimStart())
            }
            color={'admin'}
            error={formErrors.job_description}
          />
        </label>
      </InfoGridCard>
      <footer className={style.buttons}>
        {/* <UIButton title={'Reset'} type='reset' classModificator={style.reset} /> */}
        <UIButton
          title={assignmentId ? 'Update job' : 'Post job'}
          type='submit'
          classModificator={style.submit}
        />
      </footer>
    </form>
  );
};

export default PostJob;
