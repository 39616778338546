import React, { FC, useCallback } from 'react';
import styles from './_styles.module.scss';
import TableHeadItem from './TableHeadItem';
import TableRow from './TableRow';
import { IInvoiceDetails } from '../../../../core/models/models';

const theadData = [
  'Date',
  'Package',
  'Shortlist',
  'Bump',
  'Post',
  'Validity',
  'Amount',
  'Invoice',
];

const CustomizedTables: FC<{ historyData: IInvoiceDetails[] }> = ({
  historyData,
}) => {
  return (
    <div className={styles.table_wrap}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.table_thead}>
            {theadData.map(h => {
              return <TableHeadItem key={h} heading={h} />;
            })}
          </tr>
        </thead>
        <tbody className={styles.table_body}>
          {historyData.map(row => {
            return <TableRow key={row.purchase_id} data={row} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomizedTables;
