import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './_styles.module.scss';

export interface UIRouterLinkProps {
  goTo: string;
  title: string;
  className?: string;
  replace?: boolean;
}

const UIRouterLink: FC<UIRouterLinkProps> = ({
  goTo,
  title,
  className,
  replace = false,
}) => {
  return (
    <NavLink
      to={goTo}
      className={`${styles.custom_link} ${className || ''}`}
      replace={replace}
    >
      {/* eslint-disable-next-line react/no-unknown-property */}
      <span about={title}>{title}</span>
    </NavLink>
  );
};

export default UIRouterLink;
