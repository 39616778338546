import React, { useEffect, useState, FC } from 'react';
import styles from './_styles.module.scss';
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';
import { useAppDispatch } from '../../../../core/hooks/reduxHooks';
import { Navigate } from 'react-router-dom';

const CheckoutForm: FC<{ clientSecretKey: string }> = ({ clientSecretKey }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | undefined>('');
  const [seccess, setSeccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   if (!stripe) {
  //     return;
  //   }
  //
  //   if (!clientSecretKey) {
  //     return;
  //   }
  //
  //   stripe.retrievePaymentIntent(clientSecretKey).then(({ paymentIntent }) => {
  //     switch (paymentIntent?.status) {
  //       case 'succeeded':
  //         setMessage('Payment succeeded!');
  //         break;
  //       case 'processing':
  //         setMessage('Your payment is processing.');
  //         break;
  //       case 'requires_payment_method':
  //         setMessage('Your payment was not successful, please try again.');
  //         break;
  //       default:
  //         setMessage('Something went wrong.');
  //         break;
  //     }
  //   });
  // }, [stripe]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    const { REACT_APP_BASE_URL } = process.env;

    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    return await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url:
            'https://singaporejob.sg/employer/price/purchase/completion',
        },
        redirect: 'if_required',
      })
      .then(result => {
        // console.log(result);
        if (result.paymentIntent?.status === 'succeeded') {
          setMessage('Payment succeeded!');
          setSeccess(true);
        } else {
          setMessage('Payment failed.');
        }
      })
      .catch(error => {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
          setMessage(error.message);
        } else {
          setMessage('An unexpected error occurred.');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (seccess) {
    return <Navigate to={`/employer/price/purchase/completion`} replace />;
  }

  return (
    <form id='payment-form' onSubmit={handleSubmit} className={styles.form}>
      <PaymentElement id='payment-element' className={styles.payment_element} />
      <button
        disabled={
          isLoading ||
          !stripe ||
          !elements ||
          message === 'Something went wrong.'
        }
        id='stripe_submit'
        className={styles.pay_btn}
      >
        <span id='button-text'>{isLoading ? 'Processing...' : 'Pay now'}</span>
      </button>
      {/* Show any error or success messages */}
      {message && (
        <div
          id='payment-message'
          className={styles.payment_message}
          style={
            message === 'Payment succeeded!'
              ? { color: '#198754' }
              : { color: '#ff3333' }
          }
        >
          {message}
        </div>
      )}
    </form>
  );
};

export default CheckoutForm;
