import React from 'react';
import styles from './_styles.module.scss';
import mainImg from '../../../../assets/images/employee-main.png';
import { useWindowSize } from '../../../../core/hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import UIButtonLink from '../../../UI/UIButtonLink/UIButtonLink';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../core/hooks/reduxHooks';
import { openModal } from '../../../../redux/reducers/ui/ModalDialogSlice';
import useWebContent from '../../../../core/hooks/useWebContent';

const EmployerHead: React.FC = () => {
  const size = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token } = useAppSelector(state => state.userTokenReducer);
  const [webContent] = useWebContent();

  const postAJobHeader = () => {
    if (!token) {
      dispatch(openModal('login'));
    } else {
      navigate('/employer/post-jobs');
    }
  };

  return (
    <section className={styles.main}>
      <div className={styles.main_content}>
        <article className={styles.text_content}>
          {size.width < 967 ? (
            <p className={styles.main_text}>
              Leading job portal <span>in Singapore</span>
            </p>
          ) : (
            <p className={styles.main_text}>
              Reach out to people through <span>all channels</span>
            </p>
          )}
          <p className={styles.secondary_text}>
            {webContent.poster_index_description_web}
          </p>
          <UIButtonLink
            title={webContent.new_post_poster_web}
            classModificator={styles.post_btn}
            callback={postAJobHeader}
          />
        </article>
        <img alt={'main img'} className={styles.main_img} src={mainImg} />
      </div>
    </section>
  );
};

export default EmployerHead;
