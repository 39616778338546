import React, { FC, useCallback } from 'react';
import UIInputLabelLogin from '../UIInputLabel/UIInputLabelLogin';
import { FormControl, Input } from '@mui/material';
import { useLocation } from 'react-router-dom';

interface UIRegistrationInputProps {
  id: string;
  label: string;
  type: 'text' | 'email' | 'tel' | 'password';
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
}

const UIRegistrationInput: FC<UIRegistrationInputProps> = ({
  id,
  label,
  type,
  error = false,
  ...rest
}) => {
  const location = useLocation();

  return (
    <div className='registration__input'>
      <FormControl sx={{ width: '100%' }} variant='standard'>
        <UIInputLabelLogin htmlFor={`registration-${id}`} color={'secondary'}>
          {label}
        </UIInputLabelLogin>
        <div className={'registration__input--cntr'}>
          {type === 'tel' ? (
            <div className='registration__phone--code'>
              <span>+65</span>
            </div>
          ) : null}
          <Input
            type={type}
            required={true}
            color={'secondary'}
            id={`registration-${id}`}
            aria-describedby={`registration-${id}`}
            {...rest}
            inputProps={{
              'aria-label': `registration-${id}`,
              style: {
                color: '#35435E',
                fontWeight: 400,
                fontSize: '18px',
              },
            }}
            sx={{
              width: '100%',
              fontSize: '16px',
              fontWeight: 400,
            }}
            error={error}
          />
        </div>
      </FormControl>
    </div>
  );
};

export default UIRegistrationInput;
