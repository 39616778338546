import React, { FC, useEffect } from 'react';
import styles from './_styles.module.scss';
import { Link, Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import {
  verifyUserEmail,
  verifyUserEmailUnsubscribeCandidates,
  verifyUserEmailUnsubscribeJobs,
} from '../../redux/thunk/profile';
import useWebContent from '../../core/hooks/useWebContent';

interface VerifyEmailPageProps {
  type: 'emailVerify' | 'unsubscribeJobs' | 'unsubscribeCandidate';
}

const VerifyEmailPage: FC<VerifyEmailPageProps> = ({ type }) => {
  const { emailToken } = useParams();
  const dispatch = useAppDispatch();
  const { verifyEmailMessage, token } = useAppSelector(
    state => state.userTokenReducer
  );
  const [webContent] = useWebContent();

  useEffect(() => {
    if (emailToken) {
      switch (type) {
        case 'emailVerify':
          dispatch(verifyUserEmail(emailToken));
          break;
        case 'unsubscribeJobs':
          dispatch(verifyUserEmailUnsubscribeJobs(emailToken));
          break;
        case 'unsubscribeCandidate':
          dispatch(verifyUserEmailUnsubscribeCandidates(emailToken));
          break;
      }
    }
  }, [emailToken, dispatch]);

  if (!emailToken) {
    return <Navigate to={'/'} replace={true} />;
  }

  return (
    <div className={styles.wrap}>
      {verifyEmailMessage && (
        <h2 className={styles.email}>{verifyEmailMessage}</h2>
      )}
      <Link to={'/search'} className={styles.view_all}>
        {webContent.view_jobs_button_web}
      </Link>
    </div>
  );
};

export default VerifyEmailPage;
