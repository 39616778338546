import { Fragment, FunctionComponent, useEffect } from 'react';
import Visitor from '../../containers/Visitor/Visitor';
import WorkCardList from '../../components/Works/WorkCardList/WorkCardList';
import TelegramChannels from '../../components/TelegramChannels/TelegramChannels';
import { Box, Container } from '@mui/material';
import JobsByLocation from '../../components/JobsByLocation/JobsByLocation';
import JobsByIndustries from '../../components/JobsByIndustries/JobsByIndustries';
import { useWindowSize } from '../../core/hooks/useWindowSize';
import { clearActiveFilters } from '../../redux/reducers/api/CandidateAssignmentsSlice';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import RequireCompleteProfile from '../../containers/CandidateLayout/RequireCompleteProfile';
import { Navigate } from 'react-router-dom';

export const CandidateHomePage: FunctionComponent = () => {
  const size = useWindowSize();
  const dispatch = useAppDispatch();
  const { possibleFilters } = useAppSelector(
    state => state.candidateAssignments
  );

  useEffect(() => {
    dispatch(clearActiveFilters());
  }, []);

  return (
    <RequireCompleteProfile>
      <Visitor />
      <Box mt={'-30px'}>
        <WorkCardList />
      </Box>
      <Container
        maxWidth='lg'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {size.width > 655 ? (
          <Fragment>
            <JobsByLocation />
            {possibleFilters.show_categories_icons_on_website && (
              <JobsByIndustries />
            )}
            <TelegramChannels />
          </Fragment>
        ) : null}
      </Container>
    </RequireCompleteProfile>
  );
};
