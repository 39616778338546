import React, { FC } from 'react';
import styles from './_styles.module.scss';
import SortLocation from './SortLocation';
import { useAppSelector } from '../../core/hooks/reduxHooks';
import { LocationTypes } from '../../core/models/models';

const JobsByLocation: FC = () => {
  const regions = useAppSelector(
    state => state.candidateAssignments.possibleFilters.regions
  );

  const locationsArr: LocationTypes[] = ['All regions', ...regions];

  return (
    <div className={styles.locations}>
      <h3 className={styles.heading}>
        View jobs <span>by location</span>
      </h3>
      <ul className={styles.locations_list}>
        {locationsArr.map(location => (
          <SortLocation key={location} location={location} />
        ))}
      </ul>
    </div>
  );
};

export default JobsByLocation;
