import React, { FC } from 'react';
import styles from '../EmployerHeader/_styles.module.scss';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import UINavLink from '../../UI/UINavLink/UINavLink';
import UIButtonLink from '../../UI/UIButtonLink/UIButtonLink';
import Logout from '../../Logout/Logout';
import { openModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import { drawerSlice } from '../../../redux/reducers/ui/DrawerSlice';
import useWebContent from "../../../core/hooks/useWebContent";

const HeaderEmployerNavLinks: FC = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector(state => state.userTokenReducer);
  const navigate = useNavigate();
    const [webContent] = useWebContent();

  const { closeDrawer } = drawerSlice.actions;

  const noAuthHeaderMenu = () => (
    <div className={styles.not_auth_menu_wrapper}>
      <UINavLink
        to='/employer/price'
        title='Price'
        icon='shop'
        className={styles.link}
        role='employer'
      />
      <div className={styles.buttons_wrapper}>
        <UIButtonLink
          title={webContent.go_to_candidate_page_web}
          classModificator={styles.search_btn}
          callback={() => navigate('/')}
          icon='jobSeeker'
          iconDirection='left'
        />
        <UIButtonLink
          title='Login / Register'
          classModificator={styles.login_btn}
          callback={() => {
            dispatch(openModal('login'));
            dispatch(closeDrawer());
          }}
          icon='myProfile'
          iconDirection='right'
        />
      </div>
    </div>
  );

  const authHeaderMenu = () => (
    <div className={styles.auth_wrap}>
      <nav className={styles.center_menu}>
        <UINavLink
          to='/employer/post-jobs'
          title='Post a job'
          icon='postJob'
          className={styles.link}
          role={'employer'}
        />
        <UINavLink
          to='/employer/manage-jobs'
          title='Manage jobs'
          icon='manage'
          className={styles.link}
          role={'employer'}
        />
        <UINavLink
          to='/employer/profile'
          title='Profile'
          icon='myProfile'
          className={styles.link}
          role={'employer'}
        />
        <UINavLink
          to='/employer/price'
          title='Price'
          icon='shop'
          className={styles.link}
          role={'employer'}
        />
        <UINavLink
          to='/employer/purchase-history'
          title='Purchase history'
          icon='purchaseHistory'
          className={styles.link}
          role={'employer'}
        />
      </nav>
      <div className={styles.buttons_wrapper}>
        <UIButtonLink
          title={webContent.go_to_candidate_page_web}
          classModificator={styles.search_btn}
          callback={() => navigate('/')}
          icon='jobSeeker'
          iconDirection='left'
        />
        <Logout forUser={'employer'} />
      </div>
    </div>
  );

  return <>{token ? authHeaderMenu() : noAuthHeaderMenu()}</>;
};

export default HeaderEmployerNavLinks;
