import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IWebContent {
  candidate_view_web: string;
  poster_index_description_web: string;
  new_post_poster_web: string;
  search_bar_keyword_web: string;
  search_bar_type_web: string;
  search_bar_location_web: string;
  search_bar_categories_web: string;
  poster_view_web: string;
  purchase_step_one_web: string;
  purchase_step_two_web: string;
  purchase_step_three_web: string;
  posting_locations_heading_web: string;
  posting_location_one_web: string;
  posting_location_two_web: string;
  posting_location_three_web: string;
  all_posts_will_be_posted_in_web: string;
  all_posts_channel_web: string;
  part_time_posted_in_web: string;
  part_time_posts_channel_web: string;
  full_time_posted_in_web: string;
  full_time_posts_channel_web: string;
  applicants_can_apply_through_web: string;
  faq_question_one_web: string;
  faq_answer_one_web: string;
  faq_question_two_web: string;
  faq_answer_two_web: string;
  faq_question_three_web: string;
  faq_answer_three_web: string;
  other_details_question_one_web: string;
  other_details_answer_one_web: string;
  other_details_question_two_web: string;
  other_details_answer_two_web: string;
  whatsapp_link_web: string;
  email_web: string;
  company_name_field_web: string;
  company_name_field_prefill_web: string;
  job_title_field_web: string;
  job_title_field_prefill_web: string;
  job_location_field_web: string;
  job_location_field_prefill_web: string;
  days_and_hours_field_web: string;
  days_and_hours_field_prefill_web: string;
  job_description_field_web: string;
  job_description_field_prefill_web: string;
  profile_incomplete_web: string;
  profile_updated_web: string;
  creating_web: string;
  on_review_web: string;
  on_website_web: string;
  on_website_and_on_telegram_web: string;
  open_web: string;
  closed_web: string;
  repost_web: string;
  rejected_web: string;
  matched_web: string;
  on_banned_content_review_web: string;
  contains_banned_content_web: string;
  edited_details_web: string;
  shortlisted_web: string;
  shortlists_left_web: string;
  bumps_left_web: string;
  buy_shortlists_web: string;
  buy_bumps_web: string;
  no_jobs_web: string;
  bump_description_web: string;
  shortlist_description_web: string;
  applicant_has_already_applied_modal: string;
  assignment_is_already_closed_modal: string;
  applicant_is_poster_modal: string;
  applicant_is_staff_modal: string;
  applicant_character_limit_for_qualification_exceeded_modal: string;
  applicant_character_limit_for_experience_exceeded_modal: string;
  sms_sending_error_modal: string;
  assignment_not_found_modal: string;
  user_is_banned_modal: string;
  phone_is_banned_modal: string;
  wrong_password_provided_modal: string;
  login_or_reset_modal: string;
  wrong_verification_code_modal: string;
  user_with_hp_number_not_found_modal: string;
  resume_not_found_modal: string;
  user_is_not_poster_modal: string;
  proposal_does_not_exist_modal: string;
  applicant_withdrew_proposal_modal: string;
  no_shortlists_left_modal: string;
  assignment_is_not_on_review_or_edited_details_or_repost_modal: string;
  assignment_is_currently_on_review_modal: string;
  assignment_is_currently_on_banned_content_review_modal: string;
  assignment_update_is_sent_for_approval_modal: string;
  assignment_has_been_put_on_banned_content_review_modal: string;
  assignment_updated_successfully_modal: string;
  no_more_reposts_modal: string;
  assignment_posted_successfully_modal: string;
  assignment_reposted_successfully_modal: string;
  assignment_sent_for_approval_modal: string;
  assignment_content_approved_successfully_modal: string;
  phone_provider_is_not_supported: string;
  assignment_rejected_modal: string;
  assignment_deleted_modal: string;
  draft_created_successfully_modal: string;
  assignment_has_been_put_on_review_modal: string;
  consent_text_web: string;
  payment_received_web: string;
  continue_to_post_web: string;
  continue_to_shortlist_web: string;
  continue_to_bump_web: string;
  email_verification_sent_web: string;
  location_prefix_web: string;
  company_name_prefix_web: string;
  rate_prefix_web: string;
  day_and_time_prefix_web: string;
  has_bad_characters_modal: string;
  no_candidates_web: string;
  contact_us_channel_one_handle_web: string;
  contact_us_channel_one_link_web: string;
  contact_us_channel_two_handle_web: string;
  contact_us_channel_two_link_web: string;
  contact_us_channel_three_handle_web: string;
  contact_us_channel_three_link_web: string;
  age_web: string;
  gender_web: string;
  race_web: string;
  nationality_web: string;
  group_web: string;
  highest_qualification_web: string;
  available_day_and_time_web: string;
  expected_salary_web: string;
  details_of_experience_web: string;
  name_web: string;
  email_field_web: string;
  whatsapp_web: string;
  phone_web: string;
  resume_web: string;
  user_already_shortlisted_modal: string;
  phone_pref_web: string;
  candidate_profile_resume_attachment_size_modal_web: string;
  candidate_profile_picture_size_modal_web: string;
  all_posts_channel_link_web: string;
  part_time_posts_channel_link_web: string;
  full_time_posts_channel_link_web: string;
  email_verification_fail_web: string;
  email_verification_success_web: string;
  view_jobs_button_web: string;
  email_unsubscribed_from_job_notifications_web: string;
  email_unsubscribed_from_applicant_notifications_web: string;
  apply_modal_title_web: string;
  apply_modal_body_web: string;
  posting_successful_modal_web: string;
  no_more_posting_credits_modal: string;
  purchase_posting_credits_button: string;
  go_to_candidate_page_web: string;
  go_to_job_poster_page_web: string;
  search_bar_categories_all_web: string;
  job_proposed_salary_web: string;
  job_industry_web: string;
  job_preferred_qualification_web: string;
  job_job_description_web: string;
  years_of_working_experience_web: string;
  details_of_your_past_working_experience_web: string;
  preferred_job_categories_section_title_web: string;
  preferred_job_categories_section_header_web: string;
  example_of_profile_web: string;
}

export type CandidateJobsContentType = {
  link: string;
  qr: string;
};
export interface ICandidateJobsChannelsContent {
  all_jobs: CandidateJobsContentType;
  part_time: CandidateJobsContentType;
  full_time: CandidateJobsContentType;
}

export interface IEmployerContent {
  group?: string;
  name?: string;
  email?: string;
  age?: string;
  whatsapp?: string;
  phone?: string;
  resume?: string;
  gender?: string;
  race?: string;
  citizenship?: string;
  years_teaching?: string;
  day_and_time?: string;
  expected_salary?: string;
  qualification?: string;
  experience?: string;
  profile_picture_fs?: string;
}
interface WebContentState {
  // webContent: { [Key in string]: string };
  webContent: IWebContent;
  candidateContent: ICandidateJobsChannelsContent;
  employerContent: IEmployerContent;
  isLoading: boolean;
  error: string;
}

const initialState: WebContentState = {
  webContent: {
    candidate_view_web: 'For Job Seeker',
    poster_index_description_web:
      'Only job portal which allows people to apply via Website and Telegram!',
    new_post_poster_web: 'Post a job',
    search_bar_keyword_web: 'What job are you looking for?',
    search_bar_type_web: 'What type of jobs?',
    search_bar_location_web: 'Where?',
    search_bar_categories_web: 'Which industries?',
    poster_view_web: 'For Employers',
    purchase_step_one_web: 'Purchase posting credit',
    purchase_step_two_web: 'Post a job',
    purchase_step_three_web: 'Receive profiles of job candidates',
    posting_locations_heading_web:
      'We provide maximum outreach. Jobs will be posted in these locations',
    posting_location_one_web: 'Website',
    posting_location_two_web: 'Telegram Channels',
    posting_location_three_web: 'App (In development)',
    all_posts_will_be_posted_in_web: 'All jobs will be posted in:',
    all_posts_channel_web: '@Singapore_Jobs_SG1',
    all_posts_channel_link_web: 'https://t.me/Singapore_Jobs_SG1',
    part_time_posted_in_web: 'Part time :',
    part_time_posts_channel_web: '@SgPartTimeAgency1',
    part_time_posts_channel_link_web: 'https://t.me/SgPartTimeAgency1',
    full_time_posted_in_web: 'Full time :',
    full_time_posts_channel_web: '@SgFullTimeAgency1',
    full_time_posts_channel_link_web: 'https://t.me/SgFullTimeAgency1',
    applicants_can_apply_through_web:
      'Job candidates can apply through website and telegram bot.',
    faq_question_one_web: 'How do I post jobs?',
    faq_answer_one_web:
      'You can post jobs via our website or telegram bot @SgJobBot',
    faq_question_two_web: 'How do I receive profile of job candidates?',
    faq_answer_two_web:
      'You can view job candidates profile via our website or telegram bot @SgJobBot.\r\n\r\n',
    faq_question_three_web:
      'How long will the job advertisement be open to receive application?',
    faq_answer_three_web:
      'The job advertisement will be open for 15 days. You can extend the job advertisement by purchasing bump.',
    other_details_question_one_web: "What's shortlist?",
    other_details_answer_one_web:
      'If you have 5 shortlists and 8 people applied for the job, you can only get the contact details of 5 people. If required, you can purchase additional shortlists.',
    other_details_question_two_web: "What's bump?",
    other_details_answer_two_web:
      'When a job post is bumped, the posting date will be changed to the bump date. Eg: A job is posted on 27 Sept and bumped on 15 Oct, the posting date will be changed to 15 Oct. The job will appear at the top of the page. The job advertisement period will also be extended by 15 days.',
    whatsapp_link_web: 'https://wa.me/6588752200',
    email_web: 'marketing@singaporejobs.com.sg',
    phone_web: '88752200',
    company_name_field_web: 'Company name:',
    company_name_field_prefill_web: 'Company name',
    job_title_field_web: 'Job title:',
    job_title_field_prefill_web: 'Job title',
    job_location_field_web: 'Job location:',
    job_location_field_prefill_web: 'Enter the address and include postal code',
    days_and_hours_field_web: 'Working days and hours:',
    days_and_hours_field_prefill_web: 'Working days and hours',
    job_description_field_web: 'Job description:',
    job_description_field_prefill_web: 'Please key in the job description...',
    profile_incomplete_web: 'Please fill in all the required fields.',
    profile_updated_web: 'Profile successfully updated',
    creating_web: 'Creating',
    on_review_web: 'On review',
    on_website_web: 'On website1',
    on_website_and_on_telegram_web: 'On website & telegram1',
    open_web: 'Open',
    closed_web: 'Closed',
    repost_web: 'Repost',
    rejected_web: 'Rejected',
    matched_web: 'Matched',
    on_banned_content_review_web: 'On banned content review',
    contains_banned_content_web: 'Contains banned content',
    edited_details_web: 'Edited details',
    shortlisted_web: 'Shortlisted',
    shortlists_left_web: 'Shortlists left',
    bumps_left_web: 'Bumps left',
    buy_shortlists_web: 'Buy Shortlists',
    buy_bumps_web: 'Buy bumps',
    no_jobs_web: 'Not available jobs yet',
    bump_description_web:
      'Bump helps to rank your job advertisement higher and extend the expiration by 15 days',
    shortlist_description_web:
      'Shortlist allows you to get the candidate contact information',
    applicant_has_already_applied_modal:
      'You have already applied for this assignment.',
    assignment_is_already_closed_modal:
      'The assignment has been already closed.',
    applicant_is_poster_modal:
      'Users cannot apply for assignment they had posted.',
    applicant_is_staff_modal: 'Staff cannot apply for an assignment',
    applicant_character_limit_for_qualification_exceeded_modal:
      'Max character limit {} for qualification exceeded',
    applicant_character_limit_for_experience_exceeded_modal:
      'Max character limit {} for experience exceeded',
    sms_sending_error_modal: 'SMS service failed to send verification code',
    assignment_not_found_modal: 'Assignment with id {} not found',
    user_is_banned_modal: 'User is banned',
    phone_is_banned_modal: 'Phone is banned',
    wrong_password_provided_modal: 'Wrong password provided',
    login_or_reset_modal: 'Please login or reset password',
    wrong_verification_code_modal: 'Wrong verification code',
    user_with_hp_number_not_found_modal: 'User with hp number {} not found',
    resume_not_found_modal: 'Resume not found',
    user_is_not_poster_modal: 'You are not the poster of the job',
    proposal_does_not_exist_modal: 'Proposal does not exist',
    applicant_withdrew_proposal_modal: 'Applicant withdrew this proposal',
    no_shortlists_left_modal: 'No shortlists left',
    user_already_shortlisted_modal: 'Candidate is already shortlisted',
    assignment_is_not_on_review_or_edited_details_or_repost_modal:
      'This assignment is not on review or edited details or repost',
    assignment_is_currently_on_review_modal:
      'Assignment is currently on the review.',
    assignment_is_currently_on_banned_content_review_modal:
      'Assignment is currently on banned content review.',
    assignment_update_is_sent_for_approval_modal:
      'Assignment update is sent for approval.',
    assignment_has_been_put_on_banned_content_review_modal:
      'Assignment has been put on banned content review.',
    assignment_updated_successfully_modal: 'Assignment updated successfully.',
    no_more_reposts_modal: 'There is no more reposts left',
    assignment_posted_successfully_modal: 'Assignment posted successfully',
    assignment_reposted_successfully_modal: 'Assignment reposted successfully',
    assignment_sent_for_approval_modal:
      'Assignment sent for approval successfully',
    assignment_content_approved_successfully_modal:
      'Assignment content has been approved successfully',
    phone_provider_is_not_supported: '-',
    assignment_rejected_modal: 'Assignment rejected.',
    assignment_deleted_modal: 'Assignment deleted',
    draft_created_successfully_modal: 'Draft created successfully',
    assignment_has_been_put_on_review_modal:
      'Assignment has been put on review.',
    consent_text_web:
      "By clicking 'submit', it means that you consent to us posting and sharing all your personal details, certificates and/or photo on any mediums and anyone we deem fit. If you are uncomfortable, please go to profile and delete your profile",
    payment_received_web: 'Thanks! We have received the payment.',
    continue_to_post_web: 'Continue to post a job',
    continue_to_shortlist_web: 'Continue to shortlist candidate',
    continue_to_bump_web: 'Continue to bump job',
    email_verification_sent_web:
      'We have sent an email to you. Please click on the link to verify your email account.',
    location_prefix_web: 'Location:',
    company_name_prefix_web: 'Company:',
    rate_prefix_web: 'Salary:',
    day_and_time_prefix_web: 'Working hours:',
    has_bad_characters_modal: 'Please do not enter emoji, ! or ?',
    no_candidates_web: 'No candidates has been shortlisted yet',
    contact_us_channel_one_handle_web: 'All Jobs Telegram Channel',
    contact_us_channel_one_link_web: 'https://t.me/Singapore_Jobs_SG',
    contact_us_channel_two_handle_web: 'Part Time Jobs Channel',
    contact_us_channel_two_link_web: 'https://t.me/JobsPartTimer',
    contact_us_channel_three_handle_web: 'Full Time Jobs Channel',
    contact_us_channel_three_link_web: 'https://t.me/JobsFullTimer',
    age_web: 'Age:',
    gender_web: 'Gender:',
    race_web: 'Race:',
    nationality_web: 'Nationality:',
    group_web: 'Group:',
    highest_qualification_web: 'Highest Qualification:',
    available_day_and_time_web: 'Availability:',
    expected_salary_web: 'Expected salary:',
    details_of_experience_web: 'Details of experience:',
    name_web: 'Name:',
    email_field_web: 'Email:',
    whatsapp_web: 'Whatsapp:',
    phone_pref_web: 'Phone:',
    resume_web: 'Resume:',
    candidate_profile_resume_attachment_size_modal_web:
      'Resume size is more than {} mb. Please reduce the file size.',
    candidate_profile_picture_size_modal_web:
      'Profile picture size is more than {} mb. Please reduce the file size.',
    email_verification_fail_web: 'The email address could not be verified',
    email_verification_success_web: 'Thanks for verifying your email!',
    view_jobs_button_web: 'View Jobs',
    email_unsubscribed_from_job_notifications_web:
      'You have been unsubscribed from new job email notifications!',
    email_unsubscribed_from_applicant_notifications_web:
      'You have been unsubscribed from candidates email notifications!',
    apply_modal_title_web: 'Thank you for applying!',
    apply_modal_body_web: 'Join these telegram channels today!',
    posting_successful_modal_web:
      'Thank you for posting the job. The job will be posted after the admin approves it.',
    no_more_posting_credits_modal:
      'Please purchase posting credit to post a job',
    purchase_posting_credits_button: 'Purchase posting credit',
    go_to_candidate_page_web: 'For Job Seeker',
    go_to_job_poster_page_web: 'For Employers',
    search_bar_categories_all_web: 'All industries',
    job_proposed_salary_web: 'Proposed salary:',
    job_industry_web: 'Industry:',
    job_preferred_qualification_web: 'Preferred qualification:',
    job_job_description_web: 'Job description:',
    years_of_working_experience_web: 'Years of working experience:',
    details_of_your_past_working_experience_web:
      'Provide details of your past working experience:',
    preferred_job_categories_section_title_web: 'Preferred Job Categories',
    preferred_job_categories_section_header_web:
      'Please select your preferred job categories. We will send notification based on your preference (Can select multiple options).',
    example_of_profile_web: 'Example of job candidate profile',
  },
  candidateContent: {
    all_jobs: {
      link: 'https://t.me/Singapore_Jobs_SG1',
      qr: '',
    },
    part_time: {
      link: 'https://t.me/SgPartTimeAgency1',
      qr: '',
    },
    full_time: {
      link: 'https://t.me/SgFullTimeAgency1',
      qr: '',
    },
  },
  employerContent: {
    group: 'Group',
    name: 'Name',
    email: 'Email',
    age: 'Age',
    citizenship: 'Nationality',
    qualification: 'Highest Qualification',
    experience: 'Experience',
    profile_picture_fs: '',
  },
  isLoading: false,
  error: '',
};

export const WebContentSlice = createSlice({
  name: 'employer/invoices',
  initialState,
  reducers: {
    setWebContent(state, { payload }: PayloadAction<IWebContent>) {
      state.webContent = payload;
    },
    setLoadingWebContent(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setCandidateJobsChannelsContent(
      state,
      { payload }: PayloadAction<ICandidateJobsChannelsContent>
    ) {
      state.candidateContent = payload;
    },
    setEmployerCandidateCardContent(
      state,
      { payload }: PayloadAction<IEmployerContent>
    ) {
      state.employerContent = payload;
    },
  },
});

export const {
  setWebContent,
  setLoadingWebContent,
  setCandidateJobsChannelsContent,
  setEmployerCandidateCardContent,
} = WebContentSlice.actions;

export default WebContentSlice.reducer;
