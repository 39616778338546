import { API } from '../../core/api/api';
import { IAvailableWorksParams } from '../../core/models/models';
import { AppDispatch } from '../store';
import {
  clearApplyPosterInfo,
  setApplyPosterInfo,
  setAssignments,
  setJobsLoading,
  setOpenAssignmentsData,
  setPossibleFilters,
  setSaveAssignment,
  setSelectedJob,
  setTotalPages,
} from '../reducers/api/CandidateAssignmentsSlice';
import { setLoading } from '../reducers/ui/HandlerSlice';
import {
  closeModal,
  openErrorModal,
  openModal,
} from '../reducers/ui/ModalDialogSlice';
import { modalContentTexts } from '../../core/constants/modalsContentText';

export const getAssignmentFilters = () => async (dispatch: AppDispatch) => {
  return API.getAssignmentFilters()
    .then(res => {
      dispatch(setPossibleFilters(res.data));
    })
    .catch(error => {
      // console.log(error);
      // dispatch(openErrorModal(modalContentTexts.error.text));
    })
    .finally(() => {
      // dispatch(setLoading(false));
    });
};

export const getAssignmentsAsync =
  (payload?: IAvailableWorksParams, selectedReplace?: boolean) =>
  async (dispatch: AppDispatch) => {
    dispatch(setJobsLoading(true));

    return API.getOpenAssignments(payload || undefined)
      .then(response => {
        // console.log(response);
        // dispatch(setAssignments(response.data.results));
        // dispatch(setTotalPages(response.data.totalPages));
        // dispatch(setSelectedJob(response.data.results[0]));
        dispatch(
          setOpenAssignmentsData({
            assignments: response.data.results,
            totalPages: response.data.totalPages,
            // selectedJob: response.data.results[0],
          })
        );

        if (selectedReplace) {
          dispatch(setSelectedJob(response.data.results[0]));
        }
      })
      .catch(error => {
        // console.log(error);
        dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setJobsLoading(false));
      });
  };

export const getAssignmentDetailsAsync =
  (assignment_id: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.getAssignmentDetails(assignment_id)
      .then(response => {
        dispatch(setSelectedJob(response.data));
      })
      .catch(error => {
        // console.log(error);
        dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getSavedAssignmentsAsync =
  (payload: IAvailableWorksParams, selectedReplace: boolean) =>
  async (dispatch: AppDispatch) => {
    dispatch(setJobsLoading(true));

    return API.getSavedAssignments(payload)
      .then(response => {
        dispatch(setAssignments(response.data.results));
        dispatch(setTotalPages(response.data.totalPages));
        if (selectedReplace) {
          dispatch(setSelectedJob(response.data.results[0]));
        }
      })
      .catch(error => {
        // console.log(error);
        dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setJobsLoading(false));
      });
  };

export const getAppliedAssignments =
  (payload: IAvailableWorksParams, selectedReplace: boolean) =>
  async (dispatch: AppDispatch) => {
    dispatch(setJobsLoading(true));

    return API.getAppliedAssignments(payload)
      .then(response => {
        dispatch(setAssignments(response.data.results));
        dispatch(setTotalPages(response.data.totalPages));
        if (selectedReplace) {
          dispatch(setSelectedJob(response.data.results[0]));
        }
      })
      .catch(error => {
        // console.log(error);
        dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setJobsLoading(false));
      });
  };

export const saveAssignment = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  return API.saveAssignment(id)
    .then(() => {
      dispatch(setSaveAssignment({ id, save: true }));
    })
    .catch(error => {
      // console.log(error);
      dispatch(openErrorModal(modalContentTexts.error.text));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const deleteSaveAssignment =
  (id: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.deleteSaveAssignment(id)
      .then(() => {
        dispatch(setSaveAssignment({ id, save: false }));
      })
      .catch(error => {
        // console.log(error);
        dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getApplyPosterInfo =
  (id: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    dispatch(clearApplyPosterInfo());

    return API.getApplyPosterInfo(id)
      .then(response => {
        if (response.status === 200) {
          dispatch(setApplyPosterInfo(response.data));
        }

        return response.data;
      })
      .catch(error => {
        // console.log(error.response.data.message);
        dispatch(closeModal());
        dispatch(openErrorModal(error.response.data.message));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const applyAssignment =
  (data: FormData) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    return API.postApplyJobCandidate(data)
      .then(res => {
        if (res.data) {
          dispatch(clearApplyPosterInfo());
          dispatch(openModal('success applying'));
        }
        //
        // console.log('res', res);
        // console.log('data', data);
      })
      .catch(error => {
        // console.log(error);
        dispatch(openErrorModal(modalContentTexts.error.text));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
