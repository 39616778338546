import React, { FC, useCallback, useEffect, useState } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import { modalDialogSlice } from '../../../redux/reducers/ui/ModalDialogSlice';
import UIButton from '../../UI/UIButton/UIButton';
import './VerificationModalContent.scss';
import { setError } from '../../../redux/reducers/api/UserTokenSlice';
import { useNavigate } from 'react-router-dom';
import {
  resendAdminVerifyCode,
  resendUserRegisterVerifyCode,
  resetPasswordFromLogin,
  verifyAdminLogin,
  verifyResetLoginPasswordUpdate,
  verifyUserRegister,
} from '../../../redux/thunk/login';
import {
  resetPasswordFromProfile,
  verifyProfilePasswordUpdate,
  verifyUpdatePhoneNumber,
} from '../../../redux/thunk/profile';
import { styled } from '@mui/material';
import {
  getCountDownDate,
  getDistance,
  getSeconds,
  getTimerView,
} from '../../../core/functions';

const MuiOtpInputStyled = styled(MuiOtpInput)(({ theme }) => ({
  color: theme.palette.text.primary,
  margin: '0 35px',
  padding: '5px 0',

  '& .MuiOtpInput-TextField': {
    fontSize: '1.4rem',
  },
  '& .Mui-focused': {
    // borderBottom: '2px solid #3653b7',
    borderColor: '#3653b7',
  },
  '& .MuiOutlinedInput-input': {
    // borderBottom: '1px solid #3653b7',
  },
  '& input': {
    border: 'none',
    fontSize: '1.4rem',
    padding: '10px 5px',
  },
}));

const VerificationModalContent: FC<{
  type:
    | 'phone'
    | 'resetPassLogin'
    | 'resetPassProfile'
    | 'registration'
    | 'adminLogin';
}> = ({ type }) => {
  const [code, setCode] = useState('');
  const dispatch = useAppDispatch();
  const { closeModal } = modalDialogSlice.actions;
  const { hp_number, error, passwordTemp } = useAppSelector(
    state => state.userTokenReducer
  );
  const otpTimestamp = useAppSelector(
    state => state.modalDialogReducer.misc.otpTimestamp
  );

  const [seconds, setSeconds] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (otpTimestamp) {
      setSeconds(getSeconds(getDistance(getCountDownDate(otpTimestamp))));
    }
  }, [otpTimestamp]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (seconds === 0 || seconds < 0) {
        setSeconds(0);
        clearTimeout(timeOut);

        return;
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [seconds]);

  const verification = (event?: React.SyntheticEvent, finalValue?: string) => {
    event?.preventDefault();

    if (
      (!finalValue && code.length < 4) ||
      (finalValue && finalValue.length < 4)
    ) {
      return;
    }

    switch (type) {
      case 'registration':
        dispatch(
          verifyUserRegister({ hp_number: hp_number, code: finalValue || code })
        );
        break;
      case 'phone':
        dispatch(
          verifyUpdatePhoneNumber({
            code: finalValue || code,
            hp_number: hp_number,
          })
        );
        break;
      case 'resetPassLogin':
        dispatch(
          verifyResetLoginPasswordUpdate({
            hp_number: hp_number,
            code: finalValue || code,
            password: passwordTemp,
          })
        );
        break;
      case 'resetPassProfile':
        dispatch(
          resetPasswordFromProfile({
            password: passwordTemp,
            code: finalValue || code,
          })
        );
        break;
      case 'adminLogin':
        dispatch(
          verifyAdminLogin({ hp_number: hp_number, code: finalValue || code })
        );
        break;
      default:
        break;
    }
  };

  const resendVerifyCode = useCallback(() => {
    switch (type) {
      case 'registration':
        dispatch(
          resendUserRegisterVerifyCode({
            password: passwordTemp,
            hp_number: hp_number,
          })
        );
        break;
      case 'adminLogin':
        dispatch(resendAdminVerifyCode({ hp_number, password: passwordTemp }));
        break;
      case 'resetPassLogin':
        dispatch(resetPasswordFromLogin(hp_number));
        break;

      case 'resetPassProfile':
        dispatch(verifyProfilePasswordUpdate());
        break;
    }
  }, [passwordTemp, hp_number]);

  const matchIsNumeric = (text: any) => {
    const isNumber = typeof text === 'number';
    const isString = typeof text === 'string';

    return (isNumber || (isString && text !== '')) && !isNaN(Number(text));
  };

  const validateChar = (value: string, index: number) => {
    return matchIsNumeric(value);
  };

  useEffect(() => {
    if (error.length > 0) {
      setCode('');
    }
  }, [error]);

  return (
    <form
      id={'verify'}
      onSubmit={verification}
      className='modal__dialog verification'
    >
      <UIModalDialogHeader heading={'verification'} />

      <div className='verification__content'>
        <div className={`verification__code`}>
          <MuiOtpInputStyled
            value={code}
            validateChar={validateChar}
            onComplete={finalValue => {
              verification(undefined, finalValue);
            }}
            length={4}
            onChange={value => {
              if (error.length > 0) {
                dispatch(setError(''));
              }

              setCode(value);
            }}
            // error={error.length}
          />
          {error.length > 0 && <p className={'error_text'}>{error}</p>}
        </div>
        <div className='verification__text'>
          Please enter the code that is sent to your phone
        </div>

        <div className={'resend_wrap'}>
          <button
            disabled={seconds > 0}
            onClick={() => {
              if (seconds === 0) {
                resendVerifyCode();
              } else {
                return;
              }
            }}
            className='verification__resend'
          >
            Send code again{' '}
            {seconds !== null && seconds !== 0 && getTimerView(seconds)}
          </button>
        </div>

        <div className='verification__submit'>
          <UIButton
            title={'Submit'}
            type='submit'
            classModificator={'Button__modal--submit'}
          />
        </div>
      </div>
    </form>
  );
};

export default VerificationModalContent;
