import React, { useCallback } from 'react';
import { createStyles, Dialog } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import { modalDialogSlice } from '../../redux/reducers/ui/ModalDialogSlice';
import './ModalDialog.scss';
import VerificationModalContent from '../../components/ModalsContent/VerificationModalContent/VerificationModalContent';
import AuthModalLogin from '../../components/ModalsContent/LoginModalContent/LoginModal';
import RegistrationModalContent from '../../components/ModalsContent/RegistrationModalContent/RegistrationModalContent';
import ApplyModal from '../../components/ModalsContent/ApplyModalContent/ApplyModal';
import InfoModalContent from '../../components/ModalsContent/InfoModalContent/InfoModalContent';
import SuccessfullyApplyModal from '../../components/ModalsContent/ApplyModalContent/SuccessfullyApplyModal';
import ErrorModalContent from '../../components/ModalsContent/ErrorModalContent/ErrorModalContent';
import PurchaseCreditBumpOrShortlistContent from '../../components/ModalsContent/PurchaseCreditBumpOrShortlistContent/PurchaseCreditBumpOrShortlistContent';
import NewPhone from '../../components/ModalsContent/NewPhoneModalContent/NewPhone';
import UpdatePassword from '../../components/ModalsContent/UpdatePasswordModalContent/UpdatePasswordModal';
import AssignmentBannedContent from '../../components/ModalsContent/AssignmentBannedContent/AssignmentBannedContent';
import DeleteUserModal from '../../components/ModalsContent/DeleteUserModal/DeleteUserModal';
import PurchaseBumpAmount from '../../components/ModalsContent/PurchaseBumpAmountModalContent/PurchaseBumpAmount';
import PurchaseShortlistAmount from '../../components/ModalsContent/PurchaseShortlistAmountModalContent/PurchaseShortlistAmount';
import PurchaseMoreCredits from '../../components/ModalsContent/PurchaseMoreCredits/PurchaseMoreCredits';

const ModalDialog = () => {
  const dispatch = useAppDispatch();
  const { closeModal } = modalDialogSlice.actions;
  const { isOpened, modal } = useAppSelector(state => state.modalDialogReducer);

  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const handleModalContent = useCallback(() => {
    switch (modal) {
      case 'registration':
        return <RegistrationModalContent />;
      case 'login':
        return <AuthModalLogin />;
      case 'deleteUser':
        return <DeleteUserModal />;
      case 'verifyRegistration':
        return <VerificationModalContent type='registration' />;
      case 'verifyPhoneUpdate':
        return <VerificationModalContent type='phone' />;
      case 'verifyLoginPasswordUpdate':
        return <VerificationModalContent type='resetPassLogin' />;
      case 'verifyProfilePasswordUpdate':
        return <VerificationModalContent type='resetPassProfile' />;
      case 'verifyAdminLogin':
        return <VerificationModalContent type='adminLogin' />;
      case 'purchase_shortlist':
        return <PurchaseShortlistAmount />;
      case 'purchase_more_credits':
        return <PurchaseMoreCredits />;
      case 'purchase_bump':
        return <PurchaseBumpAmount />;
      case 'purchase_credit_bump_or_shortlist':
        return <PurchaseCreditBumpOrShortlistContent />;
      case 'applying':
        return <ApplyModal />;
      case 'success applying':
        return <SuccessfullyApplyModal />;
      case 'banned content':
        return <AssignmentBannedContent />;
      case 'info':
        return <InfoModalContent />;
      case 'error':
        return <ErrorModalContent />;
      case 'newPhone':
        return <NewPhone type='new' />;
      case 'enterPhone':
        return <NewPhone type='enter' />;
      case 'updatePasswordLogin':
        return <UpdatePassword type='login' />;
      case 'updatePasswordProfile':
        return <UpdatePassword type='profile' />;
    }
  }, [modal]);

  return (
    <Dialog
      className={'modal'}
      open={isOpened}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{
        sx: {
          borderRadius: '11px',
          margin: '0 10px',
          backgroundColor: '#FFFFFF',
          scrollbarWidth: 'none',
          // backgroundColor: 'red',
          '&::-webkit-scrollbar': {
            width: '0',
          },
          // '&::-webkit-scrollbar-track': {
          //   background: '#f1f1f1',
          // },
          // '&::-webkit-scrollbar-thumb': {
          //   backgroundColor: '#888',
          // },
          // '&::-webkit-scrollbar-thumb:hover': {
          //   background: '#555',
          // },
        },
      }}
      sx={{
        backdropFilter: 'blur(4px)',
      }}
      scroll={'paper'}
      classes={{ scrollPaper: 'scrollPaper' }}
    >
      {handleModalContent()}
    </Dialog>
  );
};

export default ModalDialog;
