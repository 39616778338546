import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { setupStore } from './redux/store';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import App from './App';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

const store = setupStore();

const muiTheme = createTheme({
  /* custom theme */
  palette: {
    primary: {
      // main: '#00CABA',
      main: '#3653B7',
    },
    secondary: {
      main: '#3653B7',
    },
    info: {
      main: '#7030a0',
    },
    text: {
      primary: '#35435E',
      secondary: '#FFFFFF',
    },
    error: {
      main: '#f32a2a',
      dark: '#f32a2a',
    },
  },
  typography: {
    subtitle1: {
      fontWeight: 700,
      fontSize: '15px',
      textTransform: 'uppercase',
    },
  },
});

render(
  <Provider store={store}>
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={muiTheme}>
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
