import React from 'react';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import { useAppDispatch } from '../../../core/hooks/reduxHooks';
import styles from '../_styles.module.scss';
import UIButton from '../../UI/UIButton/UIButton';
import { closeModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import { deleteUserProfile } from '../../../redux/thunk/profile';

const DeleteUserModal: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <div className={`${styles.modal} ${styles.modal_delete_user}`}>
      <UIModalDialogHeader heading={'Delete profile'} />
      <div className={styles.modal_body}>
        <p className={styles.message}>
          Are you sure you want to delete your profile?
        </p>
        <p className={styles.message}>
          By deleting your profile, companies will no longer be able to contact
          you for the jobs that you applied
        </p>
        <div className={styles.buttons}>
          <UIButton
            title={'Yes'}
            callback={() => dispatch(deleteUserProfile())}
            classModificator={styles.delete}
          />
          <UIButton
            title={'No'}
            callback={() => dispatch(closeModal())}
            classModificator={styles.no}
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;
