import React, { FC, ChangeEvent, useEffect } from 'react';
import styles from './_styles.module.scss';
import { Pagination } from '@mui/material';
import { useWindowSize } from '../../../core/hooks/useWindowSize';

const UIPagination: FC<{
  currentPage: number;
  totalPages: number;
  onChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
  className?: string;
  searchPageMobile?: boolean;
  [x: string]: any;
}> = ({
  currentPage,
  totalPages,
  onChangePage,
  className,
  searchPageMobile = false,
  ...rest
}) => {
  useEffect(() => {
    if (searchPageMobile) {
      window.scrollTo({ top: 410, left: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [currentPage]);

  return (
    <div className={styles.pagination_wrap}>
      <Pagination
        className={`${className || ''}`}
        page={currentPage}
        defaultPage={1}
        siblingCount={1}
        boundaryCount={1}
        count={totalPages}
        variant='outlined'
        shape='rounded'
        {...rest}
        onChange={onChangePage}
        // classes={{ ul: styles.pag_list }}
      />
    </div>
  );
};

export default UIPagination;
