import React, {FC, useCallback, useEffect} from 'react';
import styles from './_styles.module.scss';
import UIContainer from '../../../../containers/Container/Container';
import { useWindowSize } from '../../../../core/hooks/useWindowSize';
import PostLocationMob from './PostLocationMob';
import PostLocationDeck from './PostLocationDeck';
import { tabletsLandscapeBreakpoint } from '../../../../core/constants/variables';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../core/hooks/reduxHooks';
import noPhoto from '../../../../assets/images/no_photo_candidate.png';
import { clearActiveFilters } from '../../../../redux/reducers/api/CandidateAssignmentsSlice';
import { getEmployerCandidateCardContent } from '../../../../redux/thunk/webContent';
import UIReviewCandidateQuality from '../../../UI/UIReviewCandidateQuality/UIReviewCandidateQuality';

const WhyUs: FC = () => {
  const size = useWindowSize();
  const dispatch = useAppDispatch();

  const { webContent, employerContent } = useAppSelector(
    state => state.WebContentReducer
  );

  useEffect(() => {
    dispatch(getEmployerCandidateCardContent());
  }, []);

  const candidateInfo = useCallback((p: string | undefined) => {
    return p ? <p className={styles.info_p}>{p}</p> : null;
  },[]);

  return (
    <section id={'why_us'} className={styles.why_us}>
      <UIContainer>
        <h2 className={styles.heading}>Why us?</h2>
        <p className={`${styles.text} ${styles.text_top}`}>
          {webContent.posting_locations_heading_web}
        </p>

        {size.width < tabletsLandscapeBreakpoint ? (
          <PostLocationMob />
        ) : (
          <PostLocationDeck />
        )}

        <p className={`${styles.text} ${styles.text_bottom}`}>
          {webContent.applicants_can_apply_through_web}
        </p>
      </UIContainer>
      <UIContainer>
        <h2 className={styles.heading}>{webContent.example_of_profile_web}</h2>
        <div className={styles.example}>
          <div className={styles.card_heading}>
            <h4 className={styles.review_index}>Candidate</h4>
            <div className={styles.photo_wrap}>
              <img
                src={
                  employerContent.profile_picture_fs
                    ? `data:image/jpeg;base64,${employerContent.profile_picture_fs}`
                    : noPhoto
                }
                alt='Candidate photo'
                className={styles.photo}
              />
            </div>
          </div>
          <div className={styles.qualities}>
            {candidateInfo(employerContent.age)}
            {candidateInfo(employerContent.gender)}
            {candidateInfo(employerContent.race)}
            {candidateInfo(employerContent.citizenship)}
            {candidateInfo(employerContent.group)}
            {candidateInfo(employerContent.qualification)}
            {candidateInfo(employerContent.day_and_time)}
            {candidateInfo(employerContent.expected_salary)}
          </div>
          <div className={styles.contact_wrap}>
            <div className={styles.contacts}>
              {candidateInfo(employerContent.name)}
              {candidateInfo(employerContent.email)}
              {candidateInfo(employerContent.whatsapp)}
              {candidateInfo(employerContent.phone)}
              {candidateInfo(employerContent.resume)}
            </div>
          </div>
          <div>{candidateInfo(employerContent.experience)}</div>
        </div>
      </UIContainer>
    </section>
  );
};

export default WhyUs;
