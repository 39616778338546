import React, { FC } from 'react';
import styles from './_styles.module.scss';
import JobCardDetails from '../../Candidate/JobDetails/JobCardDetails/JobCardDetails';
import JobHeading from '../../Candidate/JobDetails/JobHeading';
import { IAvailableWorkDetails } from '../../../core/models/models';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import JobTypeAndDate from '../../JobTypeAndDate/JobTypeAndDate';

type SearchWorkCardProps = {
  selectedJobId: string | null;
  work: IAvailableWorkDetails;
  onClick: () => void;
};

const SearchWorkCard: FC<SearchWorkCardProps> = ({
  selectedJobId,
  work,
  onClick,
}) => {
  dayjs.extend(relativeTime);

  return (
    <div
      className={`${styles.work_card} ${
        selectedJobId === work.assignment_id ? styles.work_card_active : ''
      }`}
      onClick={onClick}
    >
      <JobHeading heading={work.title} size={18} />
      <JobCardDetails
        companyName={work.company_name}
        location={work.location}
        lowestRate={work.lowest_rate}
        highestRate={work.highest_rate}
        time={work.time}
        expectedSalaryDivider={work.expected_salary_divider}
      />
      {/*<JobTypeAndDate date={dayjs(work.date).fromNow()} type={work.job_type} />*/}
      <JobTypeAndDate date={work.created_at} type={work.job_type} />
    </div>
  );
};

export default SearchWorkCard;
