import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { openInfoModal } from '../../redux/reducers/ui/ModalDialogSlice';
import {
  getCandidateProfileInfo,
  getProfileStatus,
} from '../../redux/thunk/profile';
import useWebContent from '../../core/hooks/useWebContent';

const RequireCompleteProfile: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = useAppSelector(state => state.userTokenReducer.token);
  const complete = useAppSelector(
    state => state.userInformationReducer.complete
  );

  const [webContent] = useWebContent();

  useEffect(() => {
    if (complete) {
      return;
    }

    if (token) {
      dispatch(getProfileStatus());
    }

    if (token && !complete) {
      navigate('/profile');
      dispatch(
        openInfoModal({
          message: webContent.profile_incomplete_web,
        })
      );
    }
  }, [token, complete]);

  return <>{children}</>;
};

export default RequireCompleteProfile;
