import React from 'react';
// import logo from '../../assets/icons/Logo.svg';
import './Logo.scss';
import { Link } from 'react-router-dom';
import Icon from '../UI/UIIcon/UIIcon';

const Logo: React.FC<{
  className?: string;
  goTo: '/employer' | '/';
}> = ({ className, goTo }) => {
  return (
    <Link to={goTo} className={`logo ${className || ''}`}>
      <Icon name='logo' className='logo-icon' />
      <h2 className='logo-title'>SG Jobs</h2>
    </Link>
  );
};

export default Logo;
