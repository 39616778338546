import React, { FC } from 'react';
import './Logout.scss';
import cn from 'classnames';
import { useCookies } from 'react-cookie';
import UIButtonLink from '../UI/UIButtonLink/UIButtonLink';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import {
  setAdminRole,
  userTokenSlice,
} from '../../redux/reducers/api/UserTokenSlice';
import { useNavigate } from 'react-router-dom';
import {
  clearLogoutUserInfo,
  setProfileStatus,
} from '../../redux/reducers/api/UserInformationSlice';

type LogoutProps = {
  forUser: 'employer' | 'candidate' | 'admin';
  className?: string;
};

const Logout: FC<LogoutProps> = ({ forUser, className }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [cookie, setCookie, removeCookie] = useCookies(['token']);
  const { setToken } = userTokenSlice.actions;
  const { isAdmin } = useAppSelector(state => state.userTokenReducer);

  const replaceHistoryForUser = () =>
    navigate(forUser === 'employer' ? '/employer' : '/', {
      replace: true,
    });

  const logout = () => {
    removeCookie('token', { path: '/' });
    dispatch(setToken(null));
    if (isAdmin) {
      dispatch(setAdminRole(false));
    }

    dispatch(clearLogoutUserInfo());

    dispatch(
      setProfileStatus({
        complete: true,
        requiredFields: [],
      })
    );

    replaceHistoryForUser();
  };

  return (
    <UIButtonLink
      title='Logout'
      classModificator={cn({
        logout: true,
        logout__employer: forUser === 'employer',
        logout__candidate: forUser === 'candidate',
      })}
      callback={logout}
      icon='logout'
      iconDirection='left'
    />
  );
};

export default Logout;
