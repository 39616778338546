import React, { FC } from 'react';
import styles from './_styles.module.scss';
import stepPurchase from '../../../../assets/images/step_purchase.png';
import stepPost from '../../../../assets/images/step_post.png';
import stepReceive from '../../../../assets/images/step_receive.png';
import WorkStepCard from './WorkStepCard';
import UIContainer from '../../../../containers/Container/Container';
import useWebContent from "../../../../core/hooks/useWebContent";

const stepsList = [
  {
    title: 'Step 1',
    logo: stepPurchase,
    stepText: 'Purchase posting credit',
  },
  {
    title: 'Step 2',
    logo: stepPost,
    stepText: 'Post a job',
  },
  {
    title: 'Step 3',
    logo: stepReceive,
    stepText: 'Receive profiles of job candidates',
  },
];

const WorkSteps: FC = () => {
  const [webContent] = useWebContent();

  const prepStepsList = stepsList.map((step, index) => {
    const text = (inx: number) => {
      switch (inx) {
        case 0:
          return webContent.purchase_step_one_web;
        case 1:
          return webContent.purchase_step_two_web;
        case 2:
          return webContent.purchase_step_three_web;
        default:
          return '';
      }
    };

    return {
      ...step,
      stepText: text(index),
    };
  });

  return (
    <section className={styles.works_steps}>
      <UIContainer>
        <h2 className={styles.heading}>How it works?</h2>

        <div className={styles.steps_list}>
          {prepStepsList.map(({ title, logo, stepText }) => (
            <WorkStepCard
              key={title}
              title={title}
              logo={logo}
              stepText={stepText}
            />
          ))}
        </div>
      </UIContainer>
    </section>
  );
};

export default WorkSteps;
