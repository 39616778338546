import React, { FC } from 'react';
import styles from './_styles.module.scss';
import UIButtonNavLink from '../UI/UINavLink/UIButtonNavLink';

interface TabsProps {
  links: {
    goTo: string;
    title: string;
    end?: boolean;
    className?: string;
  }[];
}

const Tabs: FC<TabsProps> = ({ links }) => {
  return (
    <nav className={styles.tabs}>
      <ul>
        {links.map(({ goTo, title, end, className }) => (
          <li key={goTo + title}>
            <UIButtonNavLink
              goTo={goTo}
              title={title}
              end={end}
              className={`${styles.tabs_link} ${className || ''}`}
            />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Tabs;
