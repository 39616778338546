import React, { FC, useEffect } from 'react';
import EmployerProfile from '../../components/Employer/EmployerProfile/EmployerProfile';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import { clearAfterRegister } from '../../redux/reducers/api/UserTokenSlice';
import RequireAuth from '../../containers/hoc/RequireAuth';
import { getEmployerProfileInfo } from '../../redux/thunk/profile';

const EmployerProfilePage: FC = () => {
  const { token, afterRegister } = useAppSelector(
    state => state.userTokenReducer
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEmployerProfileInfo());

    return () => {
      if (afterRegister) {
        dispatch(clearAfterRegister());
      }
    };
  }, [token]);

  return (
    <RequireAuth role={'employer'}>
      <EmployerProfile />
    </RequireAuth>
  );
};

export default EmployerProfilePage;
