import React, { FC } from 'react';
import '../JobDetails/JobCardDetails/JobCardDetails.scss';

type JobHeadingProps = {
  heading: string;
  size: 16 | 18 | 20;
};

const JobHeading: FC<JobHeadingProps> = ({ heading, size }) => {
  return (
    <h3
      className={'job_heading'}
      style={{
        fontSize: `${size}px`,
      }}
    >
      {heading}
    </h3>
  );
};

export default JobHeading;
