import React, { FC, useEffect } from 'react';
import styles from './_styles.module.scss';
import useWebContent from '../../core/hooks/useWebContent';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import UIRouterLink from '../../components/UI/UIRouterLink/UIRouterLink';
import { clearSelectedPackages } from '../../redux/reducers/ui/SubscriptionPackagesSlice';

const CompletionPage: FC = () => {
  const dispatch = useAppDispatch();
  const [webContent] = useWebContent();
  const { selectedAssignmentId, purchaseType } = useAppSelector(
    state => state.subscriptionPackagesReducer
  );

  useEffect(() => {
    return () => {
      dispatch(clearSelectedPackages());
    };
  }, [purchaseType, selectedAssignmentId]);

  const linkButton = () => {
    return purchaseType === 'package' ? (
      <UIRouterLink
        title={webContent.continue_to_post_web}
        goTo={`/employer/post-jobs`}
        className={styles.back_btn}
        replace={true}
      />
    ) : purchaseType === 'bump' ? (
      <UIRouterLink
        title={webContent.continue_to_bump_web}
        goTo={`/employer/manage-jobs?assignment_id=${selectedAssignmentId}`}
        className={styles.back_btn}
        replace={true}
      />
    ) : purchaseType === 'shortlist' ? (
      <UIRouterLink
        title={webContent.continue_to_shortlist_web}
        goTo={`/employer/manage-jobs?assignment_id=${selectedAssignmentId}`}
        className={styles.back_btn}
        replace={true}
      />
    ) : null;
  };

  return (
    <div className={styles.completion}>
      <h1 className={styles.heading}>{webContent.payment_received_web}</h1>
      {linkButton()}
    </div>
  );
};

export default CompletionPage;
