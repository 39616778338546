import React, { FC } from 'react';
import './JobCardDetails.scss';

interface JobCardTypeProps {
  type: 'Full-time' | 'Part-time' | 'Internship' | 'Contract' | string;
}

const JobCardType: FC<JobCardTypeProps> = ({ type }) => {
  return (
    <div
      className={`job-details__type ${
        type === 'Full-time'
          ? 'job-details__type_full'
          : type === 'Internship'
          ? 'job-details__type_internship'
          : type === 'Contract'
          ? 'job-details__type_contract'
          : ''
      }`}
    >
      {type}
    </div>
  );
};

export default JobCardType;
