import React, { FC } from 'react';
import { IPackage } from '../../../../core/models/models';
import styles from './_styles.module.scss';
import PriceCard from '../PackagePriceCard/PriceCard';
import { useAppDispatch } from '../../../../core/hooks/reduxHooks';

const PackagesPriceCardList: FC<{ packages: IPackage[]; user_id: string }> = ({
  packages,
  user_id,
}) => {
  return (
    <div className={styles.price_list}>
      {packages.map(
        ({
          package_id,
          name,
          price,
          number_of_posting,
          number_of_shortlist,
          validity_days,
        }) => (
          <PriceCard
            key={package_id}
            user_id={user_id}
            package_id={package_id}
            title={name}
            price={price}
            posting={number_of_posting}
            shortlist={number_of_shortlist}
            valid={validity_days}
            disableBtn={false}
          />
        )
      )}
    </div>
  );
};

export default PackagesPriceCardList;
