import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import './UISelect.scss';
import useOnClickOutside from '../../../core/hooks/useOnClickOutside';
import UISelectCheckbox from '../UISelectCheckbox/UISelectCheckbox';

type UISelectProps = {
  list: string[];
  selectedOption: string;
  heading: string;
  iconType?: 'industries' | 'location' | 'jobs' | 'education';
  setSelectedOption: (option: any) => void;
  placeholder?: string;
  error?: boolean;
};
const UISelect: FC<UISelectProps> = ({
  list,
  selectedOption,
  heading,
  iconType,
  setSelectedOption,
  placeholder = '',
  error,
}) => {
  const [isListOpen, setListOpen] = useState<boolean>(false);
  const selectBoxRef = useRef<HTMLDivElement>(null);

  const closeDropdown = useCallback(() => setListOpen(false), []);

  useOnClickOutside(selectBoxRef, closeDropdown);

  const handleSort = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, option: string) => {
      setSelectedOption(option);
      setListOpen(false);
    },
    [selectedOption, list, setSelectedOption]
  );

  return (
    <div className='dropdown' ref={selectBoxRef}>
      <p
        className={`dropdown__heading ${
          error && !selectedOption && 'heading-error'
        }`}
      >
        {heading}
      </p>
      <div className='dropdown__wrapper'>
        <button
          type='button'
          className='dropdown__header'
          onClick={() => setListOpen(prev => !prev)}
        >
          <p>{selectedOption ? selectedOption : placeholder}</p>
          <div className={'dropdown__icons'}>
            <span
              className={cn({
                dropdown__arrow: true,
                'dropdown__arrow--up': isListOpen,
              })}
            />
            {iconType && (
              <div
                className={cn({
                  select__icon: true,
                  'select__icon--jobs': iconType === 'jobs',
                  'select__icon--location': iconType === 'location',
                  'select__icon--industries': iconType === 'industries',
                  'select__icon--education': iconType === 'education',
                })}
              />
            )}
          </div>
        </button>
        <ul
          className={cn({
            dropdown__list: true,
            'dropdown__list--is-open': isListOpen,
          })}
        >
          {list.map((option, key) => (
            <li key={key}>
              {/*<span*/}
              {/*  className={cn({*/}
              {/*    'dropdown__list-item': true,*/}
              {/*    'dropdown__list-item--active': selectedOption === option,*/}
              {/*  })}*/}
              {/*  onClick={e => handleSort(e, option)}*/}
              {/*>*/}
              {/*  {option}*/}
              {/*</span>*/}
              <UISelectCheckbox
                key={option}
                id={option}
                type='radio'
                title={option}
                checked={selectedOption === option}
                onChange={e => handleSort(e, option)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UISelect;
