import React, { FC, useState } from 'react';
import styles from './_styles.module.scss';
import Icon from '../../../UI/UIIcon/UIIcon';
import useWebContent from '../../../../core/hooks/useWebContent';

const PriceCardBumpShortlist: FC<{
  type: 'bump' | 'shortlist';
  totalAmount?: number;
  id: string;
  price: number;
  assignmentId?: string;
  onSelectPackage?: (amount: number) => void;
}> = ({ totalAmount, assignmentId, price, id, type, onSelectPackage }) => {
  const [isAmount, setIsAmount] = useState<number>(1);

  const [webContent] = useWebContent();

  const handleAmount = (counterType: 'plus' | 'minus') => {
    if (!totalAmount || !onSelectPackage) {
      return;
    }

    if (counterType === 'plus') {
      // console.log('plus', totalAmount);
      if (isAmount + 1 > totalAmount) {
        return;
      }

      setIsAmount(isAmount + 1);
      onSelectPackage(isAmount + 1);
    } else {
      if (isAmount > 0) {
        if (isAmount - 1 < 0) {
          return;
        }

        setIsAmount(isAmount - 1);
        onSelectPackage(isAmount - 1);
      }
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.head}>
        <Icon name={'priceCircle'} className={styles.circle} />
        <h3 className={styles.head_title}>$ {price}</h3>
      </div>
      <div className={styles.card_content}>
        <div className={styles.card_type}>
          {type === 'bump' ? (
            <Icon name={'bumpIcon'} className={styles.icon} />
          ) : (
            <Icon name={'shortlistIcon'} className={styles.icon} />
          )}
          {type === 'bump' ? 'Bump!' : 'Shortlist'}
        </div>
        <article className={styles.article}>
          {type === 'bump'
            ? webContent.bump_description_web
            : webContent.shortlist_description_web}
        </article>
      </div>
      {totalAmount && !assignmentId && (
        <div className={styles.amount}>
          <p className={styles.amount_value}>{isAmount}</p>
          <div className={styles.counter}>
            <button
              className={styles.btn}
              type={'button'}
              onClick={() => handleAmount('minus')}
            >
              <Icon name={'minus'} width={15} />
            </button>
            <div className={styles.border} />
            <button
              className={styles.btn}
              type={'button'}
              onClick={() => handleAmount('plus')}
            >
              <Icon name={'plus'} width={15} />
            </button>
          </div>
        </div>
      )}
      {assignmentId && <p className={styles.amount_value}>{assignmentId}</p>}
    </div>
  );
};

export default PriceCardBumpShortlist;
